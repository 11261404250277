/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Header from "../Header/Index.js";
import { Modal, Button, Icon } from "rsuite";
import Pagination from "react-js-pagination";
import Helper from "../Helper";
import { Context } from "../Store/Store";
import { useHistory } from "react-router-dom";
var {
  GetBookingList,
  DeleteBooking,
  ListSearchApi,
} = require("../Api/ApiRoutes");
var { apiRequest } = require("../Api/Service");
// const reducer = (state, action) => {
//   switch (action.type) {
//     case "FETCH_SUCCESS":
//       return {
//         ...state,
//         isSuccess: true,
//         success: action.payload,
//         disable: false,
//       };
//     case "FETCH_ERROR":
//       return {
//         ...state,
//         isError: true,
//         errors: action.payload,
//         disable: false,
//       };
//     case "DEFAULT":
//       return {
//         disable: action.payload,
//       };
//     default:
//       return state;
//   }
// };

// const FileSchema = Yup.object().shape({
//   file: Yup.string().required("This Field is Required"),
// });

// const initialState = {
//   isError: false,
//   errors: [],
//   isSuccess: false,
//   success: "",
//   disable: false,
// };

function ActiveBooking() {
  const [records, setrecords] = React.useState([]);
  const [result, setResult] = React.useState({});
  const [show, setShow] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [rsModal, setRsModal] = React.useState(false);
  const [hidePagination, sethidePagination] = React.useState(false);
  const [currentId, setCurrentId] = React.useState(null);
  const [state, dispatch] = React.useContext(Context);
  const [locationKeys, setLocationKeys] = React.useState([]);
  const history = useHistory();
  const [sort_by, setSortBy] = React.useState(1);
  const [labelId, setLabelId] = React.useState("");

  const Modalclose = () => {
    setRsModal(false);
    setShow(false);
  };
  const Modalopen = (e) => {
    setCurrentId(e.target.dataset.id);
    setRsModal(true);
  };
  var user = Helper.getStorageData("SESSION");
  var permissions = user.permissions;
  var location = useLocation();

  const getBooking = useCallback(
    (page, sort) => {
      if (
        Helper.getPermissions(
          "bookings-read",
          permissions && permissions,
          user.type
        )
      ) {
        Helper.overlay(true);

        let filterBy = "&status=1";

        apiRequest(
          GetBookingList.method,
          GetBookingList.url +
            `?page=${page}&sort_by=${sort ?? sort_by}${filterBy}`
        )
          .then((response) => {
            if (response.data.code === 200) {
              let data = response.data.data;
              dispatch({ type: "SET_CALL_STATE", call_one: false });
              setrecords(data);
              setLabelId(data[0]?.label_id);
              dispatch({ type: "SET_SEARCH_STATE", search_list: false });
              sethidePagination(false);
              setResult(response.data.meta);
              Helper.overlay(false);

              if (data.length === 0) {
                if (user.type === "whitelabel") {
                  setLabelId(user.label_id);
                }
              }
            }
          })
          .catch((error) => {
            setErrorMsg(error);
            Helper.overlay(false);
          });
      }
    },
    [dispatch, permissions, sort_by, user.type]
  );

  const handleSearchPageChange = useCallback(
    (pageNumber, sort) => {
      Helper.overlay(true);
      let filterBy = "&status=1";
      apiRequest(
        ListSearchApi.method,
        ListSearchApi.url +
          `bookings?name=${state.searchedName}&page=${pageNumber}&sort_by=${sort}${filterBy}`
      )
        .then((response) => {
          if (response.data.code === 200) {
            let data = response.data;
            setrecords(data.data);
            setResult(data.meta);
            Helper.overlay(false);
          }
        })
        .catch((error) => {
          Helper.overlay(false);
          history.push(`${location.pathname}#error`);
        });
    },
    [history, location.pathname, state.searchedName]
  );

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }

      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          let str = location.pathname;
          if (location.hash !== "#search") {
            dispatch({ type: "SET_SEARCH_STATE", searchedName: null });
          }
          let id = str.substr(str.length - 1);
          dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: +id });
          setLocationKeys(([_, ...keys]) => keys);
        } else {
          let str = location.pathname;
          let id = str.substr(str.length - 1);
          if (location.hash !== "#search") {
            dispatch({ type: "SET_SEARCH_STATE", searchedName: null });
          }
          dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: +id });
          setLocationKeys((keys) => [location.key, ...keys]);
        }
      }
    });
  }, [dispatch, history, locationKeys]);

  useEffect(() => {
    if (location.hash !== "#search" && !state.searchedName) {
      dispatch({ type: "SET_STATE", response: [] });
      getBooking(state.set_active_page, sort_by);
    }
  }, [
    dispatch,
    location.hash,
    sort_by,
    state.searchedName,
    state.set_active_page,
  ]);

  useEffect(() => {
    if (
      location.pathname.includes("booking-active-list") &&
      location.hash === "#search" &&
      state.searchedName
    ) {
      dispatch({ type: "SET_STATE", response: [] });
      handleSearchPageChange(state.set_active_page, sort_by);
    }
  }, [
    dispatch,
    location.hash,
    location.pathname,
    state.searchedName,
    state.set_active_page,
    sort_by
  ]);

  useEffect(() => {
    if (
      location.pathname.includes("booking-active-list") &&
      location.hash === "#search" &&
      !state.searchedName
    ) {
      if (state?.data?.data) {
        setrecords(state.data.data || []);
        setResult(state.data.meta || []);
        dispatch({ type: "SET_SEARCH_STATE", pagination: false });
        Helper.overlay(false);
      }
    }
    if (location.hash === "#error") {
      setrecords([]);
      setResult([]);
      //setShow(true);
      setErrorMsg("No record Found");
    }
  }, [dispatch, location, state.data, state.pagination, state.searchedName]);

  // eslint-disable-next-line no-unused-vars
  function getcheckbox() {
    var array = [];
    var checkboxes = document.querySelectorAll(
      "input[name='subcheckbox']:checked"
    );
    for (var i = 0; i < checkboxes.length; i++) {
      array.push(checkboxes[i].value);
    }
    console.log("array", array);
    let params = new FormData();
    params.append("ids", array);
    // let delete_property = window.confirm(
    //   "Are you sure you want to delete these records!"
    // );
  }

  // eslint-disable-next-line no-unused-vars
  function handleMainCheckBox(e) {
    var value = false;

    if (e.target.checked) {
      value = true;
    }

    Array.from(document.querySelectorAll("input[name='subcheckbox']")).forEach(
      (checkbox) => {
        checkbox.checked = value;
      }
    );
  }

  const deleteRecord = (e) => {
    apiRequest(DeleteBooking.method, DeleteBooking.url + `${currentId}`)
      .then((response) => {
        if (response.data.code === 200) {
          let data = records.filter((d) => {
            return d.id != currentId;
          });
          setrecords(data);
          setRsModal(false);
          console.log("Success", response);
        } else {
          console.log("Failure", response);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setShow(true);
        setRsModal(false);
        setErrorMsg(error.response.data.message);
      });
  };

  const handlePageChange = (pageNumber) => {
    dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: pageNumber });
    if (
      location.pathname.includes("booking-active-list") &&
      location.hash === "#search"
    ) {
      history.push(`/admin/booking-active-list/${pageNumber}#search`);
    } else {
      history.push(`/admin/booking-active-list/${pageNumber}`);
    }
  };
  const setDropDown = (e) => {
    setSortBy(e.target.value);
  };

  return (
    <React.Fragment>
      <Modal backdrop="static" show={rsModal} onHide={Modalclose} size="xs">
        <Modal.Body>
          <Icon
            icon="remind"
            style={{
              color: "#ffb300",
              fontSize: 24,
            }}
          />
          {"  "}
          Are you sure you want to delete this record?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={deleteRecord} appearance="primary">
            Ok
          </Button>
          <Button onClick={Modalclose} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show} onHide={Modalclose}>
        <Modal.Body>
          <h5>{errorMsg}</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={Modalclose} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <div className="wrapper d-flex align-items-stretch">
        <Sidebar /> */}
      <div id="content">
        <Header />
        {Helper.getPermissions(
          "bookings-read",
          permissions && permissions,
          "admin"
        ) || Helper.getPermissions("", [], user.type) ? (
          <div className="p-4">
            <div className="row pb-2 d-flex justify-content-between align-items-center border-bottom">
              <div className="col-lg-12 col-md-6">
                <div className="d-flex flex-row justify-content-between  mb-3">
                  {
                    //Helper.getPermissions('usage-create',permissions) &&
                    <div className="p-2">
                      {Helper.getPermissions(
                        "bookings-create",
                        permissions && permissions,
                        user.type
                      ) && (
                        <NavLink
                          exact
                          to={`/admin/create-booking/${labelId}`}
                          className="btn btnn-user white bg-orange"
                          style={{ backgroundColor: user.background_color }}
                        >
                          Create Booking
                        </NavLink>
                      )}
                    </div>
                  }
                  <div className="col-lg-4 col-md-9 ">
                    <div className="d-flex row align-items-center">
                      <div className="col-md-12 col-lg-3 pl-lg-0 pr-lg-0">
                        Results: {result.total ? result.total : "1"}
                      </div>
                      <div className="col-md-12 col-lg-9 row">
                      <label class="col-4 mt-auto">Sort By:</label>
                        <select
                          className="form-select form-control col-8 "
                          aria-label="Default select example"
                          name="sort_by"
                          onChange={setDropDown}
                        >
                          <option
                            selected={sort_by === 1 ? true : false}
                            value={1}
                          >
                            Last created on top
                          </option>
                          <option
                            selected={sort_by === 2 ? true : false}
                            value={2}
                          >
                            First created on top
                          </option>
                          {/* <option selected={sort_by === 3 ? true : false} value={3}>
                                                        Checkout bookings
                                                    </option>
                                                    <option selected={sort_by === 4 ? true : false} value={4}>
                                                        Not checkout bookings
                                </option> */}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-4 col-md-6">
                <div className="d-flex row align-items-center">
                  <div className="col-md-12 col-lg-3 pl-lg-0 pr-lg-0">Results: {result.total ? result.total : '1'}</div>
                  <div className="col-md-12 col-lg-9 pl-lg-0 pr-lg-0">
                    Sort By:{" "}
                    <select
                      className="form-select ml-1 ml-md-0"
                      aria-label="Default select example"
                      name="sort_by"
                      onChange={setDropDown}
                    >
                      <option selected={sort_by === 1 ? true : false} value={1}>
                        Last created on top
                      </option>
                      <option selected={sort_by === 2 ? true : false} value={2}>
                        First created on top
                      </option>
                      <option selected={sort_by === 3 ? true : false} value={3}>
                        Alphabetically A-Z
                      </option>
                      <option selected={sort_by === 4 ? true : false} value={4}>
                        Alphabetically Z-A
                      </option>
                    </select>
                  </div>
                </div>
              </div> */}
            </div>
            {errorMsg && " "}
            <div className="row">
              {records.length
                ? records.map((record, index) => (
                    <div
                      className="card col-12 mt-3 shadow bg-white rounded"
                      key={""}
                    >
                      <div className="row">
                        <div className="col-md-12 col-lg-10  col-sm-12 pb-3 pt-2">
                          <div className="row pt-2">
                            <div className="col-6">
                              {/* <input
                                                        type="checkbox"
                                                        className=""
                                                        name="subcheckbox"
                                                        defaultValue={""}
                                                        id="exampleCheck1"
                                                    /> */}
                              {Helper.getPermissions(
                                "bookings-update",
                                permissions && permissions,
                                user.type
                              ) ? (
                                <NavLink
                                  className="ml-2 form-check-label font-weight-bold"
                                  exact
                                  to={`/admin/edit-booking/${record.id}`}
                                >
                                  {record?.uuid}
                                </NavLink>
                              ) : (
                                <span>{record?.uuid}</span>
                              )}
                            </div>
                          </div>
                          <div className="row pt-2">
                            
                            <div className="col-md-4 col-lg-4 col-sm-12 ">
                              <strong>Vehicle:</strong>{" "}
                              <NavLink
                                className="ml-2 form-check-label font-weight-bold"
                                exact
                                to={`/admin/edit-vehicle-details/${record?.vehicle_detail?.id}`}
                              >
                                {record?.vehicle_detail?.name}
                              </NavLink>
                            </div>
                            <div className="col-md-5 col-lg-5 col-sm-12 ">
                              <strong>Main driver:</strong>{" "}
                              {
                                record.drivers
                                  ?.filter((item) => item.type === "1")
                                  .map((item) => (
                                    <>
                                      <span className="font-weight-bold">
                                        <NavLink
                                          exact
                                          to={`/admin/edit-user/${item.user_id}`}
                                        >
                                          {" "}
                                          <strong>
                                            {" "}
                                            <u> {item.user_name} </u>{" "}
                                          </strong>
                                        </NavLink>
                                      </span>
                                    </>
                                  ))[0]
                              }
                              {/* Agor Eskop (Verified, Signed) */}
                              {}
                            </div>
                          </div>
                          <div className="row pt-2">
                            <div className="col-md-4 col-lg-4 col-sm-12">
                              <strong>Booking starts: </strong>{" "}
                              {Helper.getDateByTZ(record?.start_date)} (
                              {user?.timezone
                                ? user.timezone
                                : Helper.localTimezone()}
                              )
                            </div>
                            <div className="col-md-3 col-lg-3 col-sm-12 ">
                              <strong>Registration plate:</strong>{" "}
                              {record.vehicle_detail.license
                                ? record.vehicle_detail.license
                                : "-"}
                              {/* Agor Eskop (Verified, Signed) */}
                              {}
                            </div>
                            <div className="col-md-5 col-lg-5 col-sm-12 ">
                              {/* <strong>Additional driver:</strong> { } {record.drivers?.filter(item => item.type !== "1").map(item => item.user_contact).pop()} */}
                              {/* kenneth Pohl (Not Verified, Not Signed) */}
                            </div>

                            {/* <div className="col-md-3 col-lg-3 col-sm-12">
                                                            <strong>Extra insurance:</strong>{" "} {record?.extra_insurance ? 'Yes' : 'No'}
                                                            { }
                                                        </div> */}
                          </div>
                          <div className="row pt-2">
                            <div className="col-md-4 col-lg-4 col-sm-12">
                              <strong>Booking ends:</strong>{" "}
                              {Helper.getDateByTZ(record?.end_date)} (
                              {user?.timezone
                                ? user.timezone
                                : Helper.localTimezone()}
                              )
                            </div>
                            {user.type !== "whitelabel" && (
                              <div className="col-md-3 col-lg-3 col-sm-12">
                                <strong>white label:</strong>{" "}
                                {record?.whitelabel?.name}
                              </div>
                            )}
                            {/* {user.type !== "whitelabel" && 
                                                        <div className="col-md-5 col-lg-5 col-sm-12 ">
                                                            <strong>Fuel coverage:</strong> { } {record?.fuel_coverage ? 'Yes' : 'No'}
                                                        </div>
                                                        }*/}
                            <div className="col-md-4 col-lg-4 col-sm-12">
                              <strong>Rental Start Date:</strong>{" "}
                              {record.rental_start_date ? (
                                <>
                                  {" "}
                                  {Helper.getDateByTZ(record.rental_start_date)} (
                                  {user?.timezone
                                    ? user.timezone
                                    : Helper.localTimezone()}
                                  ){" "}
                                </>
                              ) : (
                                "-"
                              )}
                              {/* Agor Eskop (Verified, Signed) */}
                              {}
                            </div>
                          </div>
                        

                          <div className="row pt-2">
                            {/*{user.type !== "whitelabel" && 
                                                        <div className="col-md-3 col-lg-3 col-sm-12 ">
                                                            <strong>Pay Price:</strong>{" "}
                                                            {record?.pay_price !== null ? record.pay_price :"-"}
                                                        </div>
                                                        }*/}
                            <div className="col-md-4 col-lg-4 col-sm-12 ">
                              <strong>Booking Status:</strong>{" "}
                              {record?.booking_status}
                            </div>
                            <div className="col-md-3 col-lg-3 col-sm-12 ">
                              <strong>Agent:</strong>{" "}
                              {record.agent ? record.agent : "-"}
                            </div>{" "}
                            <div className="col-md-4 col-lg-4 col-sm-12 ">
                              <strong>Created by:</strong>{" "}
                              {record?.created_by?.email}
                            </div>{" "}
                            {/*
                                                        <div className="col-md-4 col-lg-4 col-sm-12 ">
                                                            <strong>Excess Amount:</strong>{" "}
                                                            {record?.excess_amount ? record.excess_amount : "-"}
                                                    </div> */}
                            {/* <div className="col-md-4 col-lg-4 col-sm-12 ">
                                                            <strong>Fuel level at check-out:</strong>{" "}
                                                            {record?.check_out_fuel ? `${record.check_out_fuel}%` : "-"}
                                                        </div>{" "} */}
                          </div>
                          {/* <div className="row pt-2">

                                                    <div className="col-md-4 col-lg-4 col-sm-12 ">
                                                            <strong>Fuel level at check-in:</strong>{" "}
                                                            {record?.check_in_fuel ? `${record.check_in_fuel}%` : "-"}
                                                        </div>{" "}
                                                    </div> */}
                        </div>

                        <div className="col-md-12 col-sm-12  col-lg-2 ">
                          <div className="row pt-3 pt-lg-5 pb-3">
                            <div className="col-md-4  col-sm-4 col-lg-12 pt-lg-4">
                              {Helper.getPermissions(
                                "bookings-update",
                                permissions && permissions,
                                user.type
                              ) && (
                                <NavLink
                                  className=""
                                  exact
                                  to={`/admin/edit-booking/${record.id}`}
                                >
                                  <button className="btn-success w-100">
                                    Edit
                                  </button>
                                </NavLink>
                              )}
                            </div>
                            <div className="col-md-4  col-sm-4 col-lg-12 pt-lg-3 pt-md-0 pt-2">
                              {Helper.getPermissions(
                                "bookings-delete",
                                permissions && permissions,
                                "admin"
                              ) && (
                                <button
                                  className="btn-danger w-100 "
                                  data-id={record.id}
                                  onClick={Modalopen}
                                >
                                  Delete
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                : (errorMsg || records.length === 0) && (
                    <div className="text-center ft-14 mt-3 font-weight-bold">
                      No Data Found...
                    </div>
                  )}
            </div>
            <div className="row mt-3 ft-14">
              <div className="col-md-12">
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-end">
                    {!hidePagination && (
                      <Pagination
                        activePage={state.set_active_page}
                        // itemsCountPerPage={result.to}
                        totalItemsCount={result.total ? result.total : 1}
                        pageRangeDisplayed={result.last_page}
                        itemClass="page-item"
                        linkClass="page-link"
                        onChange={handlePageChange}
                        activeClass={user.type === "whitelabel" ? "active-whitelabel-pagination" : "active-default-pagination "}
                      />
                    )}
                    <li className="pl-3"></li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        ) : (
          <div className="p-4">
            <div className="text-center ft-14 mt-3 font-weight-bold">
              You are not allowed to visit this screen...
            </div>
          </div>
        )}
      </div>
      {/* </div> */}
    </React.Fragment>
  );
}
export default ActiveBooking;

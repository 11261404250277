/* eslint-disable eqeqeq */
import React, { useEffect, useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import { Modal, Button, Icon } from "rsuite";
import Pagination from "react-js-pagination";
import { Context } from "../Store/Store";
import Helper from "../Helper";
import Table from 'react-bootstrap/Table';
var { GetVechileAlarm, VechileAlarmDelete, VechileAlarmDeleteAll } = require("../Api/ApiRoutes");

var { apiRequest } = require("../Api/Service");

function VehicleAlarm(props) {

  var user = Helper.getStorageData("SESSION");
  var location = useLocation();
  const [alarmrecords, setAlaramecords] = React.useState([]);
  const [result, setResult] = React.useState({});
  const [state, dispatch] = React.useContext(Context);
  const [rsModal, setRsModal] = React.useState(false);
  const [rssModal, setRssModal] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [currentId, setCurrentId] = React.useState(null);
  // eslint-disable-next-line no-unused-vars
  const [startdate, setStartDate] = useState(new Date());
  // eslint-disable-next-line no-unused-vars
  const [enddate, setEndDate] = useState(null);

  const Modalclose = () => {
    setRsModal(false);
  };  
  const Modalopen = (e) => {
    setRsModal(true);
  };

  const Modalopenforrecord = (e) => {
    setCurrentId(e.target.dataset.id);
    setRssModal(true);
  };

   const Modalsclose = () => {
    setRssModal(false);
  }; 


  const getVehicleAlarm = useCallback((page) => {

    Helper.overlay(true);
    let vid = props?.vid ? props.vid : '';

    let aurl = GetVechileAlarm.url+'/'+vid;
   
    if (vid) {
      aurl = aurl + `?per_page=50`;
    }

    apiRequest(GetVechileAlarm.method, aurl)
      .then((response) => {
        if (response.data.code === 200) {
          setAlaramecords(response.data.data);
          setResult(response.data.meta);
          Helper.overlay(false);

        } else {
          Helper.overlay(false);
        }
      })
      .catch((error) => {
        setErrorMsg(error)
        Helper.overlay(false);
      });
  }, [props.vid])

  useEffect(() => {
    if (location.hash !== "#search") {
      getVehicleAlarm(state.set_active_page);
    }
    if (location.hash === "#error") {
      setAlaramecords([]);
      setResult([]);

      setErrorMsg('No record found');
    }
  }, [getVehicleAlarm, location.hash, state.set_active_page]);

  useEffect(() => {
    if (state.pagination === true) {
      setAlaramecords(state.data.data);
      setResult(state.data.meta);
      dispatch({ type: "SET_SEARCH_STATE", pagination: false });
      Helper.overlay(false);
    }
  }, [dispatch, state.data.data, state.data.meta, state.pagination]);

  const handlePageChange = (pageNumber) => {

    dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: pageNumber });
    Helper.overlay(true);
    let qs = '&per_page=50';


    let vid = props?.vid ? props.vid : '';

    if (startdate && enddate) {
      qs = qs + `&start=${startdate}&end=${enddate}`;
    }

    apiRequest(
      GetVechileAlarm.method,
      GetVechileAlarm.url +`/`+vid+`?page=${pageNumber}` + qs
    )
      .then((response) => {
        if (response.data.code === 200) {
          setAlaramecords(response.data.data);
          setResult(response.data.meta);
          Helper.overlay(false);
          //history.push(`/admin/heartbeat/${pageNumber}`);
        } else {
          Helper.overlay(false);
        }
      })
      .catch((error) => {
        Helper.overlay(false);
      });
  };

  const deleteRecord = (e) => {
  let vid = props?.vid ? props.vid : '';
    apiRequest(VechileAlarmDeleteAll.method, VechileAlarmDeleteAll.url+vid)
      .then((response) => {
        if (response.data.code === 200) {

          let aurl = GetVechileAlarm.url+'/'+vid;
        
          if (vid) {
            aurl = aurl + `?per_page=50`;
          }

          apiRequest(GetVechileAlarm.method, aurl)
            .then((response) => {
              if (response.data.code === 200) {
                setAlaramecords(response.data.data);
                setResult(response.data.meta);
                Helper.overlay(false);

              } else {
                Helper.overlay(false);
              }
            })
            .catch((error) => {
              setErrorMsg(error)
              Helper.overlay(false);
            });

        } else {
          console.log("Failure", response);
        }
      })
      .catch((error) => {
        console.log("error", error);
        alert(error);
      });
    //}
  };
 const deleteSingleRecord = (e) => {

    apiRequest(VechileAlarmDelete.method, VechileAlarmDelete.url+ `${currentId}`)
      .then((response) => {
        if (response.data.code === 200) {
          let data = alarmrecords.filter((d) => {
            return d.id !== currentId;
          });

          setAlaramecords(data);
          setRssModal(false);

        } else {
          console.log("Failure", response);
        }
      })
      .catch((error) => {
        console.log("error", error);
        alert(error);
      });
    //}
  };
  
  return (
    <React.Fragment>
     
        <Modal backdrop="static" show={rsModal} onHide={Modalclose} size="xs">
        <Modal.Body>
          <Icon
            icon="remind"
            style={{
              color: "#ffb300",
              fontSize: 24,
            }}
          />
          {"  "}
          Are you sure you want to delete all records?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={deleteRecord} appearance="primary">
            Ok
          </Button>
          <Button onClick={Modalclose} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal backdrop="static" show={rssModal} onHide={Modalsclose} size="xs">
        <Modal.Body>
          <Icon
            icon="remind"
            style={{
              color: "#ffb300",
              fontSize: 24,
            }}
          />
          {"  "}
          Are you sure you want to delete this record?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={deleteSingleRecord} appearance="primary">
            Ok
          </Button>
          <Button onClick={Modalsclose} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <div id="content">
        <div className="p-4">
          <div className="row border-bottom">
 
            <div className="col-md-12 col-lg-12 d-flex  pt-2">
              
            <div className="col-lg-10 mt-2">
              <button
                    className="btn-danger w-25 w-md-50"
                    onClick={Modalopen}
                  >
                    Clear All Alaram
                  </button>
            </div>
              <div className="d-flex flex-row mb-3">
                <div className="p-2">Results: {result.total ? result.total : '1'}</div>
                <div className="pl-4 pt-1">
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <Table striped bordered hover>
              <thead>
                <tr>
                  {props?.header == "no" ? '' : <th>Name</th>}
                  <th>Priority</th>
                  <th>Title</th>
                  <th>Type</th>
                  <th>Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {alarmrecords.length ? (
                  alarmrecords.map((record, index) => (
                    <tr key={index}>
                      {props?.header == "no" ? '' : <td>{record.vehicle.name ? record.vehicle.name : "-"}</td>}
                      <td> {record.priority}</td>
                      <td> {record?.title ? record.title : "-"}</td>
                      <td>{record?.type ? record.type : "-"}</td>
                      <td> {record?.created_at ? <>{Helper.getDateByTZ(record.created_at)} {user?.timezone ? `(${user.timezone})` : `(${Helper.localTimezone()})`} </>: "-"}</td>
                      <td>
                          <i className="fas fa-trash" 
                          data-id={record.id}
                          onClick={Modalopenforrecord}
                          title="Delete the record">

                          </i>
                      </td>

                    </tr>
                  ))
                ) : (
                  errorMsg && (
                    <div className="text-center ft-14 mt-3 font-weight-bold">
                      No Data Found...
                    </div>
                  )
                )}
              </tbody>
            </Table>
          </div>

          <div className="row mt-3 ft-14">
            <div className="col-md-12">
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-end">
                  <Pagination
                    activePage={state.set_active_page}
                    totalItemsCount={result.total ? result.total : 1}
                    pageRangeDisplayed={5}
                    itemsCountPerPage={50}
                    itemClass="page-item"
                    linkClass="page-link"
                    onChange={handlePageChange}
                    activeClass={user.type === "whitelabel" ? "active-whitelabel-pagination" : "active-default-pagination "}
                  />
                  <li className="pl-3">
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default VehicleAlarm;

import React, { useReducer, useState } from "react";
import Header from "../Header/Index.js";
import { Redirect } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import FlashMessage from "../FlashMessage/FlashMessage";
var { apiRequest } = require("../Api/Service");
var { UpdateHelpAndSupport,EditHelpAndSupportSubCategoryList } = require("../Api/ApiRoutes");
const Helper = require("../Helper");

const initialState = {
  isError: false,
  errors: [],
  isSuccess: false,
  success: "",
  disable: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_SUCCESS":
      return {
        ...state,
        isSuccess: true,
        success: action.payload,
        disable: false,
      };
    case "FETCH_ERROR":
      return {
        ...state,
        isError: true,
        errors: action.payload,
        disable: false,
      };
    case "DEFAULT":
      return {
        disable: action.payload,
      };
    default:
      return state;
  }
};

function EditHelpCategory(props) {

  const [record, setRecord] = React.useState(undefined);


  const VehicleSchema = Yup.object().shape({
    name: Yup.string().required("This Field is Required"),
  });
  var user = Helper.getStorageData("SESSION");
  var user_permissions = user.permissions;
  let categoryId = props.match.params.id;

   React.useMemo(() => {
    if (
      Helper.getPermissions(
        "help_and_support-update",
        user_permissions && user_permissions,
        "admin"
      ) ||
      Helper.getPermissions("", [], user.type)
    ) {
      apiRequest(
        EditHelpAndSupportSubCategoryList.method, 
        EditHelpAndSupportSubCategoryList.url+categoryId
      )
        .then((response) => {
          if (response.data.code === 200) {
            let data = response.data.data;
            setRecord(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const [LocalState, Localdispatch] = useReducer(reducer, initialState);
  const [redirectToReferrer, setredirectToReferrer] = useState(false);
  let id = props.match.params.id;

  const handleSubmit = (values) => {
    const form_data = Helper.formData(values);
    Localdispatch({ type: "DEFAULT", payload: true });
    apiRequest(UpdateHelpAndSupport.method, UpdateHelpAndSupport.url, form_data)
      .then((response) => {
        if (response.data.code === 200) {
          Localdispatch({
            type: "FETCH_SUCCESS",
            payload: response.data.message,
          });
          setTimeout(() => {
            setredirectToReferrer(true);
          }, 2000);
        }
      })
      .catch((error) => {
        console.log(error);
        Localdispatch({
          type: "FETCH_ERROR",
          payload: error.response.data.data,
        });
        window.scrollTo(500, 0);
      });
  };

  if (redirectToReferrer) {
    return <Redirect to={"/admin/help-and-support/1"} />;
  }

  return (
    <React.Fragment>
      <div id="content">
        <Header showSearch={false} showAdvanceSearch={false} />
        <div className="p-4">
          {record && (
          <Formik
            validateOnChange={false}
            validationSchema={VehicleSchema}
            onSubmit={(values) => {
              // same shape as initial values
              handleSubmit(values);
            }}
            initialValues={{
              category_id: id,
              name: record.name,
            }}
          >
            {({
              handleSubmit,
              handleChange,
              isSubmitting,
              setFieldValue,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <form className="mt-2 user" onSubmit={handleSubmit}>
                <FlashMessage
                  success={LocalState.success}
                  isSuccess={LocalState.isSuccess}
                  isError={LocalState.isError}
                  errors={LocalState.errors}
                />
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="form-label">Category Name</label>
                    <input
                      type="text"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      placeholder="Category name"
                      className={`form-control form-control-user ${
                        errors.name ? "error" : ""
                      }`}
                    />
                    {errors.name && (
                      <div className="ft-14 mt-1 red">{errors.name}</div>
                    )}
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group d-flex justify-content-end col-md-12">
                    <button
                      type="submit"
                      className="btn-submit btn-user"
                      disabled={LocalState.disable}
                      style={{
                        backgroundColor: user.background_color,
                      }}
                    >
                      confirm Details
                      {LocalState.disable ? (
                        <span
                          className="ml-2 spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        ""
                      )}
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default EditHelpCategory;

import React, { useEffect, useCallback } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Header from "../../Header/Index.js";
import { Context } from "../../Store/Store";
import { Modal, Button, Icon } from "rsuite";
import Pagination from "react-js-pagination";
import Helper from "../../Helper";
import { useHistory } from "react-router-dom";
import {
  OperatorListAPI,
  alldeleteAdmin,
  deleteAdmin,
} from "../../Api/ApiRoutes.js";
import { apiRequest } from "../../Api/Service.js";
import CheckBox from "../../../commonComponents/CheckBox.js";

function AdminUserList() {
  const [records, setrecords] = React.useState([]);
  const [result, setResult] = React.useState({});
  const [rsModal, setRsModal] = React.useState(false);
  const [state, dispatch] = React.useContext(Context);
  const [currentId, setCurrentId] = React.useState(null);
  const [locationKeys, setLocationKeys] = React.useState([]);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [sort_by, setSortBy] = React.useState(1);

  const history = useHistory();
  const Modalclose = () => {
    setRsModal(false);
  };
  const Modalopen = (e) => {
    setCurrentId(e.target.dataset.id);
    setRsModal(true);
  };

  var user = Helper.getStorageData("SESSION");
  var permissions = user.permissions;
  var location = useLocation();

  const getAdminUserList = useCallback(
    (page, sort) => {
      if (
        Helper.getPermissions(
          "admin-read",
          permissions && permissions,
          "admin"
        ) ||
        user.type === "superadmin"
      ) {
        Helper.overlay(true);
        apiRequest(
          OperatorListAPI.method,
          OperatorListAPI.url + `?page=${page}&sort_by=${sort ?? sort_by}`
        )
          .then((response) => {
            if (response.data.code === 200) {
              let data = response.data.data;
              setrecords(data);
              dispatch({ type: "SET_SEARCH_STATE", search_list: false });
              setResult(response.data.meta);
              Helper.overlay(false);
            }
          })
          .catch((error) => {
            setErrorMsg(error);
            Helper.overlay(false);
          });
      }
    },
    [dispatch, permissions, sort_by, user.type]
  );

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }

      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          let str = location.pathname;
          let id = str.substr(str.length - 1);
          dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: +id });
          getAdminUserList(+id);
          setLocationKeys(([_, ...keys]) => keys);
        } else {
          let str = location.pathname;
          let id = str.substr(str.length - 1);
          dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: +id });
          getAdminUserList(+id);
          setLocationKeys((keys) => [location.key, ...keys]);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, history, locationKeys]);

  useEffect(() => {
    if (location.hash !== "#search") {
      dispatch({ type: "SET_STATE", response: [] });
      getAdminUserList(state.set_active_page);
    } else {
      Helper.overlay(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location.hash, state.set_active_page]);

  // useEffect(() => {
  //   if (state.pagination === true) {
  //     setrecords(state.data.data)
  //     setResult(state.data.meta)
  //     sethidePagination(true);
  //     dispatch({ type: "SET_SEARCH_STATE", pagination: false });
  //   }
  // }, [dispatch, state.data.data, state.data.meta, state.pagination]);
  useEffect(() => {
    if (
      location.pathname.includes("admin-user-list") &&
      location.hash === "#search"
    ) {
      setrecords(state.data.data || []);
      setResult(state.data.meta || []);
      dispatch({ type: "SET_SEARCH_STATE", pagination: false });
      Helper.overlay(false);
    }
    if (location.hash === "#error") {
      setrecords([]);
      setResult([]);
      setErrorMsg("No record Found");
    }
  }, [dispatch, location, state.data, state.pagination]);

  function getcheckbox() {
    var array = [];
    var checkboxes = document.querySelectorAll(
      "input[name='subcheckbox']:checked"
    );
    for (var i = 0; i < checkboxes.length; i++) {
      array.push(checkboxes[i].value);
      checkboxes[i].checked = false;
    }

    let params = new FormData();
    params.append("ids", array);
    let delete_property = window.confirm(
      "Are you sure you want to delete these records!"
    );
    if (delete_property) {
      apiRequest(alldeleteAdmin.method, alldeleteAdmin.url, params)
        .then((response) => {
          if (response.data.code === 200) {
            let data = state.data;
            array.forEach((el) => {
              data = data.filter((d) => {
                return d.id !== el;
              });
            });
            dispatch({ type: "SET_STATE", response: data });
            setRsModal(false);
          } else {
            console.log("Failure", response);
          }
        })
        .catch((error) => {
          alert(error);
        });
    }
  }

  function handleMainCheckBox(e) {
    var value = false;

    if (e.target.checked) {
      value = true;
    }

    Array.from(document.querySelectorAll("input[name='subcheckbox']")).forEach(
      (checkbox) => {
        checkbox.checked = value;
      }
    );
  }

  const deleteRecord = (e) => {
    //let params = new FormData();
    //params.append("id", e.target.dataset.id);
    //  let delete_property = window.confirm(
    //    "Are you sure you want to delete this record!"
    //  );
    //  if (delete_property) {

    apiRequest(deleteAdmin.method, deleteAdmin.url + `${currentId}`)
      .then((response) => {
        if (response.data.code === 200) {
          let data = records.filter((d) => {
            return d.id !== +currentId;
          });
          setRsModal(false);
          setrecords(data)
          setResult((prev) => ({ ...prev, total: prev.total - 1 }));
        } else {
          console.log("Failure", response);
          setRsModal(false);
        }
      })
      .catch((error) => {
        alert(error);
        setRsModal(false);
      });

    //}
  };

  const handlePageChange = (pageNumber) => {
    dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: pageNumber });
    apiRequest(
      OperatorListAPI.method,
      OperatorListAPI.url + `?page=${pageNumber}&sort_by=${sort_by}`
    )
      .then((response) => {
        if (response.data.code === 200) {
          setrecords(response.data.data);
          setResult(response.data.meta);
          console.log("Success", response);
          Helper.overlay(false);
          history.push(`/admin/admin-user-list/${pageNumber}`);
        } else {
          Helper.overlay(false);
        }
      })
      .catch((error) => {
        Helper.overlay(false);
      });
  };

  const setDropDown = (e) => {
    setSortBy(e.target.value);
    getAdminUserList(state.set_active_page, e.target.value);
  };
  return (
    <React.Fragment>
      <Modal backdrop="static" show={rsModal} onHide={Modalclose} size="xs">
        <Modal.Body>
          <Icon
            icon="remind"
            style={{
              color: "#ffb300",
              fontSize: 24,
            }}
          />
          {"  "}
          Are you sure you want to delete this record?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={deleteRecord} appearance="primary">
            Ok
          </Button>
          <Button onClick={Modalclose} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <div className="wrapper d-flex align-items-stretch">
      <Sidebar /> */}
      <div id="content">
        <Header />
        {Helper.getPermissions(
          "admin-read",
          permissions && permissions,
          "admin"
        ) || Helper.getPermissions("", [], user.type) ? (
          <div className="p-4">
            <div className="row pb-2 d-flex justify-content-between align-items-center border-bottom">
              <div className="col-lg-8 col-md-6">
                <div className="d-flex flex-row">
                  <div className="p-2  d-flex align-items-center">
                    {(Helper.getPermissions(
                      "admin-delete",
                      permissions && permissions,
                      "admin"
                    ) ||
                      Helper.getPermissions("", [], user.type)) && (
                        <CheckBox onChange={handleMainCheckBox} />
                      )}
                  </div>
                  <div className="p-2">
                    {(Helper.getPermissions(
                      "admin-delete",
                      permissions && permissions,
                      "admin"
                    ) ||
                      Helper.getPermissions("", [], user.type)) && (
                        <button
                          className="btn btnn-user btn-primary"
                          onClick={getcheckbox}
                        >
                          Bulk Delete
                        </button>
                      )}
                  </div>
                  {
                    //Helper.getPermissions('usage-create',permissions) &&

                    <div className="p-2">
                      {(Helper.getPermissions(
                        "admin-create",
                        permissions && permissions,
                        "admin"
                      ) ||
                        Helper.getPermissions("", [], user.type)) && (
                          <NavLink
                            exact
                            to={`/admin/add-admin-users-detail`}
                            className="btn btnn-user white bg-orange"
                            style={{ backgroundColor: user.background_color }}
                          >
                            New Admin
                          </NavLink>
                        )}
                    </div>
                  }
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="d-flex align-items-center row">
                  <div className="col-md-12 col-lg-3 pl-lg-0 pr-lg-0">
                    Results: {result.total ? result.total : "1"}
                  </div>
                  <div className="col-md-12 col-lg-9 pl-lg-0 pr-lg-0 row">
                  <label class="col-3 mt-auto">Sort By:</label>
                    <select
                      className="form-select form-control col-8 "
                      aria-label="Default select example"
                      name="sort_by"
                      onChange={setDropDown}
                    >
                      <option selected={sort_by === 1 ? true : false} value={1}>
                        Last created on top
                      </option>
                      <option selected={sort_by === 2 ? true : false} value={2}>
                        First created on top
                      </option>
                      <option selected={sort_by === 3 ? true : false} value={3}>
                        Alphabetically A-Z
                      </option>
                      <option selected={sort_by === 4 ? true : false} value={4}>
                        Alphabetically Z-A
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {records.length
                ? records.map((record, index) => (
                  <div
                    className="card col-md-12 mt-3 shadow bg-white rounded"
                    key={index}
                  >
                    <div className="row">
                      <div className="col-md-12 col-lg-10  col-sm-12 pb-3 pt-2">
                        <div className="row pt-2">
                          <div className="col-12">
                            <input
                              type="checkbox"
                              className=""
                              name="subcheckbox"
                              defaultValue={record.id}
                              id="exampleCheck1"
                            />
                            <NavLink
                              className="ml-3 form-check-label font-weight-bold"
                              exact
                              to={`/admin/edit-admin/${record.id}`}
                            >
                              {record.id}
                            </NavLink>
                          </div>
                        </div>
                        <div className="row pt-2">
                          <div className="col-md-4 col-12">
                            Name: <strong>{record.name}</strong>
                          </div>
                          <div className="col-md-3 col-12">
                            Type: <strong>{record.type}</strong>
                          </div>
                          <div className="col-md-5 col-12">
                            TelePhone:{" "}
                            <strong>
                              <span>
                                {record.country_code
                                  ? record.country_code
                                  : "-"}
                              </span>
                              <span>
                                {" "}
                                {record.contact ? record.contact : "-"}
                              </span>
                            </strong>
                          </div>
                        </div>
                        <div className="row pt-2">
                          <div className="col-md-4 col-12">
                            Drivers behaviour score:{" "}
                            <span className="text-success">
                              {record.score ? record.score : "-"}
                            </span>
                          </div>
                          <div className="col-md-3 col-12">
                            Vehicles: <strong>{record.vehicles}</strong>
                          </div>
                          <div className="col-md-5 col-12">
                            Last Login:{" "}
                            <strong>
                              {record.last_login ? record.last_login : "-"}
                            </strong>
                          </div>
                        </div>
                        <div className="row pt-2">
                          <div className="col-md-4 col-12">
                            Usages:{" "}
                            <strong>
                              {record.usages ? record.usages : "-"}
                            </strong>
                          </div>
                          <div className="col-md-8 col-12">
                            Email: <strong>{record.email}</strong>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12 col-sm-12  col-lg-2 ">
                        <div className="row pt-3 pb-3">
                          <div className="col-md-6 col-sm-6 col-lg-12 pt-lg-4">
                            {Helper.getPermissions(
                              "admin-update",
                              permissions && permissions,
                              "admin"
                            ) || Helper.getPermissions("", [], user.type) ? (
                              <NavLink
                                className=""
                                exact
                                to={`/admin/edit-admin/${record.id}`}
                              >
                                <button className="w-100 btn-success">
                                  Edit
                                </button>
                              </NavLink>
                            ) : null}
                          </div>
                          <div className="col-md-6  col-sm-6 col-lg-12 pt-lg-3 pt-md-0 pt-2">
                            {(Helper.getPermissions(
                              "admin-delete",
                              permissions && permissions,
                              "admin"
                            ) ||
                              Helper.getPermissions("", [], user.type)) && (
                                <button
                                  data-id={record.id}
                                  onClick={Modalopen}
                                  className="btn-danger w-100"
                                >
                                  Delete
                                </button>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
                : errorMsg && (
                  <div className="text-center ft-14 mt-3 font-weight-bold">
                    No Data Found...
                  </div>
                )}
            </div>

            <div className="row mt-3 ft-14">
              <div className="col-md-12">
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-end">
                    <Pagination
                      activePage={state.set_active_page}
                      totalItemsCount={result.total ? result.total : 1}
                      pageRangeDisplayed={result.last_page}
                      itemClass="page-item"
                      linkClass="page-link"
                      onChange={handlePageChange}
                      activeClass={user.type === "whitelabel" ? "active-whitelabel-pagination" : "active-default-pagination "}
                    />
                    <li className="pl-3"></li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        ) : (
          <div className="p-4">
            <div className="text-center ft-14 mt-3 font-weight-bold">
              You are not allowed to visit this screen...
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
export default AdminUserList;

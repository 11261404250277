import React, { useEffect, useState, useRef  } from "react";
import { Redirect } from "react-router-dom";
import Header from "../Header/Index.js";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FlashMessage from "../FlashMessage/FlashMessage.js";
import { Context } from "../Store/Store.js";
import { Modal, Button, Icon } from "rsuite";


const Helper = require("../Helper.js");


var { apiRequest } = require("../Api/Service.js");
var {
    UserPermissions,
    whitelabelListEndpoint,
    searchEmailTemplate,
    searchNotificationTemplate

} = require("../Api/ApiRoutes.js");


const initialState = {
    isError: false,
    errors: [],
    isSuccess: false,
    success: "",
    disable: false,
};

const reducer = (state, action) => {
    switch (action.type) {
        case "FETCH_SUCCESS":
            return {
                ...state,
                isSuccess: true,
                success: action.payload,
                disable: true,
            };
        case "FETCH_ERROR":
            return {
                ...state,
                isError: true,
                errors: action.payload,
                disable: false,
            };
        case "DEFAULT":
            return {
                disable: action.payload,
            };
        default:
            return state;
    }
};


function NotificationFilter(props) {

    var ModuleType = props.type;
    let user = Helper.getStorageData("SESSION");

    const [LocalState, Localdispatch] = React.useReducer(reducer, initialState);
    const [whitelabelList, setWhitelabelList] = React.useState([]);
    const [labelId, setLabelId] = React.useState('');
    const inputRef = useRef("");
    const inputLangRef = useRef("en");
    const inputLabelRef = useRef("");

 
    const handleWhitelabelList = (event) => {
        
        if (user.type !== "whitelabel") {
            apiRequest(
                whitelabelListEndpoint.method,
                whitelabelListEndpoint.url + `per_page=-1`
            )
                .then((response) => {
                    if (response.data.code === 200) {
                        setWhitelabelList(response.data.data);
                    } else {
                        console.log("Failure", response);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        
    };
   
    useEffect(() => {
       
         handleWhitelabelList()
    }, [])

    const reset = () => {
        
        
        const form_data = new FormData();
        let requestURL =''
        let requestMethod =''
        if(ModuleType == 'EMAIL'){
             requestURL =  searchEmailTemplate.url;  
             requestMethod =  searchEmailTemplate.method;  
        }
        else{
            requestURL =  searchNotificationTemplate.url;  
            requestMethod =  searchNotificationTemplate.method;  
        }
        apiRequest(requestMethod, requestURL, form_data)
        .then((response) => {
            let data = response.data.data;
            props.search(data);
            inputRef.current.value = "";
            inputLangRef.current.value = '';
            inputLabelRef.current.value = "";
        })
        .catch((error) => {
            console.log(error);
            
            window.scrollTo(500, 0);
        });
    }
    const handleSubmit = (values) => {

        const form_data = new FormData();
        if(user.type === "whitelabel"){
            form_data.append("label_id", user.label_id);
        }
        else{
            if(values.label_id !== ''){
                form_data.append("label_id", values.label_id);
            }
        }

        if(values.keyword !== ''){
            form_data.append("keyword", values.keyword);
        }

        if(values.lang !== ''){
            form_data.append("lang", values.lang);
        }
        let requestURL =''
        let requestMethod =''
        if(ModuleType == 'EMAIL'){
             requestURL =  searchEmailTemplate.url;  
             requestMethod =  searchEmailTemplate.method;  
        }
        else{
            requestURL =  searchNotificationTemplate.url;  
            requestMethod =  searchNotificationTemplate.method;  
        }
        apiRequest(requestMethod, requestURL, form_data)
            .then((response) => {
                let data = response.data.data;
                props.search(data);
            })
            .catch((error) => {
                console.log(error);
                
                window.scrollTo(500, 0);
            });
    };

 
    const handleWhitelabelChange = (event) => {
        let id = event.target.value;
        setLabelId(id);
    }
    return (
        <React.Fragment>
           
            <div id="content">
              
                <div className="p-4">
                    <Formik
                        validateOnChange={false}
                        onSubmit={async (values) => {
                            handleSubmit(values);
                        }}
                        initialValues={{
                            lang: "",
                            label_id: "",
                            keyword:"",
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            isSubmitting,
                            setFieldValue,
                            handleBlur,
                            values,
                            touched,
                            isValid,
                            errors,
                        }) => (
                            <Form className="mt-2 user">
                                <FlashMessage
                                    success={LocalState.success}
                                    isSuccess={LocalState.isSuccess}
                                    isError={LocalState.isError}
                                    errors={LocalState.errors}
                                />
                                
                                <div className="form-row">
                                    
                                    <div className="form-group col-md-3">
                                        <label className="form-label">Keyword</label>
                                        <input
                                            type="text"
                                            name="keyword"
                                            ref={inputRef}
                                            value={values.keyword || ""}
                                            onChange={(e) => {
                                                values.lang = ''
                                                handleChange(e);
                                            }}
                                            placeholder="title"
                                            className={`form-control form-control-user ${errors.template_name ? "error" : ""}`}
                                        />
                                        {errors.template_name && (
                                        <div className="ft-14 mt-1 red">
                                            {errors.template_name}
                                        </div>
                                        )}
                                    </div>
  
                                    <div className="form-group col-md-3">
                                        <label className="form-label">Select Language</label>
                                        <select
                                            ref={inputLangRef} 
                                            name="lang"
                                            value={values.lang || ""}
                                            onChange={(e) => {
                                                values.lang = ''
                                                handleChange(e);
                                            }}
                                            className={`form-control hideArrow form-select-user dropdown-height ${errors.lang ? "error" : ""
                                        }`}
                                        > 
                                        <option value="">All</option>
                                        <option value="en">English</option>
                                        <option value="et">Estonian</option>
                                        </select>
                                        <div className="custom-arrow"></div>
                                        {errors.lang && (
                                            <div className="ft-14 mt-1 red">{errors.lang}</div>
                                        )}

                                    </div>
                                    {user.type !== "whitelabel" && (
                                        <div className="form-group col-md-3">
                                            <label className="form-label">WhiteLabel</label>
                                            <select
                                                ref={inputLabelRef}
                                                name="label_id"
                                                onChange={(e) => {
                                                    values.vehicle_id = ''
                                                    handleWhitelabelChange(e);
                                                    handleChange(e);
                                                }}
                                                className={`form-control hideArrow form-select-user dropdown-height ${errors.label_id ? "error" : ""
                                            }`}
                                            > <option value="">Select WhiteLabel</option>
                                                {whitelabelList &&
                                                    whitelabelList.map((record, index) => (
                                                        <option value={record.id} key={index}>
                                                            {record.name}
                                                        </option>
                                                    ))}</select><div className="custom-arrow"></div>
                                            {errors.label_id && (
                                                <div className="ft-14 mt-1 red">{errors.label_id}</div>
                                            )}

                                        </div>  
                                    )} 
                                    <div className="form-group col-md-3 d-flex align-items-center mt-4 ">
                                        <button
                                            type="submit"
                                            className="btn-submit"
                                            disabled={LocalState.disable}
                                            style={{ backgroundColor: user.background_color }}
                                        >
                                            Search
                                        
                                        </button>
                                        <button
                                            type="button"
                                            className="btn-submit ml-1"
                                            onClick={reset}
                                            style={{ backgroundColor: user.background_color }}
                                        >
                                            Reset
                                        
                                        </button>
                                    </div>
                                </div>
     

                            </Form>
                        )}
                    </Formik>
                </div>
                
            </div>
        </React.Fragment>
    );
}

export default NotificationFilter;



const _ = require("lodash");
const secretKey = "app.movitronic";
var CryptoJS = require("crypto-js");
var moment = require("moment-timezone");

module.exports = {
  getStorageData: function (key) {
    let user = _.isEmpty(localStorage.getItem(key))
      ? {}
      : localStorage.getItem(key);
    // Decrypt
    if (user.length) {
      var bytes = CryptoJS.AES.decrypt(user, secretKey);
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return _.isEmpty(decryptedData) ? {} : decryptedData;
    }
  },
  localTimezone: function () {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  },
  setStorageData: function (key, value) {
    // Encrypt
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(value),
      secretKey
    ).toString();
    localStorage.setItem(key, ciphertext);
  },
  formData: function (object) {
    console.log("formData");
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  },

  baseUrl: function () {
    return process.env.REACT_APP_API_URL;
  },
  overlay(is_show = false) {
    let user = this.getStorageData("SESSION");
    
    if (is_show === true) {
      const whiteLabelOverlay = document.getElementById("whiteLabelOverlay");
      const whiteLabelSpinner = document.getElementById("whiteLabelSpinner")
      const div = document.getElementById("overlay");
      if (user.type === "whitelabel") {
        whiteLabelOverlay.style.display = "block";
        whiteLabelSpinner.style.display = "block";
      } else {
        div.style.display = "block";
        div.style.display = "flex";
        div.style.justifyContent = "center";
        div.style.alignItems = "center";
      }
    } else {
        document.getElementById("whiteLabelOverlay").style.display = "none";
        document.getElementById("whiteLabelSpinner").style.display = "none";
        document.getElementById("overlay").style.display = "none";
    }
  },

  getPermissions: function (
    module_name = "",
    module_array = [],
    user_type = ""
  ) {
    if (user_type === "superadmin") {
      return true;
    } else {
      return module_array.includes(module_name);
    }
  },

  getDateByTZ: function (date) {
    const utz = this.getStorageData("SESSION");

    if (utz.timezone === "") {
      const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return this.changeTimeZone(date, localTimeZone);
    } else {
      return this.changeTimeZone(date, utz.timezone);
    }
  },

  padTo2Digits: function (num) {
    return num.toString().padStart(2, "0");
  },
  formatDate: function (date) {
    return (
      [
        date.getFullYear(),
        this.padTo2Digits(date.getMonth() + 1),
        this.padTo2Digits(date.getDate()),
      ].join("-") +
      " " +
      [
        this.padTo2Digits(date.getHours()),
        this.padTo2Digits(date.getMinutes()),
        this.padTo2Digits(date.getSeconds()),
      ].join(":")
    );
  },
  changeTimeZone: function (date, timeZone) {
    if (date == null || date == "") {
      return date;
    }
    try {
      return moment.utc(date).tz(timeZone).format("YYYY-MM-DD HH:mm:ss");
    } catch (error) {
      return date;
    }

  },

  convertUtcToTarget: function (inputDatetime, targetTimezone) {
    return moment
      .utc(inputDatetime)
      .tz(targetTimezone)
      .format("YYYY-MM-DD HH:mm:ss");
  },

  convertTargetToUtc: function (targetTimezone) {
    return moment.tz(targetTimezone).utc().format("YYYY-MM-DD HH:mm:ss");
  },

  LocalToTz: function (date, targetTimezone) {
    // console.log("||||||||||||||||||||||||||||");
    // const utcDatetime = date; // Assuming input datetime is in UTC
    // const convertedDatetime = this.convertUtcToTarget(utcDatetime, targetTimezone);
    // console.log(` Datetime: ${moment(utcDatetime).utc().format('YYYY-MM-DD HH:mm:ss')}`);
    // console.log(`Converted Datetime to ${targetTimezone}: ${convertedDatetime}`);
    // const currentDatetimeInTarget = this.convertTargetToUtc(targetTimezone);
    // console.log(`Current Datetime in ${targetTimezone}: ${moment().tz(targetTimezone).format('YYYY-MM-DD HH:mm:ss')}`);
    // console.log(`Converted Datetime to UTC: ${currentDatetimeInTarget}`);
    // console.log("|||||||||||-----|||||||||||||||||");
    // tz = "Europe/Tallinn";
    // if(date == null || date == ""){
    //   return date;
    // }
    // if (typeof date === 'string') {
    //   return new Date(
    //     new Date(date).toLocaleString('en-US', {
    //       timeZone:tz,
    //     }),
    //   );
    // }
    // return new Date(
    //   date.toLocaleString('en-US', {
    //    timeZone:tz,
    //   }),
    // );
  },
  convertToUTC: function (dateString) {
    console.log(dateString);
    const utz = this.getStorageData("SESSION");
    let localDate;
    //if(utz.timezone === ""){
    localDate = new Date(dateString);
    // }
    // else{
    //   localDate = this.LocalToTz(dateString,utz.timezone);
    // }
    console.log(localDate);

    if (isNaN(localDate.getTime())) {
      return "Invalid date string: Unable to parse the date.";
    }

    const year = localDate.getUTCFullYear();
    const month = String(localDate.getUTCMonth() + 1).padStart(2, "0");
    const day = String(localDate.getUTCDate()).padStart(2, "0");
    const hours = String(localDate.getUTCHours()).padStart(2, "0");
    const minutes = String(localDate.getUTCMinutes()).padStart(2, "0");
    const seconds = String(localDate.getUTCSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  },

  loadScript(src) {
    var tag = document.createElement("script");
    tag.async = false;
    tag.src = src;
    var body = document.getElementsByTagName("body")[0];
    body.appendChild(tag);
  },
};

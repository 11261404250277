import React, { useEffect, useRef, useState } from "react";
import { Redirect } from "react-router-dom";
import Header from "../Header/Index.js";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FlashMessage from "../FlashMessage/FlashMessage";
import moment from "moment";
import EditorConvertToHTML from "../Contract/Editor.js";
import { Button, Image, Modal, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Collapse } from 'antd';
import SaveButton from "../../commonComponents/Button.js";
const { Panel } = Collapse;

var { apiRequest } = require("../Api/Service");
var {
    whitelabelListEndpoint,
    getWhitelabelVehicles,
    GetTemplate,
    CreateBooking,
    GetWhiteLabelStation,
    getPlaceholder,
    getInsuracePlaceholder,
    GetInsuranceTemplate,
    ExtraWhitelableuserLsting,
} = require("../Api/ApiRoutes");
const Helper = require("../Helper");

const initialState = {
    isError: false,
    errors: [],
    isSuccess: false,
    success: "",
    disable: false,
};

const reducer = (state, action) => {
    switch (action.type) {
        case "FETCH_SUCCESS":
            return {
                ...state,
                isSuccess: true,
                success: action.payload,
                disable: true,
            };
        case "FETCH_ERROR":
            return {
                ...state,
                isError: true,
                errors: action.payload,
                disable: false,
            };
        case "DEFAULT":
            return {
                disable: action.payload,
            };
        default:
            return state;
    }
};

function AddBooking(props) {
    const [LocalState, Localdispatch] = React.useReducer(reducer, initialState);
    const [redirectToReferrer, setredirectToReferrer] = useState(false);
    const [whitelabelList, setWhitelabelList] = React.useState([]);
    const [vehicleList, setVehiclelList] = React.useState([]);
    const [additionalDriverFields, setAdditionalDriverFields] = useState([""]);
    const [showTemplate, setShowTemplate] = React.useState(false);
    const [getTemplate, setGetemplate] = React.useState(false);
    const [template, setTemplate] = React.useState("");
    const [showOthersFields, setShowOthersFields] = React.useState(false);
    const [showOthersDropFields, setShowOthersDropFields] = React.useState(false);
    const [whitelabelStationList, setWhitelabelStationList] = useState([]);
    const [mainDriver, setMainDriver] = useState("");
    const [additionalDriverErrors, setAdditinalDriverErrors] = useState([]);
    const [placeholder, setPlaceholder] = React.useState([]);
    const [Insuranceplaceholder, setInsurancePlaceholder] = React.useState([]);
    const [getInsuranceTemplate, setGeInsurancetemplate] = React.useState(false);
    const [Insurancetemplate, setInsuranceTemplate] = React.useState([]);
    const [showInsuranceTemplate, setShowInsuranceTemplate] =
        React.useState(false);
    const [defaultDropoff, setDefaultDropoff] = React.useState(0);
    const [defaultPick, setDefaultPick] = React.useState(0);
    const [pdfFile, setPdfFile] = React.useState(null);
    const [emailError, setEmailError] = React.useState(null);
    const [previewVisible, setPreviewVisible] = React.useState(false);
    const [previewVisibleOnlyOnce, setPreviewVisibleOnlyOnce] = React.useState(0);
    const [pdfError, setPdfError] = React.useState(undefined);
    const [previewImage, setPreviewImage] = useState("");

    const [getInsuranceetTemplate, setGeInsuranceettemplate] =
        React.useState(false);
    const [Insuranceettemplate, setInsuranceetTemplate] = React.useState([]);
    const [showInsuranceetTemplate, setShowInsuranceetTemplate] =
        React.useState(false);

    const [extraDefaultFileds, setExtraDefaultFileds] = useState([]);
    const [extraFileds, setExtraFileds] = useState([]);
    const [extraAddNewFileds, setAddNewExtraFileds] = useState([]);
    const [extraOption, setExtraOption] = useState([]);
    const [selectedAgent, setSelectedAgent] = useState("");
    const [excessAmount, setExcessAmount] = useState("");
    const [selanotherField, setSelanotherField] = useState("");
    let user = Helper.getStorageData("SESSION");
    let labelId = props.match.params.id;
    const errorFieldRef = useRef(null);

    const emailSchema = Yup.string().email('Invalid email format');

    const arrayOfEmailsSchema = Yup.array().of(emailSchema);

    const DetailSchema = Yup.object()
        .shape({
            start_date: Yup.string().required("This Field is Required"),
            end_date: Yup.mixed().required("This Field is Required"),
            agent: Yup.mixed().required("This Field is Required"),
            vehicle_id: Yup.mixed().required("This Field is Required"),
            insurance_price: Yup.string()
                .required("This Field is Required")
                .matches(/^\d+$/, "Input must be a number"),
            ...(user.type !== "whitelabel"
                ? { label_id: Yup.mixed().required("This Field is Required") }
                : {}),
            main_driver: Yup.string()
                .required("This Field is Required")
                .test(
                    "main-driver-not-included",
                    "Main driver cannot be the same as the additional driver",
                    function (value) {
                        return !additionalDriverFields.includes(value);
                    }
                ),
        })
        .test(
            "custom-validation",
            "This is a custom validation message",
            function (values) {
                if (!pdfFile) {
                    setPdfError("PDF file is required.");
                    return false;
                }

                return true;
            }
        );

    const handleWhitelabelList = (event) => {
        apiRequest(
            whitelabelListEndpoint.method,
            whitelabelListEndpoint.url + `per_page=-1`
        )
            .then((response) => {
                if (response.data.code === 200) {
                    setWhitelabelList(response.data.data);
                } else {
                    console.log("Failure", response);
                }
            })
            .catch((error) => {
                console.log(error);
            });

        apiRequest(getPlaceholder.method, getPlaceholder.url)
            .then((response) => {
                if (response.data.code === 200) {
                    setPlaceholder(response.data.data);
                } else {
                    console.log("Failure", response);
                }
            })
            .catch((error) => {
                console.log(error);
            });

        apiRequest(getInsuracePlaceholder.method, getInsuracePlaceholder.url)
            .then((response) => {
                if (response.data.code === 200) {
                    setInsurancePlaceholder(response.data.data);
                } else {
                    console.log("Failure", response);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleVehicles = (id) => {
        apiRequest(
            getWhitelabelVehicles.method,
            getWhitelabelVehicles.url + `label_id=${id}`
        )
            .then((response) => {
                if (response.data.code === 200) {
                    setVehiclelList(response.data.data);
                } else {
                    console.log("Failure", response);
                }
            })
            .catch((error) => {
                console.log(error);
            });

        apiRequest(GetTemplate.method, GetTemplate.url + `${id}`)
            .then((response) => {
                setTemplate(response);
                setShowTemplate(true);
            })
            .catch((error) => {
                setShowTemplate(false);
                console.log(error);
            });

        apiRequest(
            GetInsuranceTemplate.method,
            GetInsuranceTemplate.url + `${id}&lang=en`
        )
            .then((response) => {
                setInsuranceTemplate(response);
                setShowInsuranceTemplate(true);
            })
            .catch((error) => {
                setShowInsuranceTemplate(false);
                console.log(error);
            });
        apiRequest(
            GetInsuranceTemplate.method,
            GetInsuranceTemplate.url + `${id}&lang=et`
        )
            .then((response) => {
                setInsuranceetTemplate(response);
                setShowInsuranceetTemplate(true);
            })
            .catch((error) => {
                setShowInsuranceetTemplate(false);
                console.log(error);
            });
        apiRequest(GetWhiteLabelStation.method, GetWhiteLabelStation.url + `${id}`)
            .then((response) => {
                if (response.data.code === 200) {
                    setWhitelabelStationList(response.data.data);
                    setDefaultDropoff(response.data.default_dropoff_station_id);
                    setDefaultPick(response.data.default_pickup_station_id);
                } else {
                    console.log("Failure", response);
                }
            })
            .catch((error) => {
                console.log(error);
            });

        apiRequest(getPlaceholder.method, getPlaceholder.url)
            .then((response) => {
                if (response.data.code === 200) {
                    setPlaceholder(response.data.data);
                } else {
                    console.log("Failure", response);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        apiRequest(getInsuracePlaceholder.method, getInsuracePlaceholder.url)
            .then((response) => {
                if (response.data.code === 200) {
                    setInsurancePlaceholder(response.data.data);
                } else {
                    console.log("Failure", response);
                }
            })
            .catch((error) => {
                console.log(error);
            });

        apiRequest(
            ExtraWhitelableuserLsting.method,
            ExtraWhitelableuserLsting.url + `${id}`
        )
            .then((response) => {
                if (response.data.code === 200) {
                    setExtraFileds([...extraFileds, {}]);
                    if (response.data.data.length === 0) {
                        // setExtraFileds([...extraFileds, {
                        // "title": "",
                        // "title_en": "",
                        // "title_es": "",
                        // "price": 0,
                        // "is_daily": false,
                        // "is_default": false,
                        // "is_vat": false }])
                    } else {
                        let defaultvalues = response.data.data.filter((d) => {
                            return d.is_default === true;
                        });
                        setExtraDefaultFileds(defaultvalues);

                        let otherVlaues = response.data.data.filter((d) => {
                            return d.is_default !== true;
                        });
                        setExtraFileds(otherVlaues);
                    }
                } else {
                    console.log("Failure", response);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleWhitelabelChange = (event) => {
        let id = event.target.value;
        handleVehicles(id);
    };
    const handTemplate = (template) => {
        setGetemplate(template);
        console.log(template);
    };

    const handInsuranceTemplate = (template) => {
        setGeInsurancetemplate(template);
        console.log(template);
    };

    const handInsuranceetTemplate = (template) => {
        setGeInsuranceettemplate(template);
        console.log(template);
    };

    useEffect(() => {
        if (user.type === "whitelabel") {
            handleVehicles(labelId);
        } else {
            handleWhitelabelList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const scrollToErrorField = () => {
            const errorField = document.querySelector(".error");
            const pdfErrorField = document.querySelector(".pdf-error");

            const errorRect = errorField?.getBoundingClientRect();
            const pdfErrorRect = pdfErrorField?.getBoundingClientRect();

            const rect = errorRect || pdfErrorRect;

            if (rect) {
                const midpoint = rect.top + rect.height / 2;
                const scrollToY = midpoint + window.scrollY - window.innerHeight / 2;

                window.scrollTo({
                    top: scrollToY,
                    behavior: "smooth",
                });
            }
        };

        document.addEventListener("scrollToTop", scrollToErrorField);

        return () => {
            document.removeEventListener("scrollToTop", scrollToErrorField);
        };
    }, []);

    const handleSubmit = (values) => {
        // Check if pdfFile is present
        if (!pdfFile) {
            // Show an error message
            setPdfError("PDF file is required.");
            return; // Prevent further execution of the function
        }
        console.log(emailError);
        if (emailError) {

            Localdispatch({
                type: "FETCH_ERROR",
                payload: emailError,
            });
            window.scrollTo(500, 0);
            return; // Prevent further execution of the function
        }


        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let additionalDrivers = [];
        if (additionalDriverFields[0] !== "") {
            additionalDrivers = additionalDriverFields?.map((val) => {
                return val;
            });
        }


        const form_data = new FormData();
        form_data.append(
            "start_date",
            Helper.convertToUTC(
                moment(values.start_date).format("YYYY-MM-DD HH:mm:ss")
            )
        );
        form_data.append(
            "end_date",
            Helper.convertToUTC(moment(values.end_date).format("YYYY-MM-DD HH:mm:ss"))
        );
        form_data.append("main_driver", values.main_driver);
        form_data.append("additional_drivers", additionalDrivers);
        form_data.append("label_id", values.label_id);
        form_data.append("vehicle_id", values.vehicle_id);
        form_data.append("timezone", timezone);
        form_data.append("insurance_price", values.insurance_price);
        form_data.append("vehicle_condition_pdf", pdfFile);
        form_data.append("agent", selectedAgent);
        form_data.append("excess_amount", excessAmount);
        const extraFields = [...extraDefaultFileds, ...extraAddNewFileds];
        const images = extraFields.length > 0
        ? extraFields.map((item, index) => {
            if ('image' in item && typeof item.image === 'string' && item.image.trim() !== '') {
                form_data.append(`extra-image[${index}]`, item.image);
            } else if ('image' in item && Array.isArray(item.image) && item.image.length > 0) {
                console.log(Array.isArray(item.image))
                console.log(item.image.length)
                console.log(item.image[0].originFileObj)
                form_data.append(`extra-image[${index}]`, item.image[0].originFileObj);
            } else if ('image' in item) {
                form_data.append(`extra-image[${index}]`, "");
            } else {
                console.warn(`Item at index ${index} does not have an image key.`);
            }
        })
        : [];
        form_data.append("extras", [JSON.stringify(extraFields)]);
        if (
            values.pickup_station_id !== "other" &&
            values.pickup_station_id !== "default"
        ) {
            if (values.pickup_station_id === "") {
                form_data.append("pickup_station_id", defaultPick);
            } else {
                form_data.append("pickup_station_id", values.pickup_station_id);
            }
            form_data.append("pickup_station_type", "1");
            form_data.append("pickup_station_name", null);
        }

        if (
            values.dropoff_station_id !== "other" &&
            values.dropoff_station_id !== "default"
        ) {
            if (values.dropoff_station_id === "") {
                form_data.append("dropoff_station_id", defaultDropoff);
            } else {
                form_data.append("dropoff_station_id", values.dropoff_station_id);
            }

            form_data.append("dropoff_station_type", "1");
            form_data.append("dropoff_station_name", null);
        }

        if (values.pickup_station_id === "default") {
            form_data.append("pickup_station_id", null);
            form_data.append("pickup_station_type", "2");
            form_data.append("pickup_station_name", null);
        }
        if (values.dropoff_station_id === "default") {
            form_data.append("dropoff_station_id", null);
            form_data.append("dropoff_station_type", "2");
            form_data.append("dropoff_station_name", null);
        }

        if (values.pickup_station_id === "other") {
            form_data.append("pickup_station_id", null);
            form_data.append("pickup_station_type", "3");
            form_data.append("pickup_station_name", values.pickup_station_name);
        }
        if (values.dropoff_station_id === "other") {
            form_data.append("dropoff_station_id", null);
            form_data.append("dropoff_station_type", "3");
            form_data.append("dropoff_station_name", values.dropoff_station_name);
        }

        if (getTemplate !== false) {
            form_data.append("contract", getTemplate);
        } else {
            form_data.append("contract", template.data);
        }

        if (getInsuranceTemplate !== false) {
            form_data.append("extra_insurance_content", getInsuranceTemplate);
        } else {
            form_data.append("extra_insurance_content", Insurancetemplate.data);
        }

        if (getInsuranceetTemplate !== false) {
            form_data.append("extra_insurance_content_et", getInsuranceetTemplate);
        } else {
            form_data.append("extra_insurance_content_et", Insuranceettemplate.data);
        }
        Localdispatch({ type: "DEFAULT", payload: true });
        apiRequest(CreateBooking.method, CreateBooking.url, form_data)
            .then((response) => {
                if (response.data.code === 200) {
                    Localdispatch({
                        type: "FETCH_SUCCESS",
                        payload: response.data.message,
                    });
                    setTimeout(() => {
                        setredirectToReferrer(true);
                    }, 2000);
                }
            })
            .catch((error) => {
                console.log(error);
                Localdispatch({
                    type: "FETCH_ERROR",
                    payload: error.response.data.data,
                });
                window.scrollTo(500, 0);
            });
    };

    const handleFieldBlur = (value, name) => {
        let error;
        if (!value) {
            error = `This Field is Required`;
        } else if (name === "main_driver") {
            setMainDriver(value);
            if (additionalDriverFields.includes(value))
                error = `Main driver cannot be the same as the additional driver`;
        } else {
            error = undefined;
        }
        return error;
    };

    const handleWhitelabelStationPickupChange = (event) => {
        let id = event.target.value;
        setDefaultPick(id);
        if (id === "other") {
            setShowOthersFields(true);
        } else {
            setShowOthersFields(false);
        }
    };

    const handleWhitelabelStationDropoffChange = (event) => {
        let id = event.target.value;
        setDefaultDropoff(id);
        if (id === "other") {
            setShowOthersDropFields(true);
        } else {
            setShowOthersDropFields(false);
        }
    };

    const handleAdditionalExtraFields = () => {
        if (extraOption.length === 0) {
            return false;
        }
        if (extraOption === "other") {
            setAddNewExtraFileds([
                ...extraAddNewFileds,
                {
                    title: "",
                    title_en: "",
                    title_es: "",
                    price: 0,
                    is_daily: false,
                    is_default: false,
                    is_vat: false,
                    image: [],
                    type:null,
                    item_type:null,
                    short_description_en:"",
                    short_description_et:"",
                    long_description_en:"",
                    long_description_et:"",
                },
            ]); // Add a new empty input field to the state
        } else {
            // eslint-disable-next-line eqeqeq
            let ob = extraFileds.filter((_, index) => index == extraOption);
            console.log(ob[0].title);
            setExtraDefaultFileds([
                ...extraDefaultFileds,
                {
                    title: ob[0].title,
                    title_en: ob[0].title_en,
                    title_es: ob[0].title_es,
                    price: ob[0].price,
                    is_daily: ob[0].is_daily,
                    is_default: ob[0].is_default,
                    is_vat: ob[0].is_vat,
                    image: ob[0].image,
                    type:ob[0].type,
                    item_type:ob[0].item_type,
                    short_description_en:ob[0].short_description_en,
                    short_description_et:ob[0].short_description_et,
                    long_description_en:ob[0].long_description_en,
                    long_description_et:ob[0].long_description_et,
                },
            ]);

            console.log(extraDefaultFileds);

            const values = [...extraFileds];
            values.splice(extraOption, 1);
            setExtraFileds(values);
            setExtraOption([]);
        }
        setSelanotherField("");
    };

    const handleAssignNewExtra = (event) => {
        setExtraOption(event.target.value);
        setSelanotherField(event.target.value);
        if (event.target.value === "other") {
            //handleAdditionalExtraFields();
        }
    };

    const handleAdditionalChangeFields = (index, event) => {
        const values = [...additionalDriverFields];
        values[index] = event.target.value;
        setAdditionalDriverFields(values);

        arrayOfEmailsSchema.validate(values)
            .then((valid) => {
                console.log('All emails are valid');
                setEmailError(null);
            })
            .catch((error) => {
                console.log(error.errors);
                setEmailError(error.errors);
            });

    };

    const handleAdditionalFields = () => {
        setAdditionalDriverFields([...additionalDriverFields, ""]); // Add a new empty input field to the state
    };

    const handleRemoveAdditionalFields = (index) => {
        const values = [...additionalDriverFields];
        values.splice(index, 1); // Remove the input field at the given index
        setAdditionalDriverFields(values);
    };
    const handlePreview = () => {
        setPreviewVisible(previewVisibleOnlyOnce >= 1 ? false : true);
        setPreviewVisibleOnlyOnce(previewVisibleOnlyOnce + 1);
    };

    const handleCancel = () => {
        setPreviewVisible(false);
    };

    const beforeUpload = (file) => {
        const isPdf = file.type === "application/pdf";
        if (!isPdf) {
            setPdfError("You can only upload PDF files!");
            return;
        } else {
            setPdfFile(file);
            setPdfError(undefined);
        }
        setPreviewVisibleOnlyOnce(0);
        return false;
    };

    const handelExtraFields = (index, key, event) => {
        const values = [...extraDefaultFileds];

        if (key === "price") {
            values[index].price = event.target.value;
        }

        setExtraDefaultFileds(values);
        console.log(extraDefaultFileds);
    };

    const handelExtraAddNewFields = (index, key, event) => {
        const values = [...extraAddNewFileds];
        const setValueForKey = (targetKey, value) => {
            values[index][targetKey] = value;
        };

        switch (key) {
            case "title":
            case "price":
            case "title_en":
            case "title_es":
                setValueForKey(key, event.target.value);
                break;

            case "is_default":
            case "is_vat":
            case "is_daily":
                setValueForKey(key, event.target.checked);
                break;

            case "type":
            case "item_type":
            case "short_description_en":
            case "short_description_et":
            case "long_description_en":
            case "long_description_et":
                setValueForKey(key, event);
                break;

            case "image":
                if (event.status === "removed") {
                    values[index].image = [];
                } else {
                    const _file = { ...event, status: "done" };
                    values[index].image = [_file];
                }
                break;

            default:
                break;
        }

        setAddNewExtraFileds(values);
        setSelanotherField("");
    };

    const handleRemoveExtraAddNewFileds = (index) => {
        const values = [...extraAddNewFileds];
        values.splice(index, 1); // Remove the input field at the given index
        setAddNewExtraFileds(values);
    };

    const agentOptions = [
        "ERGO Insurance SE",
        "AB Lietuvos draudimas Eesti Filiaal",
        "Compensa Vienna insurance Group, ADB EESTI",
        "IF P&C INSURANCE AS",
        "LHV Kindlustus AS",
    ];

    const handleAgentChange = (selectedAgent) => {
        setSelectedAgent(selectedAgent);

        // Set excess amount based on the selected agent
        switch (selectedAgent) {
            case "ERGO Insurance SE":
                setExcessAmount(`300€`);
                break;
            case "AB Lietuvos draudimas Eesti Filiaal":
            case "Compensa Vienna insurance Group, ADB EESTI":
            case "IF P&C INSURANCE AS":
            case "LHV Kindlustus AS":
                setExcessAmount(`200€`);
                break;
            default:
                setExcessAmount("");
        }
    };

    const handleRemoveExtraFileds = (index) => {
        let ob = extraDefaultFileds.filter((_, i) => i === index);

        const values = [...extraDefaultFileds];
        values.splice(index, 1); // Remove the input field at the given index
        setExtraDefaultFileds(values);

        setExtraFileds([
            ...extraFileds,
            {
                title: ob[0].title,
                title_en: ob[0].title_en,
                title_es: ob[0].title_es,
                price: ob[0].price,
                is_daily: ob[0].is_daily,
                is_default: ob[0].is_default,
                is_vat: ob[0].is_vat,
                image: ob[0].image,
                type:ob[0].type,
                item_type:ob[0].item_type,
                short_description_en:ob[0].short_description_en,
                short_description_et:ob[0].short_description_et,
                long_description_en:ob[0].long_description_en,
                long_description_et:ob[0].long_description_et,
                
            },
        ]);
    };

    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const handleImgPreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.preview);
        setPreviewVisible(!previewVisible);
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    if (redirectToReferrer) {
        return (
            <Redirect
                to={{
                    pathname: `/admin/booking-list/1`,
                }}
            />
        );
    }

    return (
        <React.Fragment>
            {/* <div className="wrapper d-flex align-items-stretch">
        <Sidebar /> */}
            <div id="content">
                <Header showSearch={false} showAdvanceSearch={false} />
                {/* {Helper.getPermissions(
            "admin-create",
            user_permissions && user_permissions,
            "admin"
          ) || Helper.getPermissions("", [], user.type) ? ( */}
                <div className="p-4">
                    <h4 className="mb-3">Add Booking</h4>

                    <Formik
                        validateOnChange={false}
                        validationSchema={DetailSchema}
                        onSubmit={async (values) => {
                            handleSubmit(values);
                        }}
                        initialValues={{
                            start_date: "",
                            end_date: "",
                            main_driver: "",
                            label_id: "",
                            vehicle_id: "",
                            insurance_price: "",
                            pickup_station_id: "",
                            dropoff_station_id: "",
                            agent: "",
                            excess_amount: "",
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            isSubmitting,
                            setFieldValue,
                            handleBlur,
                            values,
                            touched,
                            isValid,
                            errors,
                        }) => (
                            <Form className="mt-2 user">
                                <FlashMessage
                                    success={LocalState.success}
                                    isSuccess={LocalState.isSuccess}
                                    isError={LocalState.isError}
                                    errors={LocalState.errors}
                                />
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label className="form-label">Start Date</label>
                                        <input
                                            type="datetime-local"
                                            name="start_date"
                                            value={values.start_date || ""}
                                            onChange={(e) => {
                                                handleChange(e);
                                                const error = handleFieldBlur(e.target.value);
                                                errors.start_date = error;
                                            }}
                                            placeholder="Start Date"
                                            className={`form-control form-control-user ${errors.start_date ? "error" : ""
                                                }`}
                                        />
                                        {errors.start_date && (
                                            <div className="ft-14 mt-1 red">{errors.start_date}</div>
                                        )}
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label className="form-label">Main Driver</label>
                                        <input
                                            type="email"
                                            name="main_driver"
                                            onChange={(e) => {
                                                handleChange(e);
                                                const error = handleFieldBlur(
                                                    e.target.value,
                                                    "main_driver"
                                                );
                                                errors.main_driver = error;
                                            }}
                                            placeholder="Main Driver"
                                            className={`form-control form-control-user ${errors.main_driver ? "error" : ""
                                                }`}
                                        />
                                        {errors.main_driver && (
                                            <div className="ft-14 mt-1 red">{errors.main_driver}</div>
                                        )}
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label className="form-label">End Date</label>

                                        <input
                                            type="datetime-local"
                                            name="end_date"
                                            value={values.end_date || ""}
                                            onChange={(e) => {
                                                handleChange(e);
                                                const error = handleFieldBlur(e.target.value);
                                                errors.end_date = error;
                                            }}
                                            placeholder="End Date"
                                            id="error1"
                                            className={`form-control form-control-user ${errors.end_date ? "error" : ""
                                                }`}
                                        />
                                        {errors.end_date && (
                                            <div className="ft-14 mt-1 red">{errors.end_date}</div>
                                        )}
                                    </div>
                                    {user.type !== "whitelabel" ? (
                                        <>
                                            <div className="form-group col-md-6">
                                                <label className="form-label">WhiteLabel</label>
                                                <select
                                                    name="label_id"
                                                    onChange={(e) => {
                                                        values.vehicle_id = "";
                                                        handleWhitelabelChange(e);
                                                        handleChange(e);
                                                        const error = handleFieldBlur(e.target.value);
                                                        errors.label_id = error;
                                                    }}
                                                    className={`form-control hideArrow form-select-user dropdown-height ${errors.label_id ? "error" : ""
                                                        }`}
                                                >
                                                    {" "}
                                                    <option value="">Select WhiteLabel</option>
                                                    {whitelabelList &&
                                                        whitelabelList.map((record, index) => (
                                                            <option value={record.id} key={index}>
                                                                {record.name}
                                                            </option>
                                                        ))}
                                                </select>
                                                <div className="custom-arrow"></div>
                                                {errors.label_id && (
                                                    <div className="ft-14 mt-1 red">
                                                        {errors.label_id}
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="form-group col-md-6">
                                                <label className="form-label">Vehicle</label>
                                                <select
                                                    className={`form-control  hideArrow form-select-user dropdown-height ${errors.vehicle_id ? "error" : ""
                                                        }`}
                                                    name="vehicle_id"
                                                    value={values.vehicle_id}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        const error = handleFieldBlur(e.target.value);
                                                        errors.vehicle_id = error;
                                                    }}
                                                >
                                                    {" "}
                                                    <option value="">Select Vehicle</option>
                                                    {vehicleList &&
                                                        vehicleList.map((record, index) => (
                                                            <option value={record.id} key={index}>
                                                                {record.name} {"- " + record.license}
                                                            </option>
                                                        ))}
                                                </select>
                                                <div className="custom-arrow"></div>
                                                {errors.vehicle_id && (
                                                    <div className="ft-14 mt-1 red">
                                                        {errors.vehicle_id}
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label className="form-label">Additional Driver</label>
                                        {additionalDriverFields.map((inputValue, index) => (
                                            <div className="" key={index}>
                                                <div className="d-flex">
                                                    <input
                                                        type="email"
                                                        value={inputValue}
                                                        name="additional_drivers"
                                                        onChange={(event) => {
                                                            handleAdditionalChangeFields(index, event);
                                                            const isMatchingMainDriver =
                                                                event.target.value === mainDriver;
                                                            setAdditinalDriverErrors((prevErrors) => {
                                                                const newErrors = { ...prevErrors };
                                                                newErrors[index] = isMatchingMainDriver
                                                                    ? "Additional driver cannot be the same as the main driver"
                                                                    : undefined;
                                                                return newErrors;
                                                            });
                                                        }}
                                                        placeholder="Driver's Email"
                                                        className={`mb-3 form-control form-control-user ${errors.server_path ? "error" : ""
                                                            }`}
                                                    />
                                                    {index > 0 ? (
                                                        <div className="pl-5 pr-4 pt-2">
                                                            <i
                                                                className="fas fa-minus hover"
                                                                style={{
                                                                    fontSize: "30px",
                                                                    color: user.background_color,
                                                                    cursor: "pointer",
                                                                }}
                                                                aria-hidden="true"
                                                                onClick={() => handleRemoveAdditionalFields(index)}
                                                            ></i>
                                                        </div>
                                                    ) : (
                                                        <div className="pl-5 pr-4 pt-2">
                                                            <i
                                                                className="fas fa-plus-circle hover"
                                                                style={{
                                                                    fontSize: "30px",
                                                                    color: user.background_color,
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={handleAdditionalFields}
                                                                aria-hidden="true"
                                                            ></i>
                                                        </div>
                                                    )}
                                                </div>
                                                {errors.additional_drivers && (
                                                    <div className="ft-14 mt-1 mb-1 red">
                                                        {errors.additional_drivers}
                                                    </div>
                                                )}
                                                {additionalDriverErrors[index] && (
                                                    <div className="ft-14 mt-1 mb-1 red">
                                                        {additionalDriverErrors[index]}
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                    {user.type !== "whitelabel" && (
                                        <div className="form-group col-md-6">
                                            <label className="form-label">Vehicle</label>
                                            <select
                                                className={`form-control hideArrow form-select-user dropdown-height ${errors.vehicle_id ? "error" : ""
                                                    }`}
                                                name="vehicle_id"
                                                value={values.vehicle_id}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    const error = handleFieldBlur(e.target.value);
                                                    errors.vehicle_id = error;
                                                }}
                                            >
                                                {" "}
                                                <option value="">Select Vehicle</option>
                                                {vehicleList &&
                                                    vehicleList.map((record, index) => (
                                                        <option value={record.id} key={index}>
                                                            {`${record?.name}  /  ${record?.license}`}
                                                        </option>
                                                    ))}
                                            </select>
                                            <div
                                                className="custom-arrow"
                                                style={{ top: "60%" }}
                                            ></div>
                                            {errors.vehicle_id && (
                                                <div className="ft-14 mt-1 red">
                                                    {errors.vehicle_id}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label className="form-label">Insurance agent</label>
                                        <select
                                            name="agent"
                                            onChange={(e) => {
                                                handleAgentChange(e.target.value);
                                                handleChange(e);
                                                const error = handleFieldBlur(e.target.value);
                                                errors.agent = error;
                                            }}
                                            className={`form-control hideArrow form-select-user dropdown-height ${errors.agent ? "error" : ""
                                                }`}
                                        >
                                            {" "}
                                            <option value="">Select insurance agent</option>
                                            {agentOptions.map((agent) => (
                                                <option key={agent} value={agent}>
                                                    {agent}
                                                </option>
                                            ))}
                                        </select>
                                        <div className="custom-arrow"></div>
                                        {errors.agent && (
                                            <div className="ft-14 mt-1 red">{errors.agent}</div>
                                        )}
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label className="form-label">Excess Amount</label>
                                        <input
                                            type="text"
                                            name="excess_amount"
                                            value={`${excessAmount}`}
                                            readOnly
                                            placeholder="Excess Amount"
                                            className={`form-control form-control-user ${errors.excess_amount ? "error" : ""
                                                }`}
                                        />
                                        {errors.excess_amount && (
                                            <div className="ft-14 mt-1 red">
                                                {errors.excess_amount}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label className="form-label">
                                            {" "}
                                            Insurance day price (with VAT)
                                        </label>
                                        <div className="input-group">
                                            <input
                                                type="number"
                                                name="insurance_price"
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    const error = handleFieldBlur(e.target.value);
                                                    errors.insurance_price = error;
                                                }}
                                                onInput={(e) => {
                                                    if (e.target.value < 0) {
                                                        e.target.value = 0;
                                                    }
                                                }}
                                                placeholder="Per day price in euros"
                                                className={`form-control form-control-user ${errors.insurance_price ? "error" : ""
                                                    }`}
                                            />
                                            <div className="input-group-append">
                                                <span
                                                    className="input-group-text form-control hideArrow form-select-user pt-3 h-100"
                                                    id="basic-addon2"
                                                >
                                                    Euros (€)
                                                </span>
                                            </div>
                                        </div>
                                        {errors.insurance_price && (
                                            <div className="ft-14 mt-1 red">
                                                {errors.insurance_price}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label className="form-label">Pickup</label>
                                        {whitelabelStationList && (
                                            <select
                                                className={`form-control hideArrow form-select-user dropdown-height ${errors.pickup_station_id ? "error" : ""
                                                    }`}
                                                name="pickup_station_id"
                                                value={defaultPick}
                                                onChange={(e) => {
                                                    handleWhitelabelStationPickupChange(e);
                                                    handleChange(e);
                                                }}
                                            >
                                                <option value="">Select Pickup</option>
                                                {whitelabelStationList &&
                                                    whitelabelStationList.map((rwecord, index) => (
                                                        <option value={rwecord.id} key={index}>
                                                            {rwecord.name}
                                                        </option>
                                                    ))}
                                                <option value="other">Other</option>
                                                <option value="default">Default</option>
                                            </select>
                                        )}
                                        <div className="custom-arrow"></div>
                                        {errors.pickup_station_id && (
                                            <div className="ft-14 mt-1 red">
                                                {errors.pickup_station_id}
                                            </div>
                                        )}
                                    </div>

                                    {showOthersFields && (
                                        <div className="form-group col-md-6">
                                            <label className="form-label">Pickup location</label>
                                            <input
                                                type="text"
                                                name="pickup_station_name"
                                                value={values.pickup_station_name}
                                                onChange={handleChange}
                                                placeholder="Pickup location"
                                                className={`form-control form-control-user ${errors.pickup_station_name ? "error" : ""
                                                    }`}
                                            />
                                            {errors.pickup_station_name && (
                                                <div className="ft-14 mt-1 red">
                                                    {errors.pickup_station_name}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label className="form-label">Dropoff</label>
                                        {whitelabelStationList && (
                                            <select
                                                className={`form-control hideArrow form-select-user dropdown-height ${errors.dropoff_station_id ? "error" : ""
                                                    }`}
                                                name="dropoff_station_id"
                                                value={defaultDropoff}
                                                onChange={(e) => {
                                                    handleWhitelabelStationDropoffChange(e);
                                                    handleChange(e);
                                                }}
                                            >
                                                <option value="">Select Dropoff</option>
                                                {whitelabelStationList &&
                                                    whitelabelStationList.map((drecord, index) => (
                                                        <option value={drecord.id} key={index}>
                                                            {drecord.name}
                                                        </option>
                                                    ))}
                                                <option value="other">Other</option>
                                                <option value="default">Default</option>
                                            </select>
                                        )}
                                        <div className="custom-arrow"></div>
                                        {errors.dropoff_station_id && (
                                            <div className="ft-14 mt-1 red">
                                                {errors.dropoff_station_id}
                                            </div>
                                        )}
                                    </div>
                                    {showOthersDropFields && (
                                        <div className="form-group col-md-6">
                                            <label className="form-label">Dropoff location</label>
                                            <input
                                                type="text"
                                                name="dropoff_station_name"
                                                value={values.dropoff_station_name}
                                                onChange={handleChange}
                                                placeholder="Dropoff location"
                                                className={`form-control form-control-user ${errors.dropoff_station_name ? "error" : ""
                                                    }`}
                                            />
                                            {errors.dropoff_station_name && (
                                                <div className="ft-14 mt-1 red">
                                                    {errors.dropoff_station_name}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>

                                <div className="mb-2 row">
                                    <div className="col-6">
                                        <Upload
                                            accept=".pdf"
                                            beforeUpload={beforeUpload}
                                            onPreview={handlePreview}
                                            showUploadList={true}
                                            maxCount={1}
                                            ref={errorFieldRef}
                                            className={` ${pdfError ? "pdf-error" : ""}`}
                                        >
                                            <Button>Vehicle condition PDF</Button>
                                        </Upload>
                                        {pdfError && (
                                            <div className="ft-14 mt-1 red ">{pdfError}</div>
                                        )}
                                        {pdfFile && previewVisibleOnlyOnce <= 1 && (
                                            <Modal
                                                open={previewVisible}
                                                footer={null}
                                                onCancel={handleCancel}
                                                title={
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <span>{pdfFile.name}</span>
                                                    </div>
                                                }
                                            >
                                                <iframe
                                                    title="PDF Preview"
                                                    src={URL.createObjectURL(pdfFile)}
                                                    style={{ width: "100%", height: "500px" }}
                                                />
                                            </Modal>
                                        )}
                                    </div>
                                </div>
                                {extraDefaultFileds && <h3>Extras Management</h3>}
                                {extraDefaultFileds.map((inputValue, index) => (
                                    <div className="form-row" key={index}>
                                        <div className="form-group col-md-6">
                                            <label className="form-label">
                                                {inputValue.title}{" "}
                                                <span className="mt-2">
                                                    {inputValue.is_vat === true ? "(with VAT)" : ""}{" "}
                                                </span>{" "}
                                            </label>
                                            <div className="input-group d-flex">
                                                <input
                                                    type="text"
                                                    value={inputValue?.price}
                                                    name="price"
                                                    onChange={(event) => {
                                                        handelExtraFields(index, "price", event);
                                                    }}
                                                    placeholder="Price"
                                                    className={`form-control form-control-user ${errors.server_path ? "error" : ""
                                                        }`}
                                                />
                                                <div className="input-group-append">
                                                    <span
                                                        className="input-group-text form-control form-select-user pt-3 h-100"
                                                        id="basic-addon2"
                                                    >
                                                        EUR{inputValue.is_daily === true ? "/Day" : ""}
                                                    </span>
                                                </div>
                                                <div className="">
                                                    <div className="pl-5 pr-4 pt-2">
                                                        <i
                                                            className="fas fa-minus hover"
                                                            style={{
                                                                fontSize: "30px",
                                                                color: user.background_color,
                                                                cursor: "pointer",
                                                            }}
                                                            aria-hidden="true"
                                                            onClick={(event) => {
                                                                handleRemoveExtraFileds(index, event);
                                                            }}
                                                        ></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                {extraAddNewFileds.map((inputValue, index) => (
                                    <div className="row border border-secondary m-1" key={index}>
                                        <div className="form-group col-md-3">
                                            <div className="">
                                                <label
                                                    className="form-check-label mt-n2"
                                                    for="inlineCheckbox2"
                                                >
                                                    Extra Title
                                                </label>
                                                <input
                                                    id={`title_${index}`}
                                                    type="text"
                                                    value={inputValue?.title}
                                                    name="title"
                                                    onChange={(event) => {
                                                        handelExtraAddNewFields(index, "title", event);
                                                    }}
                                                    placeholder="Add New Extra"
                                                    className={`mb-3 form-control form-control-user ${errors.server_path ? "error" : ""
                                                        }`}
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group col-md-3">
                                            <div className="">
                                                <label
                                                    className="form-check-label mt-n2"
                                                    for="inlineCheckbox2"
                                                >
                                                    Extra Price
                                                </label>
                                                <input
                                                    id={`price_${index}`}
                                                    type="text"
                                                    value={inputValue?.price}
                                                    name="price"
                                                    onChange={(event) => {
                                                        handelExtraAddNewFields(index, "price", event);
                                                    }}
                                                    placeholder="Price"
                                                    className={`mb-3 form-control form-control-user ${errors.server_path ? "error" : ""
                                                        }`}
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group col-md-3 mt-3">
                                            <div class="form-check form-check-inline">
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name={`type_${index}`}
                                                    id={`dailyPrice_${index}`}
                                                    value="1"
                                                    checked={inputValue?.type === "1"}
                                                    onChange={(event) => {
                                                        handelExtraAddNewFields(
                                                            index,
                                                            "type",
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                                <label class="form-check-label" for="dailyPrice">
                                                    Daily price
                                                </label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name={`type_${index}`}
                                                    id={`totalPrice_${index}`}
                                                    value="2"
                                                    checked={inputValue?.type === "2"}
                                                    onChange={(event) => {
                                                        handelExtraAddNewFields(
                                                            index,
                                                            "type",
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                                <label class="form-check-label" for="totalPrice">
                                                    Total Price
                                                </label>
                                            </div>

                                            <div class="form-check form-check-inline">
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name={`item_type_${index}`}
                                                    id={`singleItem_${index}`}
                                                    checked={inputValue?.item_type === "1"}
                                                    value="1"
                                                    onChange={(event) => {
                                                        handelExtraAddNewFields(
                                                            index,
                                                            "item_type",
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                                <label class="form-check-label" for="singleItem">
                                                    Single item
                                                </label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name={`item_type_${index}`}
                                                    id={`multipeItems_${index}`}
                                                    checked={inputValue?.item_type === "2"}
                                                    value="2"
                                                    onChange={(event) => {
                                                        handelExtraAddNewFields(
                                                            index,
                                                            "item_type",
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                                <label class="form-check-label" for="multipeItems">
                                                    Multiple items
                                                </label>
                                            </div>
                                        </div>

                                        <div className="form-group col-md-2 mt-3">
                                            <div className=" form-check form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    name={`is_vat_${index}`}
                                                    type="checkbox"
                                                    id={`is_vat_${index}`}
                                                    onChange={(event) => {
                                                        handelExtraAddNewFields(index, 'is_vat', event);
                                                    }}
                                                    checked={inputValue?.is_vat ? inputValue.is_vat : false}
                                                    value={inputValue?.is_vat ? inputValue.is_vat : false}
                                                />
                                                <label className="form-check-label mt-n2" for="inlineCheckbox1">+Vat</label>
                                            </div>
                                            <div className=" form-check form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    id={`is_default_${index}`}
                                                    name={`is_default_${index}`}
                                                    type="checkbox"
                                                    onChange={(event) => {
                                                        handelExtraAddNewFields(index, 'is_default', event);
                                                    }}
                                                    checked={inputValue?.is_default ? inputValue.is_default : false}
                                                    value={inputValue?.is_default ? inputValue.is_default : false}
                                                />
                                                <label className="form-check-label mt-n2" for="inlineCheckbox2">Default</label>
                                            </div>
                                        </div>

                                        <div className="form-group col-md-1">
                                            <div className=" pt-2">
                                                <i
                                                    className="fas fa-minus hover"
                                                    style={{
                                                        fontSize: "30px",
                                                        color: "orange",
                                                        cursor: "pointer",
                                                    }}
                                                    id={`fa-minus-${index}`}

                                                    aria-hidden="true"
                                                    onClick={(event) => {
                                                        handleRemoveExtraAddNewFileds(index, event);
                                                    }}
                                                ></i>
                                            </div>
                                        </div>

                                        <div className="form-group col-md-12">
                                            Image Upload
                                            <Upload
                                                customRequest
                                                listType="picture-card"
                                                fileList={inputValue.image}
                                                onPreview={handleImgPreview}
                                                onChange={(file) => {
                                                    handelExtraAddNewFields(index, "image", file.file);
                                                }}
                                                showUploadList={{
                                                    showPreviewIcon: true,
                                                    showRemoveIcon: false,
                                                }}
                                            >
                                                {uploadButton}
                                            </Upload>
                                            <Image
                                                width={200}
                                                style={{
                                                    display: "none",
                                                    transform: `rotate(90deg)`,
                                                }}
                                                preview={{
                                                    visible: previewVisible,
                                                    onVisibleChange: (visible, prevVisible) =>
                                                        setPreviewVisible(visible),
                                                }}
                                                src={previewImage}
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="form-label">
                                                Short Description (EN)
                                            </label>
                                            <input
                                                type="text"
                                                value={inputValue?.short_description_en}
                                                name="short_description_en"
                                                id={`short_description_en_${index}`}

                                                onChange={(event) => {
                                                    handelExtraAddNewFields(
                                                        index,
                                                        "short_description_en",
                                                        event.target.value
                                                    );
                                                }}
                                                placeholder="Short Description in English"
                                                className={`mb-3 form-control form-control-user ${errors.short_description_en ? "error" : ""
                                                    }`}
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="form-label">
                                                Short Description (ET)
                                            </label>
                                            <input
                                                type="text"
                                                value={inputValue?.short_description_et}
                                                name="short_description_et"
                                                id={`short_description_et_${index}`}

                                                onChange={(event) => {
                                                    handelExtraAddNewFields(
                                                        index,
                                                        "short_description_et",
                                                        event.target.value
                                                    );
                                                }}
                                                placeholder="Short Description in Estonian"
                                                className={`mb-3 form-control form-control-user ${errors.short_description_et ? "error" : ""
                                                    }`}
                                            />
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label className="form-label" for="long_description_en">
                                                Long Description (EN)
                                            </label>
                                            <textarea
                                                id={`long_description_en_${index}`}

                                                name="long_description_en"
                                                placeholder="Enter Description in English"
                                                value={inputValue?.long_description_en}
                                                onChange={(event) => {
                                                    handelExtraAddNewFields(
                                                        index,
                                                        "long_description_en",
                                                        event.target.value
                                                    );
                                                }}
                                                className={`mb-3 form-control ${errors.long_description_en ? "error" : ""
                                                    }`}
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="form-label">
                                                Long Description (ET)
                                            </label>
                                            <textarea
                                                id={`long_description_et_${index}`}
                                                name="long_description_et"
                                                placeholder="Enter Description in Estonian"
                                                value={inputValue?.long_description_et}
                                                onChange={(event) => {
                                                    handelExtraAddNewFields(
                                                        index,
                                                        "long_description_et",
                                                        event.target.value
                                                    );
                                                }}
                                                className={`mb-3 form-control  ${errors.long_description_et ? "error" : ""
                                                    }`}
                                            />
                                        </div>
                                    </div>
                                ))}
                                <div className="d-flex form-group col-md-6">
                                    {extraFileds && (
                                        <>
                                            <select
                                                className={`form-control hideArrow form-select-user dropdown-height ${errors.dropoff_station_id ? "error" : ""
                                                    }`}
                                                name="another_extra"
                                                value={selanotherField}
                                                onChange={(e) => {
                                                    handleAssignNewExtra(e);
                                                    handleChange(e);
                                                }}
                                            >
                                                <option value="">Select Another extras</option>
                                                {extraFileds &&
                                                    extraFileds.map((drecord, index) => (
                                                        <option value={index} key={index}>
                                                            {drecord.title}
                                                        </option>
                                                    ))}
                                                <option value="other">Other</option>
                                            </select>
                                            <div
                                                className="custom-arrow"
                                                style={{ right: "135px", top: "26px" }}
                                            ></div>
                                        </>
                                    )}
                                    {errors.dropoff_station_id && (
                                        <div className="ft-14 mt-1 red">
                                            {errors.dropoff_station_id}
                                        </div>
                                    )}
                                    <div className="pl-5 pr-4 pt-2">
                                        <i
                                            className="fas fa-plus-circle hover"
                                            style={{
                                                fontSize: "30px",
                                                color: user.background_color,
                                                cursor: "pointer",
                                            }}
                                            onClick={handleAdditionalExtraFields}
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                </div>
                                {showTemplate && <h3>Contract</h3>}
                                {showTemplate && (
                                    <Collapse accordion className="mb-3">
                                        <Panel header="Placeholders" key="1">
                                            {placeholder &&
                                                placeholder.map((precord, index) => (
                                                    <strong
                                                        className="badge p-2 m-1 label-assg badge-success mt-2"
                                                        key={index}
                                                    >
                                                        {precord}
                                                    </strong>
                                                ))}
                                        </Panel>
                                    </Collapse>
                                )}

                                {showTemplate && (
                                    <EditorConvertToHTML
                                        tem={template}
                                        selTemplate={handTemplate}
                                        minheight="390px"
                                        maxheight="680px"
                                    />
                                )}
                                {showTemplate && <h3 class="mt-4">Extras</h3>}
                                {showInsuranceTemplate && (
                                    <Collapse accordion className=" mb-3">
                                        <Panel header="Extra Placeholders" key="1">
                                            {Insuranceplaceholder &&
                                                Insuranceplaceholder.map((precord, index) => (
                                                    <strong
                                                        className="badge p-2 m-1 label-assg badge-success mt-2"
                                                        key={index}
                                                    >
                                                        {precord}
                                                    </strong>
                                                ))}
                                        </Panel>
                                    </Collapse>
                                )}
                                {showInsuranceTemplate && <h3>Extra in English</h3>}
                                {showInsuranceTemplate && (
                                    <EditorConvertToHTML
                                        tem={Insurancetemplate}
                                        selTemplate={handInsuranceTemplate}
                                        minheight="100px"
                                        maxheight="590px"
                                    />
                                )}
                                {showInsuranceetTemplate && <h3>Extra in Estonian</h3>}
                                {showInsuranceetTemplate && (
                                    <EditorConvertToHTML
                                        tem={Insuranceettemplate}
                                        selTemplate={handInsuranceetTemplate}
                                        minheight="100px"
                                        maxheight="590px"
                                    />
                                )}

                                <div className="form-row mt-4">
                                    <div className="form-group d-flex justify-content-end col-md-12">
                                        <SaveButton
                                            type={"submit"}
                                            localState={LocalState}
                                            title={'Save'}
                                            size={"btn-sm"}
                                        />
                                    </div>
                                </div>
                                {Object.keys(errors).length > 0 &&
                                    document.dispatchEvent(new Event("scrollToTop"))}
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </React.Fragment>
    );
}

export default AddBooking;

import React, { useCallback, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import Header from "../../Header/Index.js";
import { Formik, Form } from "formik";
import FlashMessage from "../../FlashMessage/FlashMessage.js";
import EditorConvertToHTML from "../Editor.js";
import Button from "../../../commonComponents/Button.js";

const Helper = require("../../Helper.js");

var { apiRequest } = require("../../Api/Service.js");
var {
  UserPermissions,
  whitelabelListEndpoint,
  TermsOfUseTemplate,
  UpdateTermsOfUseTemplate,
} = require("../../Api/ApiRoutes.js");

const initialState = {
  isError: false,
  errors: [],
  isSuccess: false,
  success: "",
  disable: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_SUCCESS":
      return {
        ...state,
        isSuccess: true,
        success: action.payload,
        disable: true,
      };
    case "FETCH_ERROR":
      return {
        ...state,
        isError: true,
        errors: action.payload,
        disable: false,
      };
    case "DEFAULT":
      return {
        disable: action.payload,
      };
    default:
      return state;
  }
};

function CreateTermsOfUse() {
  let user = Helper.getStorageData("SESSION");
  const [LocalState, Localdispatch] = React.useReducer(reducer, initialState);
  const [redirectToReferrer, setredirectToReferrer] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [whitelabelList, setWhitelabelList] = useState([]);
  const [termsOfUseTemplate, setTermsOfUseTemplate] = useState([]);
  const [showTermOfUseTemplate, setShowTermOfUseTemplate] = useState(false);
  const [getTermsOfUseTemplate, setGetTermsOfUseTemplate] = useState(false);
  const [labelId, setLabelId] = useState("");
  const [lang, setLang] = useState();

  const handleWhitelabelList = useCallback(() => {
      apiRequest(
        whitelabelListEndpoint.method,
        whitelabelListEndpoint.url + `per_page=-1`
      )
        .then((response) => {
          if (response.data.code === 200) {
            setWhitelabelList(response.data.data);
          } else {
            console.log("Failure", response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
  }, []);

  useEffect(() => {
    handleWhitelabelList();
  }, [handleWhitelabelList]);

  const handleSubmit = async (values) => {
    const termOfUseFormData = new FormData();

    if (user.type === "whitelabel") {
      termOfUseFormData.append("label_id", user.id);
    } else {
      termOfUseFormData.append("label_id", values.label_id);
    }

    termOfUseFormData.append("locale", lang);
    if (getTermsOfUseTemplate !== false) {
      termOfUseFormData.append("content", getTermsOfUseTemplate);
    } else {
      termOfUseFormData.append("content", termsOfUseTemplate.data);
    }

    try {
      Localdispatch({ type: "DEFAULT", payload: true });

      const updateTermsOfUseTemplateResponse = await apiRequest(
        UpdateTermsOfUseTemplate.method,
        UpdateTermsOfUseTemplate.url,
        termOfUseFormData
      );
      if (updateTermsOfUseTemplateResponse.data.code === 200) {
        Localdispatch({
          type: "FETCH_SUCCESS",
          payload: "success",
        });
      }
      setredirectToReferrer(true);
    } catch (error) {
      Localdispatch({
        type: "FETCH_ERROR",
        payload: error.response.data.data,
      });
      window.scrollTo(500, 0);
    }
  };

  React.useEffect(() => {
    apiRequest(UserPermissions.method, UserPermissions.url)
      .then((response) => {
        if (response.data.code === 200) {
          let data = response.data.data;
          setPermissions(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [setPermissions]);

  const handleWhitelabelChange = (event) => {
    let id = event.target.value;
    setLabelId(id);
  };

  const getTemplates = async () => {
    const label_id = labelId ? `${labelId}&` : "";

    try {
      const getTermsOfUseTemplate = await apiRequest(
        TermsOfUseTemplate.method,
        TermsOfUseTemplate.url + `${label_id}locale=${lang}`
      );

      if (getTermsOfUseTemplate.status === 200) {
        setTermsOfUseTemplate(getTermsOfUseTemplate);
        setShowTermOfUseTemplate(true);
      }
    } catch (error) {
      setShowTermOfUseTemplate(false);
      console.error(error);
    }
  };

  const handleTermOfUseTemplate = (template) => {
    setGetTermsOfUseTemplate(template);
  };

  if (redirectToReferrer) {
    return (
      <Redirect
        to={{
          pathname: `/admin/terms-of-use/1`,
        }}
      />
    );
  }

  return (
    <React.Fragment>
      <div id="content">
        <Header showSearch={false} showAdvanceSearch={false} />

        <div className="p-4">
          <h4 className="mb-3">Create Terms of Use</h4>

          <Formik
            validateOnChange={false}
            onSubmit={async (values) => {
              handleSubmit(values);
            }}
            initialValues={{
              label_id: "",
              lang: "",
            }}
          >
            {({
              handleSubmit,
              handleChange,
              isSubmitting,
              setFieldValue,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <Form className="mt-2 user">
                <FlashMessage
                  success={LocalState.success}
                  isSuccess={LocalState.isSuccess}
                  isError={LocalState.isError}
                  errors={LocalState.errors}
                />

                <div className="form-row">
                    <>
                     {user.type !== "whitelabel" && (
                      <div className="form-group col-md-6">
                        <label className="form-label">WhiteLabel</label>
                        <select
                          name="label_id"
                          onChange={(e) => {
                            values.vehicle_id = "";
                            handleWhitelabelChange(e);
                            handleChange(e);
                          }}
                          className={`form-control hideArrow form-select-user dropdown-height ${
                            errors.label_id ? "error" : ""
                          }`}
                        >
                          {" "}
                          <option value="">Select WhiteLabel</option>
                          {whitelabelList &&
                            whitelabelList.map((record, index) => (
                              <option value={record.id} key={index}>
                                {record.name}
                              </option>
                            ))}
                        </select>
                        <div className="custom-arrow"></div>
                        {errors.label_id && (
                          <div className="ft-14 mt-1 red">
                            {errors.label_id}
                          </div>
                        )}
                      </div>
                      )}
                      <div className="form-group col-md-6">
                        <label className="form-label">Language</label>
                        <select
                          name="lang"
                          onChange={(e) => {
                            setLang(e.target.value);
                            handleChange(e);
                          }}
                          required
                          className={`form-control  hideArrow form-select-user dropdown-height ${
                            errors.label_id ? "error" : ""
                          }`}
                        >
                          {" "}
                          <option value="">Select Language</option>
                          <option value="en">English</option>
                          <option value="et">Estonian</option>
                        </select>
                        <div className="custom-arrow"></div>
                        {errors.lang && (
                          <div className="ft-14 mt-1 red">{errors.lang}</div>
                        )}
                      </div>
                      <div className="form-group col-md-3 d-flex align-items-center mt-4 ">
                        <div className="">
                          <button
                            type="button"
                            className="btn-submit"
                            disabled={LocalState.disable}
                            style={{ backgroundColor: user.background_color }}
                            onClick={() => getTemplates()}
                          >
                            Get Template
                            {LocalState.disable ? (
                              <span
                                className="ml-2 spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            ) : (
                              ""
                            )}
                          </button>
                        </div>
                      </div>
                    </>

                </div>
                <div>
                  {showTermOfUseTemplate && <h3>Terms of Use</h3>}
                  {showTermOfUseTemplate && (
                    <EditorConvertToHTML
                      tem={termsOfUseTemplate}
                      selTemplate={handleTermOfUseTemplate}
                    />
                  )}
                </div>
                {showTermOfUseTemplate && (
                  <div className="form-row mt-2">
                    <div className="form-group d-flex justify-content-end col-md-12">
                    <Button
                      type={"submit"}
                      localState={LocalState}
                      title={'Save'}
                      size={"btn-sm"}
                    />
                    </div>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CreateTermsOfUse;

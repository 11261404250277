import React, { useMemo } from "react";
import { Redirect } from "react-router-dom";
import Header from "../Header/Index.js";
import { Col, ColorPicker, Row, Space } from 'antd';

import { Formik, Form } from "formik";
import * as Yup from "yup";
import FlashMessage from "../FlashMessage/FlashMessage";
import Button from "../../commonComponents/Button.js";
var { apiRequest } = require("../Api/Service");
var { AddWhitelabel, UserPermissions, TimeZoneList } = require("../Api/ApiRoutes");

const initialState = {
  isError: false,
  errors: [],
  isSuccess: false,
  success: "",
  disable: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_SUCCESS":
      return {
        ...state,
        isSuccess: true,
        success: action.payload,
        disable: true,
      };
    case "FETCH_ERROR":
      return {
        ...state,
        isError: true,
        errors: action.payload,
        disable: false,
      };
    case "DEFAULT":
      return {
        disable: action.payload,
      };
    default:
      return state;
  }
};

function AddWhiteLabel() {
  const DetailSchema = Yup.object().shape({
    name: Yup.string().required("This Field is Required"),
    // webhook_url: Yup.string().required("This Field is Required"),
    email: Yup.string().email().required("This Field is Required"),
    contact_no: Yup.number().required("This Field is Required"),
    // file: Yup.mixed().required("This Field is Required"),
    main_logo: Yup.mixed().required("This Field is Required"),
    splash_logo: Yup.mixed().required("This Field is Required"),
    thumbnail: Yup.mixed().required("This Field is Required"),

    // strip_key: Yup.string().required("This Field is Required"),
    // strip_secret: Yup.string().required("This Field is Required"),
    // package_id: Yup.string().required("This Field is Required"),
    // subDomain_url: Yup.string().required("This Field is Required"),
  });

  const [LocalState, Localdispatch] = React.useReducer(reducer, initialState);
  const [redirectToReferrer, setredirectToReferrer] = React.useState(false);
  const [colorHex, setColorHex] = React.useState('#1677ff');
  const [colorHexLogo, setColorHexLogo] = React.useState('#1677ff');
  const [colorHexFont, setColorHexFont] = React.useState('#1677ff');
  const [formatHex, setFormatHex] = React.useState('hex');
  const [formatHexLogo, setFormatHexLogo] = React.useState('hex');
  const [formatHexFont, setFormatHexFont] = React.useState('hex');
  const [vatValue, setVatValue] = React.useState(0);
  const [timezoneList, settimezoneList] = React.useState([]);
  const fileMaxSize = 5;

  const checkFileSize = (value) => {
    let error;
    if (value > fileMaxSize) {
      error = 'File size exceeds the allowed limit of ' + fileMaxSize + 'MB';
    } else {
      error = undefined
    }
    return error;
  };

  const handleSubmit = (values) => {
    // const form_data = Helper.formData(values);
    const form_data = new FormData();
    form_data.append("name", values.name);
    form_data.append("email", values.email);
    form_data.append("contact_no", values.contact_no);
    form_data.append("color", colorHex);
    // form_data.append("file", values.file);
    form_data.append("strip_key", values.strip_key);
    form_data.append("strip_secret", values.strip_secret);
    form_data.append("webhook_url", values.webhook_url);
    form_data.append("package_id", values.package_id);
    form_data.append("subdomain_url", values.subdomain_url);
    form_data.append("main_logo", values.main_logo);
    form_data.append("splash_logo", values.splash_logo);
    form_data.append("vat", vatValue);
    form_data.append("vat_code", values.vat_code);
    form_data.append("thumbnail", values.thumbnail);
    form_data.append("support_email", values.support_email);
    form_data.append("mail_logo", values.mail_logo);
    form_data.append("ios_url", values.ios_url);
    form_data.append("android_url", values.android_url);
    form_data.append("logo_color_schema", colorHexLogo);
    form_data.append("font_color", colorHexFont);
    form_data.append("address", values.address);
    form_data.append("registration_no", values.registration_no);
    form_data.append("timezone", values.timezone);


    // for (let index = 0; index < values.permissions.length; index++) {
    //   form_data.append("permissions[]", values.permissions[index]);
    // }
    Localdispatch({ type: "DEFAULT", payload: true });

    apiRequest(AddWhitelabel.method, AddWhitelabel.url, form_data)
      .then((response) => {
        if (response.data.code === 200) {
          Localdispatch({
            type: "FETCH_SUCCESS",
            payload: response.data.message,
          });
          setTimeout(() => {
            setredirectToReferrer(true);
          }, 2000);
        }
      })
      .catch((error) => {
        Localdispatch({
          type: "FETCH_ERROR",
          payload: error.response.data.data,
        });
        window.scrollTo(500, 0);
      });
  };

  React.useEffect(() => {
    apiRequest(TimeZoneList.method, TimeZoneList.url)
      .then((response) => {
        if (response.data.code === 200) {
          let data = response.data.data;
          settimezoneList(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    apiRequest(UserPermissions.method, UserPermissions.url)
      .then((response) => {
        if (response.data.code === 200) {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const rgbString = useMemo(
    () => (typeof colorHex === 'string' ? colorHex : colorHex.toRgbString()),
    [colorHex],
  );
  const rgbStringLogo = useMemo(
    () => (typeof colorHexLogo === 'string' ? colorHexLogo : colorHexLogo.toRgbString()),
    [colorHexLogo],
  );
  const rgbStringFont = useMemo(
    () => (typeof colorHexFont === 'string' ? colorHexFont : colorHexFont.toRgbString()),
    [colorHexFont],
  );
  if (redirectToReferrer) {
    return <Redirect to={"/admin/list-white-label/1"} />;
  }

  const handleVatValue = event => {
    let { value, min, max } = event.target;
    value = Math.max(Number(min), Math.min(Number(max), Number(value)));

    setVatValue(value);
  };
  return (
    <React.Fragment>
      {/* <div className="wrapper d-flex align-items-stretch">
        <Sidebar /> */}
      <div id="content">
        <Header showSearch={false} showAdvanceSearch={false} />
        {/* {Helper.getPermissions(
            "admin-create",
            user_permissions && user_permissions,
            "admin"
          ) || Helper.getPermissions("", [], user.type) ? ( */}
        <div className="p-4">
          <h4 className="mb-3">Add WhiteLabel</h4>
          <Formik
            validateOnChange={false}
            validationSchema={DetailSchema}
            onSubmit={async (values) => {
              // console.log("permissions: ", values);
              handleSubmit(values);
            }}
            initialValues={{
              name: "",
              email: "",
              contact_no: "",
              file: undefined,
              permissions: [],
              subdomain_url: "",
              package_id: "",
              main_logo: undefined,
              splash_logo: undefined,
              thumbnail: undefined,
              support_email: "",
              slogan: "",
              mail_logo: undefined,
              ios_url: "",
              android_url: "",
              vat: 0,
              vat_code: "",
              address: "",
              registration_no: "",
              timezone: '',
            }}
          >
            {({
              handleSubmit,
              handleChange,
              isSubmitting,
              setFieldValue,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <Form className="mt-2 user">
                <FlashMessage
                  success={LocalState.success}
                  isSuccess={LocalState.isSuccess}
                  isError={LocalState.isError}
                  errors={LocalState.errors}
                />
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="form-label">Your Full Name</label>
                    <input
                      type="text"
                      name="name"
                      value={values.name || ""}
                      onChange={handleChange}
                      placeholder="Enter Your Name"
                      className={`form-control form-control-user ${errors.name ? "error" : ""
                        }`}
                    />
                    {errors.name && (
                      <div className="ft-14 mt-1 red">{errors.name}</div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label className="form-label">Email</label>
                    <input
                      type="text"
                      name="email"
                      value={values.email || ""}
                      onChange={handleChange}
                      placeholder="Enter Your Email"
                      className={`form-control form-control-user ${errors.email ? "error" : ""
                        }`}
                    />
                    {errors.email && (
                      <div className="ft-14 mt-1 red">{errors.email}</div>
                    )}
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="form-label">Contact</label>
                    <input
                      type="text"
                      name="contact_no"
                      value={values.contact_no || ""}
                      onChange={handleChange}
                      placeholder="Enter Your Contact"
                      className={`form-control form-control-user ${errors.contact_no ? "error" : ""
                        }`}
                    />
                    {errors.contact_no && (
                      <div className="ft-14 mt-1 red">{errors.contact_no}</div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    {/* <label className="form-label" htmlFor="avatar">
                      Avatar
                    </label>
                    <input
                      id="image"
                      type="file"
                      name="file"
                      onChange={(event) => {
                        setFieldValue("file", event.currentTarget.files[0]);
                      }}
                      className={`form-control form-select-user ${errors.file ? "error" : ""
                        }`}
                    />
                    {errors.file && (
                      <div className="ft-14 mt-1 red">{errors.file}</div>
                    )} */}
                    <label className="form-label" htmlFor="avatar">
                      Main Logo
                    </label>
                    <input
                      id="image"
                      type="file"
                      name="main_logo"
                      accept=".png, .jpg, .jpeg, .svg"
                      onChange={(event) => {
                        setFieldValue("main_logo", event.currentTarget.files[0]);
                      }}
                      className={`form-control form-select-user ${errors.main_logo ? "error" : ""
                        }`}
                    />
                    {errors.main_logo && (
                      <div className="ft-14 mt-1 red">{errors.main_logo}</div>
                    )}
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="form-label">Color</label>
                    <Row className={`form-control form-control-user`} >
                      <Space style={{ position: "absolute", top: "36px" }}>
                        <Col>
                          <ColorPicker
                            name={"color"}
                            format={formatHex}
                            value={colorHex}
                            trigger="hover"
                            onChange={(_, value) => {
                              setColorHex(value)
                              handleChange("color", value)
                            }}
                            onFormatChange={setFormatHex}

                          />
                        </Col>
                        <Col>
                          Hex: <span>{rgbString}</span>
                        </Col>
                      </Space>
                    </Row>
                    {/* {errors.color && (
                      <div className="ft-14 mt-1 red">{errors.color}</div>
                    )} */}
                  </div>

                  <div className="form-group col-md-6">
                    <label className="form-label">Stripe key</label>
                    <input
                      type="text"
                      name="strip_key"
                      value={values.strip_key || ""}
                      onChange={handleChange}
                      placeholder="Enter Key"
                      className={`form-control form-control-user ${errors.strip_key ? "error" : ""
                        }`}
                    />
                    {errors.strip_key && (
                      <div className="ft-14 mt-1 red">{errors.strip_key}</div>
                    )}
                  </div>

                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="form-label">Stripe Secret Key</label>
                    <input
                      type="text"
                      name="strip_secret"
                      value={values.strip_secret || ""}
                      onChange={handleChange}
                      placeholder="Enter Key"
                      className={`form-control form-control-user ${errors.strip_secret ? "error" : ""
                        }`}
                    />
                    {errors.strip_secret && (
                      <div className="ft-14 mt-1 red">
                        {errors.strip_secret}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label className="form-label">Webhook URL</label>
                    <input
                      type="text"
                      name="webhook_url"
                      value={values.webhook_url || ""}
                      onChange={handleChange}
                      placeholder="Enter webhook url"
                      className={`form-control form-control-user ${errors.webhook_url ? "error" : ""
                        }`}
                    />
                    {errors.webhook_url && (
                      <div className="ft-14 mt-1 red">{errors.webhook_url}</div>
                    )}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="form-label">Package Id</label>
                    <input
                      type="text"
                      name="package_id"
                      value={values.package_id || ""}
                      onChange={handleChange}
                      placeholder="Enter package id"
                      className={`form-control form-control-user  ${errors.package_id ? "error" : ""
                        }`}
                    />
                    {errors.package_id && touched.package_id ? (
                      <div className="ft-14 mt-1 red">
                        {errors.package_id}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group col-md-6">
                    <label className="form-label">Sub Domain URL</label>
                    <input
                      type="text"
                      name="subdomain_url"
                      value={values.subdomain_url || ""}
                      onChange={handleChange}
                      placeholder="Enter subDomain url"
                      className={`form-control form-control-user ${errors.subdomain_url ? "error" : ""
                        }`}
                    />
                    {errors.subdomain_url && touched.subdomain_url ? (
                      <div className="ft-14 mt-1 red">
                        {errors.subdomain_url}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="form-label" htmlFor="avatar">
                      Splash Screen
                    </label>
                    <input
                      id="image"
                      type="file"
                      name="splash_logo"
                      accept=".png, .jpg, .jpeg, .svg"
                      onChange={(event) => {
                        setFieldValue("splash_logo", event.currentTarget.files[0]);
                      }}
                      className={`form-control form-select-user ${errors.splash_logo ? "error" : ""
                        }`}
                    />
                    {errors.splash_logo && (
                      <div className="ft-14 mt-1 red">{errors.splash_logo}</div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label className="form-label" htmlFor="avatar">
                      Thumbnail
                    </label>
                    <input
                      id="image"
                      type="file"
                      accept=".png, .jpg, .jpeg, .svg"
                      name="thumbnail"
                      onChange={(event) => {
                        setFieldValue("thumbnail", event.currentTarget.files[0]);
                      }}
                      className={`form-control form-select-user ${errors.thumbnail ? "error" : ""
                        }`}
                    />
                    {errors.thumbnail && (
                      <div className="ft-14 mt-1 red">{errors.thumbnail}</div>
                    )}

                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="form-label" htmlFor="avatar">
                      Support email
                    </label>
                    <input
                      type="text"
                      name="support_email"
                      value={values.support_email || ""}
                      onChange={handleChange}
                      placeholder="Enter Your Email"
                      className={`form-control form-control-user ${errors.support_email ? "error" : ""
                        }`}
                    />
                    {errors.support_email && (
                      <div className="ft-14 mt-1 red">{errors.support_email}</div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label className="form-label" htmlFor="avatar">
                      Company Slogan
                    </label>
                    <input
                      type="text"
                      id="slogan"
                      name="slogan"
                      value={values.slogan || ""}
                      onChange={handleChange}
                      placeholder="Enter Your Solgan"
                      className={`form-control form-control-user ${errors.slogan ? "error" : ""
                        }`}
                    />
                    {errors.slogan && (
                      <div className="ft-14 mt-1 red">{errors.slogan}</div>
                    )}

                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="form-label" htmlFor="avatar">
                      Email Logo
                    </label>
                    <input
                      id="image"
                      type="file"
                      name="mail_logo"
                      accept=".png, .jpg, .jpeg, .svg"
                      onChange={(event) => {
                        setFieldValue("mail_logo", event.currentTarget.files[0]);
                        const error = checkFileSize(event.currentTarget.files[0].size / 1024 / 1024);
                        errors.mail_logo = error;
                      }}
                      className={`form-control form-select-user ${errors.mail_logo ? "error" : ""
                        }`}
                    />
                    {errors.mail_logo && (
                      <div className="ft-14 mt-1 red">{errors.mail_logo}</div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label className="form-label" htmlFor="avatar">
                      Android App URL
                    </label>
                    <input
                      type="text"
                      name="android_url"
                      value={values.android_url || ""}
                      onChange={handleChange}
                      placeholder="Enter subDomain url"
                      className={`form-control form-control-user ${errors.android_url ? "error" : ""
                        }`}
                    />
                    {errors.android_url && touched.android_url ? (
                      <div className="ft-14 mt-1 red">
                        {errors.android_url}
                      </div>
                    ) : null}

                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="form-label" htmlFor="avatar">
                      App Store URL
                    </label>
                    <input
                      type="text"
                      name="ios_url"
                      value={values.ios_url || ""}
                      onChange={handleChange}
                      placeholder="Enter subDomain url"
                      className={`form-control form-control-user ${errors.ios_url ? "error" : ""
                        }`}
                    />
                    {errors.ios_url && touched.ios_url ? (
                      <div className="ft-14 mt-1 red">
                        {errors.ios_url}
                      </div>
                    ) : null}

                  </div>
                  <div className="form-group col-md-6">
                    <label className="form-label">Logo color scheme</label>
                    <Row className={`form-control form-control-user`} >
                      <Space style={{ position: "absolute", top: "36px" }}>
                        <Col>
                          <ColorPicker
                            name={"color"}
                            format={formatHexLogo}
                            value={colorHexLogo}
                            trigger="hover"
                            onChange={(_, value) => {
                              setColorHexLogo(value)
                              handleChange("color", value)
                            }}
                            onFormatChange={setFormatHexLogo}

                          />
                        </Col>
                        <Col>
                          Hex: <span>{rgbStringLogo}</span>
                        </Col>
                      </Space>
                    </Row>
                    {/* {errors.color && (
                      <div className="ft-14 mt-1 red">{errors.color}</div>
                    )} */}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="form-label" htmlFor="avatar">
                      VAT %
                    </label>
                    <input
                      type="number"
                      step="any"
                      name="vat"
                      value={vatValue}
                      onChange={handleVatValue}

                      placeholder="Enter VAT%"
                      min="1" max="100"
                      className={`form-control form-control-user ${errors.vat ? "error" : ""
                        }`}
                    />
                    {errors.vat && touched.vat ? (
                      <div className="ft-14 mt-1 red">
                        {errors.vat}
                      </div>
                    ) : null}

                  </div>
                  <div className="form-group col-md-6">
                    <label className="form-label" htmlFor="avatar">
                      VAT Code
                    </label>
                    <input
                      type="text"
                      name="vat_code"
                      value={values.vat_code || ""}
                      onChange={handleChange}
                      placeholder="Enter VAT Code"
                      min="1" max="100"
                      className={`form-control form-control-user ${errors.vat_code ? "error" : ""
                        }`}
                    />
                    {errors.vat_code && touched.vat_code ? (
                      <div className="ft-14 mt-1 red">
                        {errors.vat_code}
                      </div>
                    ) : null}

                  </div>

                  <div className="form-group col-md-6">
                    <label className="form-label" htmlFor="avatar">
                      Registration Number
                    </label>
                    <input
                      type="text"
                      step="any"
                      name="registration_no"
                      value={values.registration_no || ""}
                      onChange={handleChange}
                      placeholder="Enter registration number"
                      className={`form-control form-control-user ${errors.registration_no ? "error" : ""
                        }`}
                    />
                    {errors.registration_no && touched.registration_no ? (
                      <div className="ft-14 mt-1 red">
                        {errors.registration_no}
                      </div>
                    ) : null}

                  </div>
                  <div className="form-group col-md-6">
                    <label className="form-label">Font color scheme</label>
                    <Row className={`form-control form-control-user`} >
                      <Space style={{ position: "absolute", top: "36px" }}>
                        <Col>
                          <ColorPicker
                            name={"color"}
                            format={formatHexFont}
                            value={colorHexFont}
                            trigger="hover"
                            onChange={(_, value) => {
                              setColorHexFont(value)
                              handleChange("color", value)
                            }}
                            onFormatChange={setFormatHexFont}

                          />
                        </Col>
                        <Col>
                          Hex: <span>{rgbStringFont}</span>
                        </Col>
                      </Space>
                    </Row>
                    {/* {errors.color && (
                      <div className="ft-14 mt-1 red">{errors.color}</div>
                    )} */}
                  </div>
                  <div className="form-group col-md-6">
                    <label className="form-label" htmlFor="avatar">
                      Address
                    </label>
                    <input
                      type="text"
                      step="any"
                      name="address"
                      value={values.address || ""}
                      onChange={handleChange}
                      placeholder="Enter address"
                      className={`form-control form-control-user ${errors.address ? "error" : ""
                        }`}
                    />
                    {errors.address && touched.address ? (
                      <div className="ft-14 mt-1 red">
                        {errors.address}
                      </div>
                    ) : null}

                  </div>
                  <div className="form-group col-md-6">
                    <label className="form-label">Timezone</label>
                    <select
                      className={`form-control form-select-user dropdown-height ${errors.timezone ? "error" : ""
                        }`}
                      name="timezone"
                      value={values.timezone}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    > <option value="">Select Timezone</option>
                      {timezoneList &&
                        timezoneList.map((record, index) => (
                          <option value={record} key={index}>
                            {record}
                          </option>
                        ))}</select>
                    {errors.timezone && (
                      <div className="ft-14 mt-1 red">{errors.timezone}</div>
                    )}
                  </div>

                </div>
                {/* <div className=" form-row">

                  <div className="form-group col-md-2">
                    <label className="form-label">Vehicle Permissions</label>
                    <FieldArray
                      name="permissions"
                      render={(arrayHelpers) => (
                        <div>
                          {userpermissions.vehicle &&
                            userpermissions.vehicle.map((permission) => (
                              <div key={permission.id}>
                                <label
                                  htmlFor={`permissions-${permission.id}`}
                                >
                                  <input
                                    name="permissions"
                                    type="checkbox"
                                    id={`permissions-${permission.id}`}
                                    value={permission.id}
                                    checked={values.permissions.includes(
                                      permission.id
                                    )}
                                    onChange={(e) => {
                                      if (e.target.checked)
                                        arrayHelpers.push(permission.id);
                                      else {
                                        const idx =
                                          values.permissions.indexOf(
                                            permission.id
                                          );
                                        arrayHelpers.remove(idx);
                                      }
                                    }}
                                  />{" "}
                                  {permission.name}
                                </label>
                              </div>
                            ))}
                        </div>
                      )}
                    />
                    {errors.permissions && (
                      <div className="ft-14 mt-1 red">
                        {errors.permissions}
                      </div>
                    )}
                  </div>

                  <div className="form-group col-md-2">
                    <label className="form-label">Usage Permissions</label>
                    <FieldArray
                      name="permissions"
                      render={(arrayHelpers) => (
                        <div>
                          {userpermissions.usage &&
                            userpermissions.usage.map((permission) => (
                              <div key={permission.id}>
                                <label
                                  htmlFor={`permissions-${permission.id}`}
                                >
                                  <input
                                    name="permissions"
                                    type="checkbox"
                                    id={`permissions-${permission.id}`}
                                    value={permission.id}
                                    checked={values.permissions.includes(
                                      permission.id
                                    )}
                                    onChange={(e) => {
                                      if (e.target.checked)
                                        arrayHelpers.push(permission.id);
                                      else {
                                        const idx =
                                          values.permissions.indexOf(
                                            permission.id
                                          );
                                        arrayHelpers.remove(idx);
                                      }
                                    }}
                                  />{" "}
                                  {permission.name}
                                </label>
                              </div>
                            ))}
                        </div>
                      )}
                    />
                    {errors.permissions && (
                      <div className="ft-14 mt-1 red">
                        {errors.permissions}
                      </div>
                    )}
                  </div>

                  <div className="form-group col-md-2">
                    <label className="form-label">Share Permissions</label>
                    <FieldArray
                      name="permissions"
                      render={(arrayHelpers) => (
                        <div>
                          {userpermissions.share &&
                            userpermissions.share.map((permission) => (
                              <div key={permission.id}>
                                <label
                                  htmlFor={`permissions-${permission.id}`}
                                >
                                  <input
                                    name="permissions"
                                    type="checkbox"
                                    id={`permissions-${permission.id}`}
                                    value={permission.id}
                                    checked={values.permissions.includes(
                                      permission.id
                                    )}
                                    onChange={(e) => {
                                      if (e.target.checked)
                                        arrayHelpers.push(permission.id);
                                      else {
                                        const idx =
                                          values.permissions.indexOf(
                                            permission.id
                                          );
                                        arrayHelpers.remove(idx);
                                      }
                                    }}
                                  />{" "}
                                  {permission.name}
                                </label>
                              </div>
                            ))}
                        </div>
                      )}
                    />
                    {errors.permissions && (
                      <div className="ft-14 mt-1 red">
                        {errors.permissions}
                      </div>
                    )}
                  </div>

                  <div className="form-group col-md-2">
                    <label className="form-label">User Permissions</label>
                    <FieldArray
                      name="permissions"
                      render={(arrayHelpers) => (
                        <div>
                          {userpermissions.user &&
                            userpermissions.user.map((permission) => (
                              <div key={permission.id}>
                                <label
                                  htmlFor={`permissions-${permission.id}`}
                                >
                                  <input
                                    name="permissions"
                                    type="checkbox"
                                    id={`permissions-${permission.id}`}
                                    value={permission.id}
                                    checked={values.permissions.includes(
                                      permission.id
                                    )}
                                    onChange={(e) => {
                                      if (e.target.checked)
                                        arrayHelpers.push(permission.id);
                                      else {
                                        const idx =
                                          values.permissions.indexOf(
                                            permission.id
                                          );
                                        arrayHelpers.remove(idx);
                                      }
                                    }}
                                  />{" "}
                                  {permission.name}
                                </label>
                              </div>
                            ))}
                        </div>
                      )}
                    />
                    {errors.permissions && (
                      <div className="ft-14 mt-1 red">
                        {errors.permissions}
                      </div>
                    )}
                  </div>

                  <div className="form-group col-md-2">
                    <label className="form-label">Profile Permissions</label>
                    <FieldArray
                      name="permissions"
                      render={(arrayHelpers) => (
                        <div>
                          {userpermissions.profile &&
                            userpermissions.profile.map((permission) => (
                              <div key={permission.id}>
                                <label
                                  htmlFor={`permissions-${permission.id}`}
                                >
                                  <input
                                    name="permissions"
                                    type="checkbox"
                                    id={`permissions-${permission.id}`}
                                    value={permission.id}
                                    checked={values.permissions.includes(
                                      permission.id
                                    )}
                                    onChange={(e) => {
                                      if (e.target.checked)
                                        arrayHelpers.push(permission.id);
                                      else {
                                        const idx =
                                          values.permissions.indexOf(
                                            permission.id
                                          );
                                        arrayHelpers.remove(idx);
                                      }
                                    }}
                                  />{" "}
                                  {permission.name}
                                </label>
                              </div>
                            ))}
                        </div>
                      )}
                    />
                    {errors.permissions && (
                      <div className="ft-14 mt-1 red">
                        {errors.permissions}
                      </div>
                    )}
                  </div>

                </div> */}

                {/* <div className="form-row">
                  <div className="form-group col-md-2">
                    <label className="form-label">Firmware Permissions</label>
                    <FieldArray
                      name="permissions"
                      render={(arrayHelpers) => (
                        <div>
                          {userpermissions.firmware &&
                            userpermissions.firmware.map((permission) => (
                              <div key={permission.id}>
                                <label
                                  htmlFor={`permissions-${permission.id}`}
                                >
                                  <input
                                    name="permissions"
                                    type="checkbox"
                                    id={`permissions-${permission.id}`}
                                    value={permission.id}
                                    checked={values.permissions.includes(
                                      permission.id
                                    )}
                                    onChange={(e) => {
                                      if (e.target.checked)
                                        arrayHelpers.push(permission.id);
                                      else {
                                        const idx =
                                          values.permissions.indexOf(
                                            permission.id
                                          );
                                        arrayHelpers.remove(idx);
                                      }
                                    }}
                                  />{" "}
                                  {permission.name}
                                </label>
                              </div>
                            ))}
                        </div>
                      )}
                    />
                    {errors.permissions && (
                      <div className="ft-14 mt-1 red">
                        {errors.permissions}
                      </div>
                    )}
                  </div>

                  <div className="form-group col-md-2">
                    <label className="form-label">Serial Permissions</label>
                    <FieldArray
                      name="permissions"
                      render={(arrayHelpers) => (
                        <div>
                          {userpermissions.serial &&
                            userpermissions.serial.map((permission) => (
                              <div key={permission.id}>
                                <label
                                  htmlFor={`permissions-${permission.id}`}
                                >
                                  <input
                                    name="permissions"
                                    type="checkbox"
                                    id={`permissions-${permission.id}`}
                                    value={permission.id}
                                    checked={values.permissions.includes(
                                      permission.id
                                    )}
                                    onChange={(e) => {
                                      if (e.target.checked)
                                        arrayHelpers.push(permission.id);
                                      else {
                                        const idx =
                                          values.permissions.indexOf(
                                            permission.id
                                          );
                                        arrayHelpers.remove(idx);
                                      }
                                    }}
                                  />{" "}
                                  {permission.name}
                                </label>
                              </div>
                            ))}
                        </div>
                      )}
                    />
                    {errors.permissions && (
                      <div className="ft-14 mt-1 red">
                        {errors.permissions}
                      </div>
                    )}
                  </div>

                  <div className="form-group col-md-2">
                    <label className="form-label">
                      Whitelabel Permissions
                    </label>
                    <FieldArray
                      name="permissions"
                      render={(arrayHelpers) => (
                        <div>
                          {userpermissions.label &&
                            userpermissions.label.map((permission) => (
                              <div key={permission.id}>
                                <label
                                  htmlFor={`permissions-${permission.id}`}
                                >
                                  <input
                                    name="permissions"
                                    type="checkbox"
                                    id={`permissions-${permission.id}`}
                                    value={permission.id}
                                    checked={values.permissions.includes(
                                      permission.id
                                    )}
                                    onChange={(e) => {
                                      if (e.target.checked)
                                        arrayHelpers.push(permission.id);
                                      else {
                                        const idx =
                                          values.permissions.indexOf(
                                            permission.id
                                          );
                                        arrayHelpers.remove(idx);
                                      }
                                    }}
                                  />{" "}
                                  {permission.name}
                                </label>
                              </div>
                            ))}
                        </div>
                      )}
                    />
                    {errors.permissions && (
                      <div className="ft-14 mt-1 red">
                        {errors.permissions}
                      </div>
                    )}
                  </div>
                </div> */}
                <div className="form-row">
                  <div className="form-group d-flex justify-content-end col-md-12">
                    <Button
                      type={"submit"}
                      localState={LocalState}
                      title={'Save'}
                      size={"btn-sm"}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          {/* <div className="row">
                <div className="col-md-12 mb-4">
                  Usages: <u>20</u>
                </div>
                <div className="col-md-12">
                  Drivers behaviour score: <u>4.5</u>
                </div>
              </div> */}
        </div>
        {/* ) : (
            <div className="p-4">
              <div className="text-center ft-14 mt-3 font-weight-bold">
                You are not allowed to visit this screen...
              </div>
            </div>
          )} */}
      </div>
      {/* </div> */}
    </React.Fragment>
  );
}

export default AddWhiteLabel;

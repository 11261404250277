import React, { Component } from 'react';
import { CKEditor, ImageInsert } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


class EditorConvertToHTML extends Component {
   constructor(props) {
        super(props);
        console.log(props.tem);
        const contentBlocks = props.tem;
        //this.state='';
        this.state = contentBlocks
        
    }
    
    render() {
        const editorContent  = this.state;
        console.log(editorContent);

        // const editorConfiguration = {
        //   plugins: [
        //     GeneralHtmlSupport
        //   ],
        //   htmlSupport: {
        //     allow: [
        //       {
        //         name: /^.*$/,
        //         styles: true,
        //         attributes: true,
        //         classes: true
        //       }
        //     ]
        //   },
        // };

        return (
            <div className="App">
                <CKEditor
                    editor={ ClassicEditor }
                    data={editorContent}
                    //config={editorConfiguration} // Pass the custom configuration here

                    
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        this.props.selTemplate(data);
                        console.log( { event, editor, data } );
                    } }
                    config={{
                    toolbar: [
                    'heading',
                    '|',
                    'bold', 'strikethrough', 'subscript', 'superscript', 'code','italic','underline',
                    '|',
                    'bulletedList','numberedList', 'todoList', 'outdent', 'indent',
                    '|',
                    '-',
                    'link','uploadImage', 'blockQuote', 'codeBlock',
                    '|',
                    'undo', 'redo',
                    '|', 'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
                    
                    ]}}
                    onBlur={ ( event, editor ) => {
                      //this.props.selTemplate(editor);
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                />
            </div>
        );
    }
}
export default EditorConvertToHTML;
import React, { useCallback } from "react";
import Header from "../Header/Index.js";

import Helper from "../Helper";
var { VehicleListAPI } = require("../Api/ApiRoutes");
var { apiRequest } = require("../Api/Service");

function MapVehicles() {
  const user = Helper.getStorageData("SESSION");
  const zoom = (user.type === "superadmin" || user.type === "admin") ? 6 : 5;

  const initMap = useCallback((filterData) => {
    let map = new window.google.maps.Map(document.getElementById("map"), {
      zoom,
      center: {
        lat: +filterData[0]?.latitude || 30.2825098,
        lng: +filterData[0]?.longitude || 64.8566929,
      },
    });
  
    let infowindow = new window.google.maps.InfoWindow();
  
    filterData &&
      filterData.map((car, index) => {
        let marker = new window.google.maps.Marker({
          position: new window.google.maps.LatLng(car?.latitude, car?.longitude),
          map: map,
          icon: `/assets/images/car.png`,
        });
  
        window.google.maps.event.addListener(
          marker,
          "click",
          (function (marker) {
            return function () {
              infowindow.setContent(`
                <div className="media" style="height:90px">
                  <img className="mr-3 rounded" src=${car?.vehicle_image} style="width:70px;height:70px;object-fit: cover;" alt="car image">
                  <div className="media-body">
                    <h6 className="font-weight-bold">${car?.vehicle_name}</h6>
                    <p className="mb-3"> ${car?.device_code}</p>
                    <a href=/admin/edit-vehicle-details/${car?.vehicle_id} className="sale text-capitalize p-2" target="_blank">View Details</a>
                  </div>
                </div>
              `);
              infowindow.open(map, marker);
            };
          })(marker, index)
        );
  
        return marker;
      });
  }, [zoom]);
  
  const getVehicleList = useCallback(() => {
    apiRequest(VehicleListAPI.method, VehicleListAPI.url +`?is_map=1`)
      .then((response) => {
        if (response.data.code === 200) {
          initMap(response.data.data);
          Helper.overlay(false);
        } else {
          // console.log("Failure", response);
          Helper.overlay(false);
        }
      })
      .catch((error) => {
        //console.log(error);
        Helper.overlay(false);
      });
  },[initMap]);

  React.useEffect(() => {
    getVehicleList();
  }, [getVehicleList]);


  return (
    <React.Fragment>
      {/* <div className="wrapper d-flex align-items-stretch">
        <Sidebar /> */}
      <div id="content">
        <Header showSearch={false} showAdvanceSearch={false}/>
        <div className="p-4">
          <div className="row">
            <div className="col-md-12">
              <div id="map" style={{ width: "100%", height: "100vh" }}></div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </React.Fragment>
  );
}

export default MapVehicles;

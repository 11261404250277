import React from "react";
import { Redirect } from "react-router-dom";
import Header from "../Header/Index.js";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import FlashMessage from "../FlashMessage/FlashMessage";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import Button from "../../commonComponents/Button.js";

var { apiRequest } = require("../Api/Service");
var { AddUser, UserPermissions,utilityCountryList } = require("../Api/ApiRoutes");
const Helper = require("../Helper");

const initialState = {
  isError: false,
  errors: [],
  isSuccess: false,
  success: "",
  disable: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_SUCCESS":
      return {
        ...state,
        isSuccess: true,
        success: action.payload,
        disable: true,
      };
    case "FETCH_ERROR":
      return {
        ...state,
        isError: true,
        errors: action.payload,
        disable: false,
      };
    case "DEFAULT":
      return {
        disable: action.payload,
      };
    default:
      return state;
  }
};

function AddUsersDetail() {
  const DetailSchema = Yup.object().shape({
    name: Yup.string().min(5, 'Too Short!').max(32, 'Too Long!').matches('[A-Z][a-z]*', 'First letter should be capital').required("This Field is Required"),
    email: Yup.string().email().required("This Field is Required"),
    contact_no: Yup.number().required("This Field is Required"),
    password: Yup.string().required("This Field is Required")
      .matches(/.{8,}/, "Minimum eight characters is Required")
      .matches(/(?=.*?[0-9])/, "at least one number is required")
      .matches(/(?=.*?[A-Z])|(?=.*?[a-z])/, "at least one letter is required"),
    password_confirmation: Yup.string()
      .required("This Field is Required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
    country: Yup.string().required("This Field is Required"),
    city: Yup.string().required("This Field is Required"),
    address: Yup.string().required("This Field is Required"),
    postal_code: Yup.string().required("This Field is Required"),
  });

  const [LocalState, Localdispatch] = React.useReducer(reducer, initialState);
  const [redirectToReferrer, setredirectToReferrer] = React.useState(false);
  const [userpermissions, setPermissions] = React.useState([]);
  const [countries, setCountries] = React.useState([]);
  var user = Helper.getStorageData("SESSION");
  var user_permissions = user.permissions;

  const handleSubmit = (values) => {
    // const form_data = Helper.formData(values);
    const form_data = new FormData();
    form_data.append("name", values.name);
    form_data.append("email", values.email);
    form_data.append("contact_no", values.contact_no);
    form_data.append("password", values.password);
    form_data.append("password_confirmation", values.password_confirmation);
    form_data.append("image", values.image);
    form_data.append("type", 3);
    form_data.append("country", values.country);
    form_data.append("city", values.city);
    form_data.append("address", values.address);
    form_data.append("postal_code", values.postal_code);

    Localdispatch({ type: "DEFAULT", payload: true });
    apiRequest(AddUser.method, AddUser.url, form_data)
      .then((response) => {
        if (response.data.code === 200) {
          Localdispatch({
            type: "FETCH_SUCCESS",
            payload: response.data.message,
          });
          setTimeout(() => {
            setredirectToReferrer(true);
          }, 2000);
        }
      })
      .catch((error) => {
        console.log(error);
        Localdispatch({
          type: "FETCH_ERROR",
          payload: error.response.data.data,
        });
        window.scrollTo(500, 0);
      });
  };

  React.useEffect(() => {
    apiRequest(UserPermissions.method, UserPermissions.url)
      .then((response) => {
        if (response.data.code === 200) {
          let data = response.data.data;
          setPermissions(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    apiRequest(utilityCountryList.method, utilityCountryList.url)
      .then((response) => {
        if (response.data.code === 200) {
          let data = response.data.data;
          setCountries(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  if (redirectToReferrer) {
    return <Redirect to={"/admin/current-user-list/1"} />;
  }

  return (
    <React.Fragment>
      {/* <div className="wrapper d-flex align-items-stretch">
        <Sidebar /> */}
      <div id="content">
        <Header showSearch={false} showAdvanceSearch={false} />
        {/* {Helper.getPermissions(
            "admin-create",
            user_permissions && user_permissions,
            "admin"
          ) || Helper.getPermissions("", [], user.type) ? ( */}
        <div className="p-4">
          <h4 className="mb-3">Add User</h4>
          <Formik
            validateOnChange={false}
            validationSchema={DetailSchema}
            onSubmit={async (values) => {
              // console.log("permissions: ", values);
              handleSubmit(values);
            }}
            initialValues={{
              name: "",
              email: "",
              contact_no: "",
              password: "",
              password_confirmation: "",
              image: undefined,
              permissions: [],
              country: "Estonia",
              city: "",
              address: "",
              postal_code: ""
            }}
          >
            {({
              handleSubmit,
              handleChange,
              isSubmitting,
              setFieldValue,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <Form className="mt-2 user">
                <FlashMessage
                  success={LocalState.success}
                  isSuccess={LocalState.isSuccess}
                  isError={LocalState.isError}
                  errors={LocalState.errors}
                />
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="form-label">Full name <span style={{ color: "red" }}>*</span> </label>
                    <input
                      type="text"
                      name="name"
                      value={values.name || ""}
                      onChange={handleChange}
                      maxLength={32}
                      placeholder="Your Name"
                      className={`form-control form-control-user ${errors.name ? "error" : ""
                        }`}
                    />
                    {errors.name && (
                      <div className="ft-14 mt-1 red">{errors.name}</div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label className="form-label">Email <span style={{ color: "red" }}>*</span></label>
                    <input
                      type="text"
                      name="email"
                      value={values.email || ""}
                      onChange={handleChange}
                      placeholder="Your Email"
                      autoComplete="new-email"
                      className={`form-control form-control-user ${errors.email ? "error" : ""
                        }`}
                    />
                    {errors.email && (
                      <div className="ft-14 mt-1 red">{errors.email}</div>
                    )}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="form-label">Password <span style={{ color: "red" }}>*</span></label>
                    <input
                      type="password"
                      name="password"
                      value={values.password || ""}
                      onChange={handleChange}
                      placeholder="**********"
                      autoComplete="new-password"
                      className={`form-control form-control-user ${errors.password ? "error" : ""
                        }`}
                    />
                    {errors.password && touched.password ? (
                      <div className="ft-14 mt-1 red">{errors.password}</div>
                    ) : null}
                  </div>
                  <div className="form-group col-md-6">
                    <label className="form-label">Confirm password <span style={{ color: "red" }}>*</span></label>
                    <input
                      type="password"
                      name="password_confirmation"
                      value={values.password_confirmation || ""}
                      onChange={handleChange}
                      placeholder="*********"
                      className={`form-control form-control-user ${errors.password_confirmation ? "error" : ""
                        }`}
                    />
                    {errors.password_confirmation && (
                      <div className="ft-14 mt-1 red">
                        {errors.password_confirmation}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="form-label">Phone number <span style={{ color: "red" }}>*</span></label>

                    <PhoneInput
                      country={"ee"}
                      // enableSearch={true}
                      name="contact_no"
                      onChange={(e, c) => {
                        values.contact_no = "+" + e
                      }}
                      value={values.contact_no || ""}
                      inputProps={{
                        className: `w-100 pl-5 rounded-left form-control form-control-user ${errors.contact_no ? 'error' : ''}`,
                      }}
                    />
                    {errors.contact_no && (
                      <div className="ft-14 mt-1 red">{errors.contact_no}</div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label className="form-label">Postal Code <span style={{ color: "red" }}>*</span></label>
                    <input
                      type="text"
                      name="postal_code"
                      value={values.postal_code || ""}
                      onChange={handleChange}
                      placeholder="Your Postal Code"
                      className={`form-control form-control-user ${errors.postal_code ? "error" : ""
                        }`}
                    />
                    {errors.postal_code && (
                      <div className="ft-14 mt-1 red">{errors.postal_code}</div>
                    )}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="form-label">Country <span style={{ color: "red" }}>*</span> </label>
                    {/* <input
                      type="text"
                      name="country"
                      value={values.country || ""}
                      onChange={handleChange}
                      maxLength={32}
                      placeholder="Your Country"
                      className={`form-control form-control-user ${errors.country ? "error" : ""
                        }`}
                    /> */}
                    <select
                        name="country"
                        value={values.country || ""}
                        onChange={(e) => {
                            values.country = "";
                            handleChange(e);
                        }}
                        className={`form-control hideArrow form-select-user dropdown-height ${errors.country ? "error" : ""
                            }`}
                    >
                        {" "}
                        {countries &&
                            countries.map((record, index) => (
                                <option value={record} key={index}>
                                    {record}
                                </option>
                            ))}
                    </select>
                    <div className="custom-arrow"></div>
                    {errors.country && (
                      <div className="ft-14 mt-1 red">{errors.country}</div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label className="form-label">City <span style={{ color: "red" }}>*</span></label>
                    <input
                      type="text"
                      name="city"
                      value={values.city || ""}
                      onChange={handleChange}
                      placeholder="Your City"
                      className={`form-control form-control-user ${errors.city ? "error" : ""
                        }`}
                    />
                    {errors.city && (
                      <div className="ft-14 mt-1 red">{errors.city}</div>
                    )}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="form-label">Address <span style={{ color: "red" }}>*</span> </label>
                    <input
                      type="text"
                      name="address"
                      value={values.address || ""}
                      onChange={handleChange}
                      maxLength={32}
                      placeholder="Your Address"
                      className={`form-control form-control-user ${errors.address ? "error" : ""
                        }`}
                    />
                    {errors.address && (
                      <div className="ft-14 mt-1 red">{errors.address}</div>
                    )}
                  </div>

                </div>
                <div className="form-row">
                  <div className="form-group d-flex justify-content-end col-md-12">
                    <Button
                      type={"submit"}
                      localState={LocalState}
                      title={'Save'}
                      size={"btn-sm"}
                    />
                  </div>
                </div>

              </Form>
            )}
          </Formik>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AddUsersDetail;

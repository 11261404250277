import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import Header from "../Header/Index.js";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FlashMessage from "../FlashMessage/FlashMessage.js";
import { Context } from "../Store/Store.js";
import { Modal, Button, Icon } from "rsuite";
import  StationMap   from './Map.js';

const Helper = require("../Helper.js");



var { apiRequest } = require("../Api/Service.js");
var {
    UserPermissions,
    whitelabelListEndpoint,
    GetStation,
    UpdateStation
} = require("../Api/ApiRoutes.js");


const initialState = {
    isError: false,
    errors: [],
    isSuccess: false,
    success: "",
    disable: false,
};

const reducer = (state, action) => {
    switch (action.type) {
        case "FETCH_SUCCESS":
            return {
                ...state,
                isSuccess: true,
                success: action.payload,
                disable: true,
            };
        case "FETCH_ERROR":
            return {
                ...state,
                isError: true,
                errors: action.payload,
                disable: false,
            };
        case "DEFAULT":
            return {
                disable: action.payload,
            };
        default:
            return state;
    }
};


function StationEdit(props) {
    let user = Helper.getStorageData("SESSION");

    const [LocalState, Localdispatch] = React.useReducer(reducer, initialState);
    const [redirectToReferrer, setredirectToReferrer] = useState(false);
    const [setPermissions] = React.useState([]);
    const [whitelabelList, setWhitelabelList] = React.useState([]);
    const [template, setTemplate] = React.useState([]);
    const [showMap, setShowMap] = React.useState(true);
    const [dispatch] = React.useContext(Context);
    const [getCords ,setCords] = React.useState([]);
    const [getLabelId, setLabelId] = React.useState(0);
    const [getSeachLocation, setSeachLocation] = useState('');
    const [records, setRecord] = useState(undefined);
    const [geofence, setGeofence] = React.useState({});
    let id = props.match.params.id;

    const StationSchema = Yup.object().shape({
        station_name: Yup.string().min(5, 'Must be exactly 50 characters')
        .max(50, 'Must be exactly 50 characters'),
        label_id: Yup.string().required("This Field is Required")
    });
    
    useEffect(() => {
        
            apiRequest(GetStation.method, GetStation.url + `${id}`)
                .then((response) => {
                    if (response.status === 200) {
                        let data = response.data.data
                        setRecord(data)
                        if(data.geofence.length > 0){
                            setGeofence(JSON.parse(data.geofence));
                        }
                        else{
                            setGeofence([]);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
    }, [id]);
    const handleWhitelabelList = (event) => {
        if (user.type !== "whitelabel") {
            apiRequest(
                whitelabelListEndpoint.method,
                whitelabelListEndpoint.url + `per_page=-1`
            )
                .then((response) => {
                    if (response.data.code === 200) {
                        setWhitelabelList(response.data.data);
                    } else {
                        console.log("Failure", response);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };
   
    const handleFieldBlur = (value) => {
        let error;
        if (!value) {
        error = `This Field is Required`;
        } else {
        error = undefined;
        }
        return error;
    };

    useEffect(() => {
        handleWhitelabelList()
    }, [])

    const handleSubmit = (values) => {

        const form_data = new FormData();
    
        if (user.type === "whitelabel") {
            //form_data.append("label_id", user.id);
         }
         else{
            form_data.append("label_id", values.label_id);
         }
        form_data.append("name", values.station_name);
        form_data.append("geofence", [JSON.stringify(getCords)]);
        form_data.append("id", id);

        Localdispatch({ type: "DEFAULT", payload: true });
        apiRequest(UpdateStation.method, UpdateStation.url+ `${id}`, form_data)
            .then((response) => {
                if (response.data.code === 200) {
                    Localdispatch({
                        type: "FETCH_SUCCESS",
                        payload: response.data.message
                    });
                    setTimeout(() => {
                         setredirectToReferrer(true);
                    }, 2000);
                }
            })
            .catch((error) => {
                console.log(error);
                Localdispatch({
                    type: "FETCH_ERROR",
                    payload: error.response.data.data,
                });
                window.scrollTo(500, 0);
            });
    };

    React.useEffect(() => {
        apiRequest(UserPermissions.method, UserPermissions.url)
            .then((response) => {
                if (response.data.code === 200) {
                    let data = response.data.data;
                    setPermissions(data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [setPermissions]);

   

    if (redirectToReferrer) {
        return <Redirect to={{
            pathname: `/admin/station-listing/1`,
        }} />;
    }

    const handleWhitelabelChange = (event) => {
        let id = event.target.value;
        setLabelId(id);
        //handleContract(id);
    }

   
    const coordinateds = (cords) => {
        setCords(cords);
        console.log(cords);
    }

    const searchLocation = (value) => {
        
        if(value !== ''){
            setShowMap(true)
            console.log();
            setSeachLocation(value);
        }
        else{
            setShowMap(true)
        }
            
    };

    return (
        <React.Fragment>
            
            <div id="content">
                <Header showSearch={false} showAdvanceSearch={false} />
              
                <div className="p-4">
                   
                    <h4 className="mb-3">Edit Station</h4>
                    {records &&
                    <Formik
                        validateOnChange={false}
                        validationSchema={StationSchema}
                        onSubmit={async (values) => {
                            handleSubmit(values);
                        }}
                        initialValues={{
                            label_id: records && records.label_id,
                            station_name: records && records.name,
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            isSubmitting,
                            setFieldValue,
                            handleBlur,
                            values,
                            touched,
                            isValid,
                            errors,
                        }) => (
                            <Form className="mt-2 user">
                                <FlashMessage
                                    success={LocalState.success}
                                    isSuccess={LocalState.isSuccess}
                                    isError={LocalState.isError}
                                    errors={LocalState.errors}
                                />
                               
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label className="form-label">Station Name</label>
                                        <input
                                            type="text"
                                            name="station_name"
                                            
                                            value={values.station_name || ""}
                                            onChange={(e) => {
                                            handleChange(e);
                                                const error = handleFieldBlur(e.target.value);
                                                errors.device_code = error;
                                            }}
                                            placeholder="Station 1"
                                            className={`form-control form-control-user ${errors.station_name ? "error" : ""
                                                }`}
                                        />
                                        <input type="hidden" className="controls"></input>
                                        {errors.station_name && (
                                            <div className="ft-14 mt-1 red">{errors.station_name}</div>
                                        )}
                                    </div>
                                    {(user.type !== "whitelabel" && 
                                    <div className="form-group col-md-6">
                                        
                                            <label className="form-label">WhiteLabel</label>
                                                <select
                                                    className={`form-control form-select-user dropdown-height ${errors.label_id ? "error" : ""
                                                        }`}
                                                    name="label_id"
                                                    value={values.label_id}
                                                    onChange={(e) => {
                                                        handleWhitelabelChange(e);
                                                        handleChange(e);

                                                    }}
                                                > <option value="">Select WhiteLabel</option>
                                                    {whitelabelList &&
                                                        whitelabelList.map((record, index) => (
                                                            <option value={record.id} key={index}>
                                                                {record.name}
                                                            </option>
                                                        ))}</select>
                                                {errors.label_id && (
                                                    <div className="ft-14 mt-1 red">{errors.label_id}</div>
                                                )}
                                       

                                    </div> )}
                                    
                                </div>
                                <div>

                               {showMap &&  <StationMap search={getSeachLocation} gcords={coordinateds} sdata={geofence} s={showMap}/>}
                                </div>
                                 {showMap && <div className="form-row mt-2">
                                    <div className="form-group d-flex justify-content-end col-md-12">
                                        <button
                                            type="submit"
                                            className="btn-submit" style={{backgroundColor: user.background_color}} 
                                            disabled={LocalState.disable}
                                        >
                                            confirm Details
                                            {LocalState.disable ? (
                                                <span
                                                    className="ml-2 spinner-border spinner-border-sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                            ) : (
                                                ""
                                            )}
                                        </button>
                                    </div>
                                    </div>
                                }

                            </Form>
                        )}
                    </Formik>
                     }
                </div>
                
            </div>
        </React.Fragment>
    );
}

export default StationEdit;

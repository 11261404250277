import React, { useEffect, useCallback } from "react";
import { NavLink, useLocation, useHistory, useParams } from "react-router-dom";
import Header from "../Header/Index.js";
import { Context } from "../Store/Store";
import Pagination from "react-js-pagination";
import Helper from "../Helper";
import { Modal, Button, Icon } from "rsuite";
import CheckBox from "../../commonComponents/CheckBox.js";
var {
  ShareListAPI,
  deleteShare,
  alldeleteShare,
  ListSearchApi,
} = require("../Api/ApiRoutes");
var { apiRequest } = require("../Api/Service");

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_SUCCESS":
      return {
        ...state,
        isSuccess: true,
        success: action.payload,
        disable: false,
      };
    case "FETCH_ERROR":
      return {
        ...state,
        isError: true,
        errors: action.payload,
        disable: false,
      };
    case "DEFAULT":
      return {
        disable: action.payload,
      };
    default:
      return state;
  }
};

const initialState = {
  isError: false,
  errors: [],
  isSuccess: false,
  success: "",
  disable: false,
};

function SharesList() {
  const [records, setrecords] = React.useState([]);
  const [result, setResult] = React.useState({});
  const [rsModal, setRsModal] = React.useState(false);
  const [state, dispatch] = React.useContext(Context);
  const [currentId, setCurrentId] = React.useState(null);
  const [locationKeys, setLocationKeys] = React.useState([]);
  const [errorMsg, setErrorMsg] = React.useState("");
  const history = useHistory();
  const [LocalState, Localdispatch] = React.useReducer(reducer, initialState);
  const [sort_by, setSortBy] = React.useState(1);
  const { id } = useParams();

  const Modalclose = () => {
    setRsModal(false);
  };
  const Modalopen = (e) => {
    setCurrentId(e.target.dataset.id);
    setRsModal(true);
  };
  var user = Helper.getStorageData("SESSION");
  var permissions = user.permissions;
  var location = useLocation();

  const getShareList = useCallback(
    (page, sort) => {
      if (
        Helper.getPermissions(
          "share-read",
          permissions && permissions,
          "admin"
        ) ||
        user.type === "superadmin" ||
        user.type === "admin" ||
        user.type === "operator"
      ) {
        Helper.overlay(true);
        apiRequest(
          ShareListAPI.method,
          ShareListAPI.url + `?page=${page}&sort_by=${sort ?? sort_by}`
        )
          .then((response) => {
            if (response.data.code === 200) {
              let data = response.data.data;
              setrecords(data);
              setResult(response.data.meta);
              dispatch({ type: "SET_SEARCH_STATE", search_list: false });
              Helper.overlay(false);
            }
          })
          .catch((error) => {
            setErrorMsg(error);
            Helper.overlay(false);
          });
      }
    },
    [dispatch, permissions, sort_by, user.type]
  );

  const getActiveSharesByShareId = useCallback(
    (id) => {
      Helper.overlay(true);
      apiRequest(ShareListAPI.method, ShareListAPI.url + `?share_id=${id}`)
        .then((response) => {
          if (response.data.code === 200) {
            let data = response.data;
            setrecords(data.data);
            setResult(data.meta);
            dispatch({ type: "SET_SEARCH_STATE", search_list: false });
            Helper.overlay(false);
          }
        })
        .catch((error) => {
          setErrorMsg(error);
          console.error("FETCH_ERROR");
          Helper.overlay(false);
        });
    },
    [dispatch]
  );

  const handleSearchPageChange = useCallback(
    (pageNumber) => {
      Helper.overlay(true);
      apiRequest(
        ListSearchApi.method,
        ListSearchApi.url +
        `shares?name=${state.searchedName}&page=${pageNumber}`
      )
        .then((response) => {
          if (response.data.code === 200) {
            let data = response.data;
            setrecords(data.data);
            setResult(data.meta);
            Helper.overlay(false);
          }
        })
        .catch((error) => {
          Helper.overlay(false);
          history.push(`${location.pathname}#error`);
        });
    },
    [history, location.pathname, state.searchedName]
  );

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }

      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          let str = location.pathname;
          if (location.hash !== "#search") {
            dispatch({ type: "SET_SEARCH_STATE", searchedName: null });
          }
          let id = str.substr(str.length - 1);
          dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: +id });
          setLocationKeys(([_, ...keys]) => keys);
        } else {
          let str = location.pathname;
          if (location.hash !== "#search") {
            dispatch({ type: "SET_SEARCH_STATE", searchedName: null });
          }
          let id = str.substr(str.length - 1);
          dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: +id });
          setLocationKeys((keys) => [location.key, ...keys]);
        }
      }
    });
  }, [dispatch, history, locationKeys, state.active_page]);

  useEffect(() => {
    if (
      location.hash !== "#search" &&
      location.hash !== "#active_share" &&
      !state.searchedName
    ) {
      dispatch({ type: "SET_STATE", response: [] });
      getShareList(state.set_active_page);
    }
  }, [dispatch, location.hash, state.searchedName, state.set_active_page]);

  useEffect(() => {
    if (
      location.pathname.includes("shares-list") &&
      location.hash === "#search" &&
      state.searchedName
    ) {
      dispatch({ type: "SET_STATE", response: [] });
      handleSearchPageChange(state.set_active_page);
    }
  }, [dispatch, location.hash, state.searchedName, state.set_active_page]);

  useEffect(() => {
    if (
      location.pathname.includes("shares-list") &&
      location.hash === "#active_share"
    ) {
      getActiveSharesByShareId(id);
    }
    if (location.hash === "#error") {
      setrecords([]);
      setResult([]);
      //setShow(true);
      setErrorMsg("No record Found");
    }
  }, [
    dispatch,
    id,
    location,
    state.data,
    state.pagination,
    state.searchedName,
  ]);

  function getcheckbox() {
    var array = [];
    var checkboxes = document.querySelectorAll(
      "input[name='subcheckbox']:checked"
    );
    for (var i = 0; i < checkboxes.length; i++) {
      array.push(checkboxes[i].value);
      checkboxes[i].checked = false;
    }
    let params = new FormData();
    params.append("ids", array);
    let delete_property = window.confirm(
      "Are you sure you want to delete these records!"
    );
    if (delete_property) {
      apiRequest(alldeleteShare.method, alldeleteShare.url, params)
        .then((response) => {
          if (response.data.code === 200) {
            let data = state.data;
            array.forEach((el) => {
              data = data.filter((d) => {
                return d.id !== el;
              });
            });
            dispatch({ type: "SET_STATE", response: data });
            setrecords(data);
            setRsModal(false);
          }
        })
        .catch((error) => {
          alert(error);
        });
    }
  }

  function handleMainCheckBox(e) {
    var value = false;

    if (e.target.checked) {
      value = true;
    }

    Array.from(document.querySelectorAll("input[name='subcheckbox']")).forEach(
      (checkbox) => {
        checkbox.checked = value;
      }
    );
  }

  const deleteRecord = (e) => {
    apiRequest(deleteShare.method, deleteShare.url + `${currentId}`)
      .then((response) => {
        //let data = response.data;
        if (response.data.code === 200) {
          let data = records.filter((d) => {
            return d.id != currentId.trim();
          });
          console.log(currentId);
          console.log(data);
          setrecords(data);
          setRsModal(false);
          //dispatch({ type: "SET_STATE", response: data});
        }
      })
      .catch((error) => {
        setRsModal(false);
        alert(error.response.data.message);
      });
  };

  const handlePageChange = (pageNumber) => {
    dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: pageNumber });
    if (
      location.pathname.includes("shares-list") &&
      location.hash === "#search"
    ) {
      history.push(`/admin/shares-list/${pageNumber}#search`);
    } else {
      history.push(`/admin/shares-list/${pageNumber}`);
    }
  };

  const setDropDown = (e) => {
    setSortBy(e.target.value);
    getShareList(state.set_active_page, e.target.value);
  };

  return (
    <React.Fragment>
      <Modal backdrop="static" show={rsModal} onHide={Modalclose} size="xs">
        <Modal.Body>
          <Icon
            icon="remind"
            style={{
              color: "#ffb300",
              fontSize: 24,
            }}
          />
          {"  "}
          Are you sure you want to delete this record?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={deleteRecord} appearance="primary">
            Ok
          </Button>
          <Button onClick={Modalclose} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <div className="wrapper d-flex align-items-stretch">
        <Sidebar /> */}
      <div id="content">
        <Header />
        {Helper.getPermissions(
          "share-read",
          permissions && permissions,
          "admin"
        ) || Helper.getPermissions("", [], user.type) ? (
          <div className="p-4">
            <div className="row pb-2 d-flex justify-content-between align-items-center border-bottom">
              <div className="col-lg-8 col-md-6">
                <div className="d-flex flex-row mb-3">
                  <div className="p-2 d-flex align-items-center">
                    {(Helper.getPermissions(
                      "share-delete",
                      permissions && permissions,
                      "admin"
                    ) ||
                      Helper.getPermissions("", [], user.type)) && (
                        <CheckBox onChange={handleMainCheckBox} />)}
                  </div>
                  <div className="p-2">
                    {(Helper.getPermissions(
                      "share-delete",
                      permissions && permissions,
                      "admin"
                    ) ||
                      Helper.getPermissions("", [], user.type)) && (
                        <button
                          className="btn btn-primary btnn-user"
                          onClick={getcheckbox}
                        >
                          Bulk Delete
                        </button>
                      )}
                  </div>
                  <div className="p-2">
                    {(Helper.getPermissions(
                      "share-create",
                      permissions && permissions,
                      "admin"
                    ) ||
                      Helper.getPermissions("", [], user.type)) && (
                        <NavLink
                          exact
                          to={`/admin/verify-user`}
                          className="btn btnn-user white bg-orange"
                          style={{ backgroundColor: user.background_color }}
                        >
                          Add Share
                        </NavLink>
                      )}
                  </div>
                </div>
              </div>
              {/* <div className="col-md-1 p-2 text-center font-weight-bold"> */}
              {/* <NavLink className="black" exact to={`/admin/shares-my-list`}>
                  Show My List
                </NavLink> */}
              {/* {(user.type === "superadmin" ||user.type === "operator" || user.type === "admin" && Helper.getPermissions(
                  "share-read",
                  permissions && permissions,
                  "admin"
                )) && (
                  Helper.getPermissions("", [], user.type)) && (
                  <React.Fragment>
                   
                    <NavLink
                      className="black"
                      exact
                      to={`/admin/shares-list`}
                    >
                      Show All List
                    </NavLink>
                  </React.Fragment>
                )}
                {` | `}
                <NavLink className="black" exact to={`/admin/shares-with-me-list`}>
                  Shared With Me
                </NavLink>  */}
              {/* </div> */}
              <div className="col-lg-4 col-md-6">
                <div className="row d-flex align-items-center ">
                  <div className="col-md-12 col-lg-3 pl-lg-0 pr-lg-0">
                    Results: {result.total ? result.total : "1"}
                  </div>
                  <div className="col-md-12 col-lg-9 pl-lg-0 pr-lg-0 row">
                    <label class="col-3 mt-auto">Sort By:</label>
                    <select
                      className="form-select form-control col-8"
                      aria-label="Default select example"
                      name="sort_by"
                      onChange={setDropDown}
                    >
                      <option selected={sort_by === 1 ? true : false} value={1}>
                        Last created on top
                      </option>
                      <option selected={sort_by === 2 ? true : false} value={2}>
                        First created on top
                      </option>
                      <option selected={sort_by === 3 ? true : false} value={3}>
                        Alphabetically A-Z
                      </option>
                      <option selected={sort_by === 4 ? true : false} value={4}>
                        Alphabetically Z-A
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              {records.length ? (
                records.map((record, index) => (
                  <div
                    className="card col-12 mt-3 shadow bg-white rounded card-shadow "
                    key={index}
                  >
                    <div className="row">
                      <div className="col-md-12 col-lg-2  col-sm-12 pb-3 pt-2 my-auto text-lg-center">
                        <img
                          src={record.vehicle.file}
                          className="card-img-top"
                          alt="..."
                          width="100"
                          height="100"
                          style={{ borderRadius: "50% !important" }}
                        />
                      </div>
                      <div className="col-md-12 col-lg-8  col-sm-12 pb-3 pt-2">
                        <div className="row pt-2">
                          <div className="col-11">
                            <input
                              type="checkbox"
                              className=""
                              name="subcheckbox"
                              defaultValue={record.id}
                              id="exampleCheck1"
                            />
                            <NavLink
                              className=" ml-3 font-weight-bold ml-3"
                              exact
                              to={`/admin/share-edit/${record.id}`}
                            >
                              {record.user.id}
                            </NavLink>
                            {record.is_booking === false ? (
                              <strong className="btn-primary badge badge-primary p-2 ml-2">
                                Shared
                              </strong>
                            ) : (
                              <strong className="btn-success badge badge-sucess p-2 ml-2">
                                Booking
                              </strong>
                            )}
                          </div>
                        </div>
                        <div className="row pt-2">
                          <div className="col-md-4 col-12">
                            Name:
                            {record.user.name ? (
                              <NavLink
                                exact
                                to={`/admin/user-list/${record.user.id}/#userId`}
                              >
                                {" "}
                                <strong>
                                  {" "}
                                  <u> {record.user.name} </u>{" "}
                                </strong>
                              </NavLink>
                            ) : (
                              "-"
                            )}
                          </div>
                          <div className="col-md-4 col-12">
                            Vehicle:{" "}
                            {record.vehicle.name ? (
                              <NavLink
                                exact
                                to={`/admin/all-vehicle-list/${record.vehicle.id}/#vehicleId`}
                              >
                                {" "}
                                <strong>
                                  {" "}
                                  <u> {record.vehicle.name} </u>{" "}
                                </strong>
                              </NavLink>
                            ) : (
                              "-"
                            )}
                          </div>
                          <div className="col-md-4 col-12">
                            <strong className="btn-success badge badge-sucess">
                              Start:
                            </strong>{" "}
                            {Helper.getDateByTZ(record.start_date)} (
                            {user?.timezone
                              ? user.timezone
                              : Helper.localTimezone()}
                            )
                          </div>

                          {/*
.... */}


                          {/* <div className="col-md-4 col-12">
                            Drivers Behavior Score:
                            <strong>
                              {" "}
                              <span className="text-success">
                                {record.score ? record.score : "0"}
                              </span>
                            </strong>
                          </div> */}
                        </div>
                        <div className="row pt-2">
                          <div className="col-md-4 col-12 pr-0">
                            Email: <strong>{record.user.email}</strong>
                          </div>
                          <div className="col-md-4 col-12">
                            Registration plate:{" "}
                            {record.vehicle.license
                              ? record.vehicle.license
                              : "-"}
                          </div>
                          <div className="col-md-4 col-12">
                            <strong className="btn-danger badge badge-danger">
                              Stop:
                            </strong>{" "}
                            {Helper.getDateByTZ(record.end_date)} (
                            {user?.timezone
                              ? user.timezone
                              : Helper.localTimezone()}
                            )
                          </div>
                        </div>
                        <div className="row pt-2">
                          <div className="col-md-4 col-12">
                            Phone:{" "}
                            <strong>
                              <span>
                                {" "}
                                {record.user.country_code
                                  ? record.user.country_code
                                  : "-"}{" "}
                              </span>
                              <span>
                                {" "}
                                {record.user.contact
                                  ? record.user.contact
                                  : "-"}{" "}
                              </span>
                            </strong>
                          </div>
                          <div className="col-md-4 col-12">
                            Last key sync:{" "}
                            <strong>
                              {record.bt_exchange_date
                                ? Helper.getDateByTZ(record.bt_exchange_date)
                                : "-"}
                            </strong>
                          </div>
                        </div>
                        <div className="row pt-2">
                          <div className="col-md-4 col-12">
                            Access Type:{" "}
                            <strong>
                              <span>
                                {record.access_type === "1"
                                  ? " Full Access "
                                  : record.access_type === "2"
                                    ? " Driver Access "
                                    : record.access_type === "3"
                                      ? " Door & Trunk Access "
                                      : "-"}
                              </span>
                            </strong>
                          </div>
                          <div className="col-md-4 col-12">
                            Expired:{" "}
                            <strong>
                              {record.is_expire === true ? "Yes" : "No"}
                            </strong>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 col-sm-12  col-lg-2 my-auto">
                        <div className="row pt-3 pb-3">
                          <div className="col-md-6 col-sm-6 col-lg-12 pt-lg-3 pt-md-0 pt-2">
                            {(Helper.getPermissions(
                              "share-update",
                              permissions && permissions,
                              "admin"
                            ) ||
                              Helper.getPermissions("", [], user.type)) && (
                                <NavLink
                                  className=""
                                  exact
                                  to={`/admin/share-edit/${record.id}`}
                                >
                                  <button className="w-100 btn-success">
                                    Edit
                                  </button>
                                </NavLink>
                              )}
                          </div>
                          <div className="col-md-6  col-sm-6 col-lg-12 pt-lg-3 pt-md-0 pt-2">
                            {(Helper.getPermissions(
                              "share-delete",
                              permissions && permissions,
                              "admin"
                            ) ||
                              Helper.getPermissions("", [], user.type)) && (
                                <button
                                  data-id={record.id}
                                  onClick={Modalopen}
                                  className="btn-danger w-100"
                                >
                                  Delete
                                </button>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center ft-14 mt-3 font-weight-bold">
                  No Data Found...
                </div>
              )}
            </div>
            <div className="row mt-3 ft-14">
              <div className="col-md-12">
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-end">
                    <Pagination
                      activePage={state.set_active_page}
                      totalItemsCount={result.total ? result.total : 1}
                      pageRangeDisplayed={5}
                      itemClass="page-item"
                      linkClass="page-link"
                      onChange={handlePageChange}
                      activeClass={user.type === "whitelabel" ? "active-whitelabel-pagination" : "active-default-pagination "}
                    />
                    <li className="pl-3">
                      {/* <form className="form-inline">
                        <label className="my-1 mr-2">Results on page:</label>
                        <select
                          className="form-control p-0"
                          style={{ height: "30px" }}
                          defaultValue={"10"}
                        >
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </form> */}
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        ) : (
          <div className="p-4">
            <div className="text-center ft-14 mt-3 font-weight-bold">
              You are not allowed to visit this screen...
            </div>
          </div>
        )}
      </div>
      {/* </div> */}
    </React.Fragment>
  );
}
export default SharesList;

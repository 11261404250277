import React from "react";
import Helper from "../Helper";
var { DownLoadContract, SendContractviaEmail } = require("../Api/ApiRoutes");


var { apiRequest } = require("../Api/Service");

// const initialState = {
//   isError: false,
//   errors: [],
//   isSuccess: false,
//   success: "",
//   disable: false,
// };
// const reducer = (state, action) => {
//   switch (action.type) {
//     case "FETCH_SUCCESS":
//       return {
//         ...state,
//         isSuccess: true,
//         success: action.payload,
//         disable: false,
//       };
//     case "FETCH_ERROR":
//       return {
//         ...state,
//         isError: true,
//         errors: action.payload,
//         disable: false,
//       };
//     case "DEFAULT":
//       return {
//         disable: action.payload,
//       };
//     default:
//       return state;
//   }
// };
function DownloadBookingPDF(props) {
  const {
    downloadBUttonShow,
    bid,
    pdf,
    messages
  } = props
  var user = Helper.getStorageData("SESSION");
  const [errorMsg, setErrorMsg] = React.useState("");

  const downloadContract = (event) => {
    event.preventDefault();

    Helper.overlay(true);

    apiRequest(DownLoadContract.method, DownLoadContract.url + `${bid}`)
      .then((response) => {
        if (response.data.code === 200) {
          window.open(response.data.data, '_blank', 'noreferrer')
          Helper.overlay(false);

          window.scrollTo(500, 0);
        }
        else {
          Helper.overlay(false);
          window.scrollTo(500, 0);
        }
      })
      .catch((error) => {
        Helper.overlay(false);
        setErrorMsg(error.response.data.message);
        window.scrollTo(500, 0);
      });
  };

  const SendContract = (event) => {
    event.preventDefault();

    Helper.overlay(true);

    let bookingId = bid;


    apiRequest(SendContractviaEmail.method, SendContractviaEmail.url + `${bookingId}`)
      .then((response) => {
        if (response.data.code === 200) {
          Helper.overlay(false);
          window.scrollTo(500, 0);
          messages();
        }
        else {
          Helper.overlay(false);
          window.scrollTo(500, 0);
          setErrorMsg('Something went wrong');
        }
      })
      .catch((error) => {
        Helper.overlay(false);
        setErrorMsg('Something went wrong');
        window.scrollTo(500, 0);
      });
  };

  const downloadVehicleConditionPdf = () => {
    const link = document.createElement('a');
    link.href = pdf;
    link.target = '_blank';
    link.download = {pdf};

    link.click();
  };
  return (
    <React.Fragment>

      {downloadBUttonShow &&
        <button onClick={downloadContract} appearance="subtle" className="btn-submit" style={{ backgroundColor: user.background_color }}>
          <b className="">Download Contract</b>
        </button>
      }
      {(pdf !== "null" && pdf !== undefined) &&
        <button onClick={downloadVehicleConditionPdf} appearance="subtle" className="btn-submit ml-2" style={{ backgroundColor: user.background_color }}>
          <b className="">Vehicle condition pdf</b>
        </button>
      }
      {downloadBUttonShow &&
        <button onClick={SendContract} appearance="subtle" className="btn-submit ml-2" style={{ backgroundColor: user.background_color }}>
          <b className="">Send Contract</b>
        </button>
      }
      {errorMsg && (" ")}
    </React.Fragment>
  );
}
export default DownloadBookingPDF;

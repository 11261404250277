import React from "react";
import { NavLink } from "react-router-dom";
import Header from "../Header/Index.js";
import { Context } from "../Store/Store";

import Echo from "laravel-echo";
import Pagination from "react-js-pagination";
import Helper from "../Helper";
var { UsageDetailAPI } = require("../Api/ApiRoutes");
var { apiRequest } = require("../Api/Service");

function DetailsUsagePage(props) {
  // const DetailSchema = Yup.object().shape({
  //   // fullname: Yup.string().required("This Field is Required"),
  //   // email: Yup.string().required("This Field is Required"),
  //   // telephone: Yup.number().required("This Field is Required"),
  //   // password: Yup.string().required("This Field is Required"),
  //   // passwordrepeat: Yup.string().required("This Field is Required"),
  // });

  const [records, setrecords] = React.useState([]);
  const [activePage, setactivePage] = React.useState(1);
  const [result, setResult] = React.useState({});
  const [hidePagination, sethidePagination] = React.useState(false);
  var user = Helper.getStorageData("SESSION");
  var permissions = user.permissions;
  let usage_id = props.match.params.id;

  React.useEffect(() => {
    //if(Helper.getPermissions('user-read',permissions)){
    Helper.overlay(true);
    apiRequest(UsageDetailAPI.method, UsageDetailAPI.url + `/${usage_id}`)
      .then((response) => {
        setrecords(response.data.data);
        Helper.overlay(false);
        if (response.data.code == 200) {
          //setrecords(response.data.data);
          setResult(response.data.meta);
          Helper.overlay(false);
        }
      })
      .catch((error) => {
        Helper.overlay(false);
      });
    //}
  }, []);

  return (
    <React.Fragment>
      {/* <div className="wrapper d-flex align-items-stretch">
        <Sidebar /> */}
      <div id="content">
        <Header showSearch={false} showAdvanceSearch={false} />

        <div className="p-4">
          <h4 className="mb-3">
            Usage no: <u>{records && records.id}</u>
          </h4>

          <div className="row mb-4">
            <div className="col-md-12 ">
              <p className="mb-0">
                Name: {" "}
                {records.vehicle &&
                  records.vehicle.user &&
                  records.vehicle.user.name}
              </p>
            </div>
            <div className="col-md-12 ">
              <p className="mb-0">
                Email: {" "}
                {records.vehicle &&
                  records.vehicle.user &&
                  records.vehicle.user.email}
              </p>
            </div>

            <div className="col-md-12 ">
              <p className="mb-0">
                Telephone: {" "}
                {records.vehicle &&
                  records.vehicle.user &&
                  records.vehicle.user.contact}
              </p>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-12 ">
              <p className="mb-0">Via: {records.via ? records.via : "-"} </p>
            </div>
            <div className="col-md-12 ">
              <p className="mb-0">Type: {records.type ? records.type : "-"} </p>
              <p className="mb-0">
                Tags: {records.vehicle && records.vehicle.tags}{" "}
              </p>
              <p className="mb-0">
                Color: {records.vehicle && records.vehicle.colour}{" "}
              </p>
              <p className="mb-0">
                Vehicle Name:{" "}
                {records.vehicle && records.vehicle.name}{" "}
              </p>
              <p className="mb-0">
                Vehicle Year: {records.vehicle && records.vehicle.year}{" "}
              </p>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-12 ">
              <p className="mb-0">BT Key Exchange ID: {records.bt_exchange_id ? records.bt_exchange_id : "-"} </p>
            </div>
            <div className="col-md-12 ">
              <p className="mb-0">BT Key Exchange Date: {records.bt_exchange_date ? records.bt_exchange_date : "-"} </p>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-12 ">
              <p className="mb-0">Start: {records.from}</p>
            </div>
            <div className="col-md-12 ">
              <p className="mb-0">
                Pick-up from Latitude: {records && records.from_latitude}{" "}
                <i
                  className="fa fa-map-marker-alt pl-1"
                  style={{ color: "green" }}
                ></i>
              </p>
              <p className="mb-0">
                Pick-up from Longitude: {records && records.from_longitude}{" "}
                <i
                  className="fa fa-map-marker-alt pl-1"
                  style={{ color: "green" }}
                ></i>
              </p>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-12 ">
              <p className="mb-0">Stop: {records.to}</p>
            </div>
            <div className="col-md-12 ">
              <p className="mb-0">
                Drop-off to Latitude:{records && records.to_latitude}{" "}
                <i
                  className="fa fa-map-marker-alt pl-1"
                  style={{ color: "green" }}
                ></i>
              </p>
              <p className="mb-0">
                Drop-off to Longitude:{records && records.to_longitude}{" "}
                <i
                  className="fa fa-map-marker-alt pl-1"
                  style={{ color: "green" }}
                ></i>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 ">
              <p className="mb-0">Usage Details</p>
            </div>
            <div className="col-md-12 ">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                <tr>
                    <th>Fuel</th>
                    <th className="bg-success text-white">
                      {records &&
                        records.vehicle &&
                        records.vehicle.detail &&
                        records.vehicle.detail.fuel}
                    </th>
                  </tr>
                  {records &&
                    records.vehicle &&
                    records.vehicle.detail &&
                    records.details.map((detail, index) => (
                        <tr>
                          <th className="text-capitalize">{detail.type}</th>
                          <th className="text-capitalize">{detail.data ? (detail.data.status):"-"}</th>
                        </tr>
                      ))
                    }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </React.Fragment>
  );
}

export default DetailsUsagePage;

/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useCallback, useContext, useEffect } from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { Redirect } from "react-router-dom";
import Header from "../Header/Index.js";
import { Context } from "../Store/Store";
import { Formik } from "formik";
import * as Yup from "yup";
import Helper from "../Helper";
import FlashMessage from "../FlashMessage/FlashMessage";
import { Modal, Button } from "rsuite";
import {
  EventDoor,
  broadcastAuth,
  EventTrunk,
  VehicleUsageAPI,
  ActiveShares,
  CurrentUserAPI,
} from "../Api/ApiRoutes";
import HeartBeat from "../HeartBeat/HeartBeat";
import VehicleAlarm from "../Alaram/VehicleAlarm";
import Echo from "laravel-echo";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useHistory } from "react-router-dom";
import VehocleTrip from "../HeartBeat/VehicleTrip.js";
import StationMap from "../Stations/Map.js";
import Pagination from "react-js-pagination";

window.Pusher = require("pusher-js");

var {
  EditVehicle,
  VehicleMakeList,
  VehicleModelList,
  KeyModelList,
  UpdateVehicleApI,
} = require("../Api/ApiRoutes");
var { apiRequest } = require("../Api/Service");

const initialState = {
  isError: false,
  errors: [],
  isSuccess: false,
  success: "",
  disable: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_SUCCESS":
      return {
        ...state,
        isSuccess: true,
        success: action.payload,
        disable: false,
      };
    case "FETCH_ERROR":
      return {
        ...state,
        isError: true,
        errors: action.payload,
        disable: false,
      };
    case "DEFAULT":
      return {
        disable: action.payload,
      };
    default:
      return state;
  }
};

function VehicleDetials(props) {
  const [doorLoader, setDoorLoader] = React.useState(null);
  const [trunkLoader, setTrunkLoader] = React.useState(null);
  const [show, setShow] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [state, dispatch] = useContext(Context);
  const [records, setrecords] = React.useState(undefined);
  const [makeList, setmakeList] = React.useState([]);
  const [modelList, setmodelList] = React.useState([]);
  const [firmwareResponse, setFirmwareResponse] = React.useState(null);
  const [firmwareStatus, setFirmwareStatus] = React.useState(null);
  const [keyList, setkeyList] = React.useState([]);
  const [LocalState, Localdispatch] = React.useReducer(reducer, initialState);
  const [serial, setSerial] = React.useState("");
  const [locationKeys, setLocationKeys] = React.useState([]);
  const history = useHistory();
  const [toggleState, setToggleState] = useState(1);

  const [useageApiCall, setuseageApiCall] = React.useState(true);
  const [heartBeatApiCall, setheartBeatApiCall] = React.useState(false);
  const [userList, setUserList] = React.useState([]);

  const [geofence, setGeofence] = React.useState({});
  const [getCords, setCords] = React.useState([]);
  const [getSeachLocation, setSeachLocation] = useState("");
  const [showMap, setShowMap] = React.useState(false);
  const [usageList, setusageList] = React.useState([]);
  const [usagesResult, setUsagesResult] = React.useState({});
  const [activeSharesList, setActiveShareList] = React.useState([]);
  const [activeShareResult, setActiveShareResult] = React.useState({});
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  let id = props.match.params.id;
  var user = Helper.getStorageData("SESSION");
  var permissions = user.permissions;

  const VehicleSchema = Yup.object().shape({
    device_code: Yup.string().required("This Field is Required"),
    year: Yup.string().required("This Field is Required"),
  });

  const renderType = (param) => {
    switch (param) {
      case "1":
        return "Full Access";
      case "2":
        return "Driver Access";
      case "3":
        return "Door & Trunk Access";
      default:
        return "-";
    }
  };
  useEffect(() => {
    apiRequest(CurrentUserAPI.method, CurrentUserAPI.url + `?show_all:1`)
      .then((response) => {
        if (response.data.code === 200) {
          setUserList(response.data.data);
        } else {
          //console.log("Failure", response);
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  }, []);

  React.useEffect(() => {
    setVechilceData();
    //getVehicleUsages(id);
    function setVechilceData() {
      Promise.all([getVehicle(id), getVehicleMakeList()]).then(
        ([getVehicle, getVehicleMakeList]) => {
          if (getVehicle.data.code === 200) {
            let data = getVehicle.data.data;
            setrecords(data);
            setSerial(data?.detail?.device_code);
            getVehicleModelList(data.vehicle_company_id);
            getKeyModelList(data.vehicle_model_id);
             getVehicleActiveShares(id);
            //setShowMap(true);
            if (data.geofence.length > 0) {
              setGeofence(JSON.parse(data.geofence));
              //setCords(JSON.parse(data.geofence));
            } else {
              setGeofence([]);
            }
          }
          if (getVehicleMakeList.data.code === 200) {
            setmakeList(getVehicleMakeList.data.data);
          }
        }
      );
    }
  }, [id]);

  React.useEffect(() => {
    window.Echo = new Echo({
      broadcaster: "pusher",
      key: "app",
      wsHost: "dev.movitronic.com",
      wsPort: 6001,
      wssPort: 6001,
      forceTLS: true,
      useTLS: true,
      disableStats: true,
      encrypted: true,
      transports: ["websocket", "polling", "flashsocket"],
      authEndpoint: broadcastAuth.url,
      auth: { headers: { Authorization: "Bearer " + user.access_token } },
    });

    window.Echo.private(`movitronic`).listen(".usage", (e) => {
      Helper.overlay(false);

      if (e.type === "trunk") {
        setTrunkLoader(null);
        Localdispatch({
          type: "FETCH_SUCCESS",
          payload: "Trunk opened",
        });
      } else {
        setDoorLoader(null);
        Localdispatch({
          type: "FETCH_SUCCESS",
          payload: "Vehicle status updated",
        });
      }
    });
    window.Echo.private(`movitronic`).listen(".heartbeat", (e) => {
      // setVechilceData();
    });
    window.Echo.private("movitronic").listen(
      ".firmware-progress-" + serial,
      (e) => {
        if (e.status == 3) {
          setFirmwareResponse(e.progress);
          setFirmwareStatus(e.status);
        } else if (e.status == 2) {
          setFirmwareStatus(e.status);
          setErrorMsg(
            "Unable to complete firmware update at the moment. Please try again"
          );
        } else if (e.status == 1) {
          setFirmwareStatus(e.status);
          Localdispatch({
            type: "FETCH_SUCCESS",
            payload: "Firmware updated",
          });
        }
      }
    );
  }, [serial, records, user.access_token]);

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }

      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          dispatch({ type: "SET_SEARCH_STATE", pagination: true });
        } else {
          dispatch({ type: "SET_SEARCH_STATE", pagination: true });
        }
      }
    });
  }, [dispatch, history, locationKeys]);

  const isoToDate = (isoStr) => {
    if (isoStr === "") {
      return "--";
    }
    const date = new Date(isoStr);

    var day = date.getDate();
    var month = date.getMonth() + 1; //months are zero based
    var year = date.getFullYear();

    var time = date.toLocaleTimeString().replace(/(.*)\D\d+/, "$1");

    let newTimeFormat = time + " " + day + "-" + month + "-" + year;
    return newTimeFormat;
  };

  const doorStatus = (e, carid, cmd) => {
    e.preventDefault();
    setDoorLoader({ id: carid, type: cmd });

    document.getElementById(e.target.id).disabled = true;
    var formData = new FormData();
    let command = cmd ? false : true;
    formData.append("cmd", command);
    formData.append("car_id", carid);
    // Localdispatch({ type: "DEFAULT", payload: true });
    Helper.overlay(true);
    apiRequest(EventDoor.method, EventDoor.url, formData)
      .then((response) => {
        if (response.data.code === 200) {
          // let data = response.data.data;
          document.getElementById(e.target.id).disabled = false;
        } else if (response.data.code === 401) {
          document.getElementById(e.target.id).disabled = false;
        } else {
          document.getElementById(e.target.id).disabled = false;
        }
      })
      .catch((error) => {
        Helper.overlay(false);
        setShow(true);
        setErrorMsg(error.response.data.message);
      });
  };

  const trunkStatus = (e, carid, cmd) => {
    e.preventDefault();
    setTrunkLoader(carid);
    document.getElementById(e.target.id).disabled = true;
    var formData = new FormData();
    let command = true;
    formData.append("cmd", command);
    formData.append("car_id", carid);
    Localdispatch({ type: "DEFAULT", payload: true });
    Helper.overlay(true);
    apiRequest(EventTrunk.method, EventTrunk.url, formData)
      .then((response) => {
        if (response.data.code === 200) {
          document.getElementById(e.target.id).disabled = false;
        } else if (response.data.code === 401) {
          document.getElementById(e.target.id).disabled = false;
        } else {
          document.getElementById(e.target.id).disabled = false;
        }
      })
      .catch((error) => {
        Helper.overlay(false);
        setShow(true);
        setErrorMsg(error.response.data.message);
      });
  };

  const getVehicle = (id) => {
    return apiRequest(EditVehicle.method, EditVehicle.url + id);
  };

  const getVehicleMakeList = () => {
    return apiRequest(VehicleMakeList.method, VehicleMakeList.url);
  };

  const handleClose = () => {
    setShow(false);
    setDoorLoader(null);
    setTrunkLoader(null);
  };

  const getVehicleModelList = (vehicle_company_id) => {
    apiRequest(
      VehicleModelList.method,
      VehicleModelList.url + `vehicle_company_id=${vehicle_company_id}`
    )
      .then((response) => {
        if (response.data.code === 200) {
          setmodelList(response.data.data);
        } else {
        }
      })
      .catch((error) => { });
  };
  const getVehicleActiveShares = (vehicle_id) => {
    Helper.overlay(true);
    apiRequest(
      ActiveShares.method,
      ActiveShares.url + `${vehicle_id}?per_page=50`
    )
      .then((response) => {
        if (response.data.code === 200) {
          //console.log(response.data);
          setActiveShareList(response.data.data);
          setActiveShareResult(response.data.meta);
          Helper.overlay(false);
        } else {
          Helper.overlay(false);
        }
      })
      .catch((error) => {
        Helper.overlay(false);
      });
  };

  const getVehicleUsages = useCallback((vehicle_id) => {
    Helper.overlay(true);
    apiRequest(
      VehicleUsageAPI.method,
      VehicleUsageAPI.url + `${vehicle_id}?per_page=50`
    )
      .then((response) => {
        if (response.data.code === 200) {
          //console.log(response.data);
          setusageList(response.data.data);
          setUsagesResult(response.data.meta);
          Helper.overlay(false);
        } else {
          Helper.overlay(false);
        }
      })
      .catch((error) => {
        Helper.overlay(false);
      });
  },[]);

  const handleActiceSharePageChange = (pageNumber) => {
    dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: pageNumber });
    Helper.overlay(true);
    apiRequest(ActiveShares.method, ActiveShares.url + `?page=${pageNumber}`)
      .then((response) => {
        if (response.data.code === 200) {
          setActiveShareResult(response.data.meta);
          Helper.overlay(false);
        } else {
          Helper.overlay(false);
        }
      })
      .catch((error) => {
        Helper.overlay(false);
      });
  };

  const handleUsagesPageChange = useCallback((pageNumber) => {
    dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: pageNumber });
    Helper.overlay(true);
    apiRequest(
      VehicleUsageAPI.method,
      VehicleUsageAPI.url + `${id}?page=${pageNumber}&per_page=50`
    )
      .then((response) => {
        if (response.data.code === 200) {
          setusageList(response.data.data);
          setUsagesResult(response.data.meta);
          Helper.overlay(false);
        } else {
          Helper.overlay(false);
        }
      })
      .catch((error) => {
        Helper.overlay(false);
      });
  },[dispatch, id]);

  const getKeyModelList = (vehicle_model_id) => {
    apiRequest(
      KeyModelList.method,
      KeyModelList.url + `vehicle_model_id=${vehicle_model_id}`
    )
      .then((response) => {
        if (response.data.code === 200) {
          setkeyList(response.data.data);
        } else {
        }
      })
      .catch((error) => { });
  };

  const handleCar = (event) => {
    let id = event.target.value;
    getVehicleModelList(id);
  };

  const handleModel = (event) => {
    let id = event.target.value;
    getKeyModelList(id);
  };

  const handleSubmit = (values) => {
    const form_data = Helper.formData(values);
    form_data.append("geofence", [JSON.stringify(getCords)]);
    Localdispatch({ type: "DEFAULT", payload: true });
    apiRequest(UpdateVehicleApI.method, UpdateVehicleApI.url + id, form_data)
      .then((response) => {
        if (response.data.code === 200) {
          Localdispatch({
            type: "FETCH_SUCCESS",
            payload: response.data.message,
          });
        }
      })
      .catch((error) => {
        //console.log(error);
        Localdispatch({
          type: "FETCH_ERROR",
          payload: error.response.data.data,
        });
        window.scrollTo(500, 0);
      });
  };

  const toggleTab = useCallback((index) => {
    if (index === 2 && useageApiCall) {
      setuseageApiCall(false);
      getVehicleUsages(id);
    }

    if (index === 3) {
      setheartBeatApiCall(true);
    }
    dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: 1 });
    setToggleState(index);
  },[dispatch, getVehicleUsages, id, useageApiCall]);

  const coordinateds = (cords) => {
    setCords(cords);
    console.log(cords);
  };

  const getActiveClass = (index, className) =>
    toggleState === index ? className : "";

  const handleAcrissCodeFieldBlur = (value) => {
    let error;
    const smallCharRegex = /[a-z]/;
    const numberRegex = /[0-9]/;
    if (value.length > 4) {
      error = "Input must be 4 characters long";
    } else if (smallCharRegex.test(value)) {
      error = "All characters must be in uppercase";
    } else if (numberRegex.test(value)) {
      error = "Only alphabets allowed";
    } else error = undefined;
    return error;
  };

  return (
    <React.Fragment>
      {/* <div className="wrapper d-flex align-items-stretch">
        <Sidebar /> */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>{errorMsg}</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <div id="content">
        <Header showSearch={false} showAdvanceSearch={false} />
        {/* {Helper.getPermissions(
            "vehicle-update",
            user_permissions && user_permissions,
            "admin"
          ) || Helper.getPermissions("", [], user.type) ? ( */}
        <div className="p-4">
          {records && (
            <Formik
              validateOnChange={false}
              validationSchema={VehicleSchema}
              onSubmit={(values) => {
                // same shape as initial values
                handleSubmit(values);
              }}
              initialValues={{
                device_code: records && records.detail.device_code,
                year: records && records.year,
                vehicle_company_id: records && records.vehicle_company_id,
                vehicle_model_id: records && records.vehicle_model_id,
                file:  null, 
                key_id: records && records.key_id,
                user_id: records && records.user_id,
                license: records && records.license,
                color: records && records.colour,
                tags: records && records.tags,
                doors: records && records.doors,
                trunk_button: records && records.trunk_button,
                ignition: records && records.ignition,
                share: records && records.share,
                name: records && records.name,
                door_button: records && records.door_button,
                key_holder: records && records.key_holder,
                acriss_code: records && records.acriss_code,
                fuel_tank_capacity:
                  records && records.fuel_tank_capacity
                    ? records.fuel_tank_capacity
                    : "",
                fuel_tank_price:
                  records && records.fuel_tank_price
                    ? records.fuel_tank_price
                    : "",
                button: 0,
              }}
            >
              {({
                handleSubmit,
                handleChange,
                isSubmitting,
                handleBlur,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <form className="mt-2 user" onSubmit={handleSubmit}>
                  <FlashMessage
                    success={LocalState.success}
                    isSuccess={LocalState.isSuccess}
                    isError={LocalState.isError}
                    errors={LocalState.errors}
                  />
                  <br />
                  {firmwareStatus === 3 && (
                    <div className="row">
                      <div className="col-md-11 pt-3">
                        <ProgressBar
                          animated
                          variant="warning"
                          now={firmwareResponse}
                        />
                      </div>
                    </div>
                  )}
                  <br />
                  <div className="row">
                    <div className="col-md-7">
                      <div className="form-row">
                        <div className="form-group col-md-8 col-lg-6 ">
                          <img
                            src={records && records.file}
                            alt="User"
                            style={{
                              height: "150px",
                              maxWidth: "100%",
                            }}
                          />
                        </div>
                        <div className="col-md-6">
                          <p>
                            Hardware:{" "}
                            <span>
                              {records.hardware_version
                                ? records.hardware_version
                                : "-"}
                            </span>
                          </p>
                          <p>
                            Firmware:{" "}
                            <span>
                              {records.software_version
                                ? records.software_version
                                : "-"}
                            </span>{" "}
                          </p>
                          <p>
                            Device Code:{" "}
                            <span>
                              {records.detail.device_code &&
                                records.device_id ? (
                                user.type === "whitelabel" ? (
                                  <strong>{records.detail.device_code}</strong>
                                ) : (
                                  <NavLink
                                    exact
                                    to={`/admin/edit-device-code/${records.device_id}`}
                                  >
                                    {" "}
                                    <strong>
                                      {" "}
                                      <u> {records.detail.device_code} </u>{" "}
                                    </strong>
                                  </NavLink>
                                )
                              ) : (
                                "-"
                              )}
                            </span>
                          </p>
                          <p>
                            Operator:{" "}
                            <u>{records.operator ? records.operator : "-"}</u>
                          </p>
                          <p>
                            Shared{" "}
                            <input
                              type="checkbox"
                              name="share"
                              defaultChecked={values.share}
                            />
                          </p>
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label className="form-label">Device code</label>
                          <input
                            type="text"
                            name="device_code"
                            defaultValue={values.device_code}
                            onChange={handleChange}
                            disabled
                            placeholder="DA00504EX0040DF"
                            className={`form-control form-control-user ${errors.device_code ? "error" : ""
                              }`}
                          />
                          {errors.device_code && (
                            <div className="ft-14 mt-1 red">
                              {errors.device_code}
                            </div>
                          )}
                        </div>

                        <div className="form-group col-md-6">
                          <label className="form-label">Vehicle Make</label>
                          <select
                            className="form-control hideArrow form-select-user"
                            name="vehicle_company_id"
                            value={values.vehicle_company_id}
                            onChange={(e) => {
                              setkeyList([]);
                              setmodelList([]);
                              values.vehicle_model_id = "";
                              values.key_id = "";
                              handleCar(e);
                              handleChange(e);
                            }}
                          >
                            <option value="">Select Vehicle Make</option>
                            {makeList &&
                              makeList.map((carMake, index) => (
                                <option value={carMake.id} key={index}>
                                  {carMake.name}
                                </option>
                              ))}
                          </select>
                          <div className="custom-arrow"></div>
                          {errors.username && touched.username ? (
                            <div className="ft-14 mt-1 red">
                              {errors.username}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label className="form-label">Vehicle Model</label>
                          <select
                            className="form-control hideArrow form-select-user"
                            name="vehicle_model_id"
                            value={values.vehicle_model_id}
                            onChange={(e) => {
                              setkeyList([]);
                              values.key_id = "";
                              handleModel(e);
                              handleChange(e);
                            }}
                          >
                            <option value="">Select Vehicle Model</option>
                            {modelList &&
                              modelList.map((record, index) => (
                                <option value={record.id} key={index}>
                                  {record.name}
                                </option>
                              ))}
                          </select>
                          <div className="custom-arrow"></div>
                          {errors.username && touched.username ? (
                            <div className="ft-14 mt-1 red">
                              {errors.username}
                            </div>
                          ) : null}
                        </div>

                        <div className="form-group col-md-6">
                          <label className="form-label">Key Model</label>
                          <select
                            className="form-control hideArrow form-select-user"
                            name="key_id"
                            value={values.key_id}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          >
                            <option value="">Select Key Model</option>
                            {keyList &&
                              keyList.map((record, index) => (
                                <option value={record.id} key={index}>
                                  {record.name}
                                </option>
                              ))}
                          </select>
                          <div className="custom-arrow"></div>
                          {errors.username && touched.username ? (
                            <div className="ft-14 mt-1 red">
                              {errors.username}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label className="form-label">Vehicle Name</label>
                          <input
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            placeholder="Car Name"
                            className={`form-control form-select-user ${errors.name ? "error" : ""
                              }`}
                          />
                          {errors.name && (
                            <div className="ft-14 mt-1 red">{errors.name}</div>
                          )}
                        </div>

                        <div className="form-group col-md-6">
                          <label className="form-label">
                            Vehicle Make Year
                          </label>
                          <input
                            type="text"
                            name="year"
                            defaultValue={values.year}
                            onChange={handleChange}
                            placeholder="2011"
                            className={`form-control form-control-user ${errors.year ? "error" : ""
                              }`}
                          />
                          {errors.year && touched.year ? (
                            <div className="ft-14 mt-1 red">{errors.year}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label className="form-label">Licence plate</label>
                          <input
                            type="text"
                            name="license"
                            defaultValue={records && records.license}
                            onChange={handleChange}
                            placeholder="Licence plate"
                            className={`form-control form-control-user`}
                          />
                        </div>

                        <div className="form-group col-md-6">
                          <label className="form-label">Vehicle Colour</label>
                          <input
                            type="text"
                            name="color"
                            defaultValue={values.color}
                            onChange={handleChange}
                            placeholder="White"
                            className={`form-control form-control-user`}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label className="form-label">Tags</label>
                          <input
                            type="text"
                            name="tags"
                            defaultValue={values.tags}
                            onChange={handleChange}
                            placeholder="Sportscar, Eco, Supercar, Electric, Hybrid, Hydrogen"
                            className={`form-control form-select-user`}
                          />
                        </div>

                        <div className="form-group  col-md-6">
                          <label className="form-label">Doors</label>
                          <select
                            className={`form-control hideArrow form-select-user ${errors.doors ? "error" : ""
                              }`}
                            name="doors"
                            value={values.doors}
                            onChange={handleChange}
                          >
                            <option value="">Select Doors</option>
                            <option value={2}>2</option>
                            <option value={4}>4</option>
                          </select>
                          <div className="custom-arrow"></div>
                          {errors.doors && (
                            <div className="ft-14 mt-1 red">{errors.doors}</div>
                          )}
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label className="form-label">
                            Open trunk button
                          </label>
                          <select
                            className={`form-control hideArrow form-select-user ${errors.trunk_button ? "error" : ""
                              }`}
                            name="trunk_button"
                            value={values.trunk_button}
                            onChange={handleChange}
                          >
                            <option value="">Select Open trunk button</option>
                            <option value={1}>Yes</option>
                            <option value={0}>No</option>
                          </select>
                          <div className="custom-arrow"></div>
                          {errors.trunk_button && (
                            <div className="ft-14 mt-1 red">
                              {errors.trunk_button}
                            </div>
                          )}
                        </div>

                        <div className="form-group col-md-6">
                          <label className="form-label">Ignition</label>
                          <select
                            className={`form-control hideArrow form-select-user  ${errors.ignition ? "error" : ""
                              }`}
                            name="ignition"
                            value={values.ignition}
                            onChange={handleChange}
                          >
                            <option value="">Select Ignition</option>
                            <option value={1}>Push-button</option>
                            <option value={0}>Key-ignition</option>
                          </select>
                          <div className="custom-arrow"></div>
                          {errors.ignition && (
                            <div className="ft-14 mt-1 red">
                              {errors.ignition}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label className="form-label">Open Door button</label>
                          <select
                            className={`form-control hideArrow form-select-user ${errors.door_button ? "error" : ""
                              }`}
                            name="door_button"
                            value={values.door_button}
                            onChange={handleChange}
                          >
                            <option value="">Select Open Door button</option>
                            <option value={1}>Yes</option>
                            <option value={0}>No</option>
                          </select>
                          <div className="custom-arrow"></div>
                          {errors.door_button && (
                            <div className="ft-14 mt-1 red">
                              {errors.door_button}
                            </div>
                          )}
                        </div>

                        <div className="form-group col-md-6">
                          <label className="form-label" htmlFor="avatar">
                            Avatar
                          </label>
                          <input
                            id="avatar"
                            type="file"
                            name="file"
                            accept="image/jpeg, image/jpg, image/png, image/gif, image/svg"
                            onChange={(event) => {
                              setFieldValue(
                                "file",
                                event.currentTarget.files[0]
                              );
                            }}
                            className={`form-control form-select-user  ${errors.file ? "error" : ""
                              }`}
                          />
                          {errors.file && (
                            <div className="ft-14 mt-1 red">{errors.file}</div>
                          )}
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group  col-md-6">
                          <label className="form-label">Key Holder</label>
                          <select
                            className={`form-control hideArrow form-select-user ${errors.key_holder ? "error" : ""
                              }`}
                            name="key_holder"
                            value={values.key_holder}
                            onChange={handleChange}
                          >
                            <option value="">Select Key Holder</option>
                            <option value={1}>Yes</option>
                            <option value={0}>No</option>
                          </select>
                          <div className="custom-arrow"></div>
                          {errors.key_holder && (
                            <div className="ft-14 mt-1 red">
                              {errors.key_holder}
                            </div>
                          )}
                        </div>
                        <div className="form-group col-md-6">
                          <label className="form-label">
                            Car group (ACRISS code)
                          </label>
                          <input
                            type="text"
                            name="acriss_code"
                            defaultValue={values.acriss_code}
                            onChange={(e) => {
                              handleChange(e);
                              const error = handleAcrissCodeFieldBlur(
                                e.target.value
                              );
                              errors.acriss_code = error;
                            }}
                            placeholder="ACRISS code"
                            className={`form-control form-select-user`}
                          />
                          {errors.acriss_code && (
                            <div className="ft-14 mt-1 red">
                              {errors.acriss_code}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label className="form-label">Fuel tank size</label>
                          <div className="input-group">
                            <input
                              type="number"
                              step="0.01"
                              name="fuel_tank_capacity"
                              defaultValue={values.fuel_tank_capacity}
                              onChange={handleChange}
                              placeholder="Fuel tank size"
                              onInput={(e) => {
                                if (e.target.value < 0) {
                                  e.target.value = 0;
                                }
                              }}
                              className={`form-control form-select-user ${errors.fuel_tank_capacity ? "error" : ""
                                }`}
                            />
                            <div className="input-group-append">
                              <span
                                className="input-group-text form-select-user"
                                style={{ padding: "11px" }}
                                id="basic-addon2"
                              >
                                Liters
                              </span>
                            </div>
                          </div>
                          {errors.fuel_tank_capacity && (
                            <div className="ft-14 mt-1 red">
                              {errors.fuel_tank_capacity}
                            </div>
                          )}
                        </div>
                        <div className="form-group col-md-6">
                          <label className="form-label">Full tank price</label>
                          <div className="input-group">
                            <input
                              type="number"
                              name="fuel_tank_price"
                              defaultValue={values.fuel_tank_price}
                              onChange={handleChange}
                              placeholder="Full tank price"
                              onInput={(e) => {
                                if (e.target.value < 0) {
                                  e.target.value = 0;
                                }
                              }}
                              className={`form-control form-select-user ${errors.fuel_tank_price ? "error" : ""
                                }`}
                            />
                            <div className="input-group-append">
                              <span
                                className="input-group-text  form-select-user "
                                style={{ padding: "11px" }}
                                id="basic-addon2"
                              >
                                Euros (€)
                              </span>
                            </div>
                          </div>
                          {errors.fuel_tank_price && (
                            <div className="ft-14 mt-1 red">
                              {errors.fuel_tank_price}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label className="form-label">User</label>
                          <select
                            className={`form-control hideArrow form-select-user ${errors.user_id ? "error" : ""
                              }`}
                            name="user_id"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          >
                            <option value="" selected="selected">
                              Select User
                            </option>
                            {userList &&
                              userList.map((user, index) => (
                                <option value={user.id} key={index}>
                                  {user.name}
                                </option>
                              ))}
                          </select>
                          <div className="custom-arrow"></div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-5">
                      <div className="mb-3">
                        <button
                          id={`door${records.id}`}
                          disabled={
                            doorLoader && records.id === doorLoader.id
                              ? true
                              : false
                          }
                          className={`btn btn-danger text-uppercase`}
                          data-id={records.id}
                          onClick={(e) => doorStatus(e, records.id, 1)}
                        >
                          {"Lock Doors"}
                          {doorLoader &&
                            records.id === doorLoader.id &&
                            doorLoader.type == 1 ? (
                            <span
                              className="ml-2 spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            ""
                          )}
                        </button>
                      </div>
                      <div className="mb-3">
                        <button
                          id={`door${records.id}`}
                          disabled={
                            doorLoader && records.id === doorLoader.id
                              ? true
                              : false
                          }
                          className={`btn btn-success text-uppercase`}
                          data-id={records.id}
                          onClick={(e) => doorStatus(e, records.id, 0)}
                        >
                          {"Unlock Doors"}
                          {doorLoader &&
                            records.id === doorLoader.id &&
                            doorLoader.type == 0 ? (
                            <span
                              className="ml-2 spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            ""
                          )}
                        </button>
                      </div>
                      <div className="mb-3">
                        <button
                          id={`trunk${records.id}`}
                          disabled={records.id === trunkLoader ? true : false}
                          className={`btn btn-success text-uppercase`}
                          data-id={records.id}
                          onClick={(e) =>
                            trunkStatus(e, records.id, records.trunk_button)
                          }
                        >
                          {`Open Trunk`}
                          {records.id === trunkLoader ? (
                            <span
                              className="ml-2 spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            ""
                          )}
                        </button>
                      </div>
                      {/* <div className="mb-3">
                          <button className="btn btn-danger">UPDATE FW</button>
                        </div> */}

                      <div className="mt-5">
                        <p>
                          Device alarms:
                          <span className="text-danger">
                            {records &&
                              Object.keys(records.alarms.device).length > 0
                              ? records.alarms.device.type +
                              ` (${records.alarms.device.priority} priority)`
                              : "-"}
                          </span>
                          <span className="text-danger">
                            {" "}
                            {Object.keys(records.alarms.device).length > 0
                              ? " ( " +
                              isoToDate(records.alarms.device.updated_at) +
                              " )"
                              : ""}{" "}
                          </span>
                        </p>
                        <p>
                          Car alarms:{" "}
                          <span className="text-danger">
                            <span className="text-danger">
                              {records &&
                                Object.keys(records.alarms.car).length > 0
                                ? records.alarms.car.type +
                                ` (${records.alarms.car.priority} priority)`
                                : "-"}
                            </span>
                            <span>
                              {" "}
                              {Object.keys(records.alarms.car).length > 0
                                ? " ( " +
                                isoToDate(records.alarms.car.updated_at) +
                                " )"
                                : ""}{" "}
                            </span>
                          </span>
                          {/* Engine light on (20 days) */}
                        </p>
                        <p>
                          Vehicle Battery:{" "}
                          {records && records.detail.supply_voltage
                            ? records.detail.supply_voltage + "V"
                            : "-"}
                        </p>
                      </div>

                      <div className="mt-4">
                        <p>
                          Last seen:{" "}
                          {records && records.last_seen ? (
                            <>
                              {Helper.getDateByTZ(records.last_seen)} (
                              {user?.timezone ? user.timezone : localTimezone}){" "}
                            </>
                          ) : (
                            "-"
                          )}
                        </p>
                        <p>
                          Last online:{" "}
                          {records && records.last_online
                            ? records.last_online
                            : "-"}
                        </p>
                        <p>
                          Internal battery:{" "}
                          {records && records.detail.battery_voltage
                            ? records.detail.battery_voltage + "V"
                            : "-"}
                        </p>
                        <p className="text-capitalize">
                          Status:{" "}
                          {records && records.status
                            ? records.status
                            : "offline"}
                        </p>
                      </div>

                      <div className="mt-4">
                        {/* <p>Odometer: 140 000 km</p> */}
                        <p>
                          Fuel level:{" "}
                          {records && records.detail.fuel
                            ? records.detail.fuel + `%`
                            : "-"}
                        </p>
                        <p>
                          Engine:{" "}
                          {records && records.detail
                            ? records.engine + `rpm`
                            : "Not Running"}
                        </p>
                        <p>
                          Central lock:{" "}
                          {records && records.central_lock == true ? (
                            <span className="text-success">Open</span>
                          ) : (
                            <span className="text-danger">Closed</span>
                          )}
                        </p>
                        <p>
                          Doors:{" "}
                          {records && records.detail.doors == "true" ? (
                            <span className="text-success">Open</span>
                          ) : (
                            <span className="text-danger">Closed</span>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  {Helper.getPermissions(
                    "vehicle-update",
                    permissions && permissions,
                    user.type
                  ) && (
                      <div className="form-row">
                        <div className="form-group d-flex justify-content-end col-md-12">
                          <button
                            type="submit "
                            className="btn-submit btn-user"
                            style={{ backgroundColor: user.background_color }}
                          >
                            confirm Details
                          </button>
                        </div>
                      </div>
                    )}
                  <div className="">
                    <p>
                      Location:{" "}
                      <u>{records.location ? records.location : "-"}</u>
                      <NavLink
                        exact
                        to={`/admin/single-vehicle-map-list/${records.id}`}
                      >
                        <i
                          className="fa fa-map-marker-alt pl-1"
                          style={{ color: "green" }}
                        ></i>
                      </NavLink>
                    </p>
                    <p>
                      Current user:{" "}
                      <u>
                        {records.usages.current_user.name
                          ? records.usages.current_user.name
                          : "-"}
                      </u>
                    </p>
                  </div>
                  <div className="">
                    <p>
                      Last Shared:{" "}
                      {records.last_share ? (
                        <>
                          {Helper.getDateByTZ(records.last_share)} (
                          {user?.timezone ? user.timezone : localTimezone}){" "}
                        </>
                      ) : (
                        "-"
                      )}
                    </p>
                    <p>
                      Last Usage:{" "}
                      {records && records.last_usage ? (
                        <>
                          {Helper.getDateByTZ(records.last_usage)} (
                          {user?.timezone ? user.timezone : localTimezone}){" "}
                        </>
                      ) : (
                        "-"
                      )}
                    </p>
                  </div>
                  <br />
                  <div className="tab">
                    <ul className="tab-list">
                      <li
                        className={`tabs ${getActiveClass(1, "active-tabs")}`}
                        style={{
                          backgroundColor:
                            toggleState === 1 ? user.background_color : "",
                        }}
                        onClick={() => toggleTab(1)}
                      >
                        Active Share
                      </li>
                      <li
                        className={`tabs ${getActiveClass(2, "active-tabs")}`}
                        style={{
                          backgroundColor:
                            toggleState === 2 ? user.background_color : "",
                        }}
                        onClick={() => toggleTab(2)}
                      >
                        Usages List
                      </li>
                      <li
                        className={`tabs ${getActiveClass(5, "active-tabs")}`}
                        style={{
                          backgroundColor:
                            toggleState === 5 ? user.background_color : "",
                        }}
                        onClick={() => toggleTab(5)}
                      >
                        Alarms
                      </li>
                      <li
                        className={`tabs ${getActiveClass(3, "active-tabs")}`}
                        style={{
                          backgroundColor:
                            toggleState === 3 ? user.background_color : "",
                        }}
                        onClick={() => toggleTab(3)}
                      >
                        Heartbeat
                      </li>
                      <li
                        className={`tabs ${getActiveClass(4, "active-tabs")}`}
                        style={{
                          backgroundColor:
                            toggleState === 4 ? user.background_color : "",
                        }}
                        onClick={() => toggleTab(4)}
                      >
                        Trip Map View
                      </li>
                    </ul>
                    <div className="content-container">
                      <div
                        className={`content  ${getActiveClass(
                          1,
                          "active-content"
                        )}`}
                      >
                        <div className="col-md-12">
                          <table className="table table-bordered tab-table">
                            <thead>
                              <tr>
                                <th>Share ID</th>
                                <th>Access Type</th>
                                <th>Shared With</th>
                                <th>Operator ID</th>
                                <th>Duration</th>
                              </tr>
                            </thead>
                            <tbody>
                              {activeSharesList ? (
                                activeSharesList?.map((share, index) => (
                                  <tr>
                                    <td>
                                      {user.type === "whitelabel" ? <strong>SM{share.id}</strong> :
                                        <NavLink
                                          exact
                                          to={`/admin/shares-list/${share.id}#active_share`}
                                        >
                                          {" "}
                                          <strong>SM{share.id}</strong>
                                        </NavLink>}
                                      {share.is_booking === 0 ? (
                                        <strong className="btn-primary badge badge-primary p-1 ml-1">
                                          Shared
                                        </strong>
                                      ) : (
                                        <strong className="btn-success badge badge-sucess p-1 ml-1">
                                          Booking
                                        </strong>
                                      )}
                                    </td>
                                    <td>{renderType(share.access_type)}</td>
                                    <td>{share.operator.name}</td>
                                    <td>A{share.operator.id}</td>
                                    <td>
                                      {Helper.getDateByTZ(share.start_date)}{" "}
                                      {Helper.getDateByTZ(share.end_date)}{" "}
                                      {values.checkout_at}{" "}
                                      {user?.timezone
                                        ? `(${user.timezone})`
                                        : `(${Helper.localTimezone()})`}
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td className="text-center ft-14 mt-3 font-weight-bold">
                                    No Data Found...
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                          <div className="row mt-3 ft-14">
                            <div className="col-md-12">
                              <nav aria-label="Page navigation example">
                                <ul className="pagination justify-content-end">
                                  <Pagination
                                    activePage={state.set_active_page}
                                    totalItemsCount={
                                      activeShareResult.total
                                        ? activeShareResult.total
                                        : 1
                                    }
                                    pageRangeDisplayed={5}
                                    itemsCountPerPage={50}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    onChange={handleActiceSharePageChange}
                                    activeClass={user.type === "whitelabel" ? "active-whitelabel-pagination" : "active-default-pagination "}
                                  />
                                  <li className="pl-3"></li>
                                </ul>
                              </nav>
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                      {/* Tab 1 close */}
                      <div
                        className={`content ${getActiveClass(
                          2,
                          "active-content"
                        )}`}
                      >
                        <div className="col-md-12">
                          <table className="table table-bordered tab-table">
                            <thead>
                              <tr>
                                {/* {user.type !== "whitelabel" && <th>Usage ID</th>} */}
                                <th>Share ID</th>
                                <th>User Name</th>
                                <th>Vehicle Name</th>
                                <th>Date</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {usageList ? (
                                usageList.map((usage, index) => (
                                  <tr>
                                    {/* {user.type !== "whitelabel" &&
                                      <td>
                                        
                                          {" "} <strong>{usage.usage_id}</strong>
                  
                                      </td>
                                    } */}
                                    <td>
                                      {usage.share_id}{" "}
                                      {usage.share_status
                                        ? "Active"
                                        : "Unactive"}
                                    </td>
                                    <td>
                                      {usage.user ? usage.user.name : "--"}
                                    </td>
                                    <td>
                                      {usage.vehicle
                                        ? usage.vehicle.name
                                        : "--"}
                                    </td>
                                    <td>
                                      {Helper.getDateByTZ(usage.date)}{" "}
                                      {values.checkout_at}{" "}
                                      {user?.timezone
                                        ? `(${user.timezone})`
                                        : `(${Helper.localTimezone()})`}
                                    </td>
                                    <td className="text-capitalize">
                                      {
                                        usage.data
                                          ? usage.type === "doors" ||
                                            usage.type === "door"
                                            ? "Central Lock " +
                                            usage.data.status
                                            : usage.type === "trunk"
                                              ? "Trunk " + usage.data.status
                                              : usage.data.status
                                          : "--"
                                      }
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td className="text-center ft-14 mt-3 font-weight-bold">
                                    No Data Found...
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                          <div className="row mt-3 ft-14">
                            <div className="col-md-12">
                              <nav aria-label="Page navigation example">
                                <ul className="pagination justify-content-end">
                                  <Pagination
                                    activePage={usagesResult?.current_page}
                                    totalItemsCount={
                                      usagesResult.total
                                        ? usagesResult.total
                                        : 1
                                    }
                                    pageRangeDisplayed={5}
                                    itemsCountPerPage={50}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    onChange={handleUsagesPageChange}
                                    activeClass={user.type === "whitelabel" ? "active-whitelabel-pagination" : "active-default-pagination "}
                                  />
                                  <li className="pl-3"></li>
                                </ul>
                              </nav>
                            </div>
                          </div>
                        </div>
                        {/** Usage list */}
                      </div>
                      <div
                        className={`content ${getActiveClass(
                          3,
                          "active-content"
                        )}`}
                      >
                        {heartBeatApiCall && (
                          <HeartBeat header="no" search="no" vid={id} />
                        )}
                      </div>
                      <div
                        className={`content ${getActiveClass(
                          4,
                          "active-content"
                        )} justify-content-center`}
                      >
                        {toggleState === 4 ? <VehocleTrip vid={id} /> : <></>}
                      </div>
                      <div
                        className={`content ${getActiveClass(
                          5,
                          "active-content"
                        )} justify-content-center`}
                      >
                        {toggleState === 5 ? <VehicleAlarm vid={id} /> : <></>}
                      </div>
                      {/* <div className="form-group col-md-6">
                            <label className="form-label">Search location</label>
                            <input
                                type="text"
                                name="googlelocation"
                                onChange={(e) => {
                                    handleChange(e);
                                    const error = searchLocation(e.target.value);
                                    errors.location = error;
                                }}
                                placeholder="e.x Estonia puiestee, Tallinn, Estonia"
                                className={`form-control form-control-user ${errors.location ? "error" : ""
                                    }`}
                            />
                            {errors.location && (
                                <div className="ft-14 mt-1 red">{errors.location}</div>
                            )}
                            </div>*/}
                      {showMap && (
                        <StationMap
                          search={getSeachLocation}
                          gcords={coordinateds}
                          sdata={geofence}
                          s={showMap}
                        />
                      )}
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          )}
        </div>
        {/* ) : (
            <div className="p-4">
              <div className="text-center ft-14 mt-3 font-weight-bold">
                You are  to visit this screen...
              </div>
            </div>
          )} */}
      </div>
      {/* </div> */}
    </React.Fragment>
  );
}

export default VehicleDetials;

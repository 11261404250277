import React from "react";
import Header from "../Header/Index.js";
import { Redirect } from "react-router-dom";

import { Formik } from "formik";
import * as Yup from "yup";
import FlashMessage from "../FlashMessage/FlashMessage";
var { apiRequest } = require("../Api/Service");
var { CreateHelpAndSupport } = require("../Api/ApiRoutes");
const Helper = require("../Helper");

const initialState = {
  isError: false,
  errors: [],
  isSuccess: false,
  success: "",
  disable: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_SUCCESS":
      return {
        ...state,
        isSuccess: true,
        success: action.payload,
        disable: false,
      };
    case "FETCH_ERROR":
      return {
        ...state,
        isError: true,
        errors: action.payload,
        disable: false,
      };
    case "DEFAULT":
      return {
        disable: action.payload,
      };
    default:
      return state;
  }
};

function AddHelpAndSupportSubCategory(props) {
  const VehicleSchema = Yup.object().shape({
    title: Yup.string().required("This Field is Required"),
    content: Yup.string().required("This Field is Required"),
  });

  const [LocalState, Localdispatch] = React.useReducer(reducer, initialState);
  const [redirectToReferrer, setredirectToReferrer] = React.useState(false);
  var user = Helper.getStorageData("SESSION");
  var permissions = user.permissions;
  let id = props.match.params.id;
  let name = props.match.params.name;
  // React.useMemo(() => {
  //   // if (
  //   //   Helper.getPermissions(
  //   //     "admin-update",
  //   //     user_permissions && user_permissions,
  //   //     "admin"
  //   //   ) ||
  //   //   Helper.getPermissions("", [], user.type)
  //   // ) {
  //     apiRequest(
  //       GetHelpAndSupportCategoryList.method,
  //       GetHelpAndSupportCategoryList.url + id
  //     )
  //       .then((response) => {
  //         let data = response.data.data;
  //         if (response.data.code === 200) {
  //           setRecord(data);
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });

  // }, [id, user.type, user_permissions]);

  const handleSubmit = (values) => {
    const form_data = Helper.formData(values);
    Localdispatch({ type: "DEFAULT", payload: true });
    apiRequest(CreateHelpAndSupport.method, CreateHelpAndSupport.url, form_data)
      .then((response) => {
        if (response.data.code === 200) {
          Localdispatch({
            type: "FETCH_SUCCESS",
            payload: response.data.message,
          });
          setTimeout(() => {
            setredirectToReferrer(true);
          }, 2000);
        }
      })
      .catch((error) => {
        //console.log(error);
        Localdispatch({
          type: "FETCH_ERROR",
          payload: error.response.data.data,
        });
        window.scrollTo(500, 0);
      });
  };

  if (redirectToReferrer) {
    return <Redirect to={"/admin/help-and-support/1"} />;
  }

  return (
    <React.Fragment>
      {/* <div className="wrapper d-flex align-items-stretch">
       <Sidebar /> */}
      <div id="content">
        <Header showSearch={false} showAdvanceSearch={false} />
        {Helper.getPermissions(
          "help_and_support-create",
          permissions && permissions,
          user.type
        )  ? (
          <div className="p-4">
            <h4>Create Subcategory</h4>
            <Formik
              validateOnChange={false}
              validationSchema={VehicleSchema}
              onSubmit={(values) => {
                // same shape as initial values
                handleSubmit(values);
              }}
              initialValues={{
                name: "",
                parent_id: id,
                title: "",
                content: "",
                lang: "en",
              }}
            >
              {({
                handleSubmit,
                handleChange,
                isSubmitting,
                setFieldValue,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <form className="mt-2" onSubmit={handleSubmit}>
                  <FlashMessage
                    success={LocalState.success}
                    isSuccess={LocalState.isSuccess}
                    isError={LocalState.isError}
                    errors={LocalState.errors}
                  />
                  <div className="form-row">
                    {/* <div className=" col-md-6">
                      <label className="">Help and Support Category</label>
                      <input
                        type="text"
                        value={name}
                        disabled={true}
                     />
                    </div> */}
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <h5 className="form-label">Subcategory Title</h5>
                      <input
                        type="text"
                        name="title"
                        onChange={handleChange}
                        placeholder="Subcategory Title"
                        className={`form-control ${
                          errors.device_code ? "error" : ""
                        }`}
                      />
                      {errors.device_code && (
                        <div className="ft-14 mt-1 red">
                          {errors.device_code}
                        </div>
                      )}
                    </div>
                    <div className="form-group col-md-6">
                      <h5 className="form-label">Subcategory name</h5>
                      <input
                        type="text"
                        name="name"
                        onChange={handleChange}
                        placeholder="Subcategory Name"
                        className={`form-control ${errors.name ? "error" : ""}`}
                      />
                      {errors.name && (
                        <div className="ft-14 mt-1 red">{errors.name}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <select
                        className={`form-control  dropdown-height ${
                          errors.lang ? "error" : ""
                        }`}
                        name="lang"
                        value={values.lang}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      >
                        <option value="en">English</option>
                        <option value="et">Estonia</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-row pt-2">
                    <div className="form-group col-md-5">
                      <h5 className="form-label">Subcategory Content</h5>
                      <textarea
                        name="content"
                        className={`form-control ${
                          errors.device_code ? "error" : ""
                        }`}
                        id="Textarea"
                        onChange={handleChange}
                        placeholder="Subcategory Content"
                      ></textarea>
                      {errors.device_code && (
                        <div className="ft-14 mt-1 red">
                          {errors.device_code}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group d-flex justify-content-end col-md-12">
                      <button
                        type="submit"
                        className="btn-submit"
                        disabled={LocalState.disable}
                        style={{
                          backgroundColor: user.background_color,
                        }}
                      >
                        confirm Details
                        {LocalState.disable ? (
                          <span
                            className="ml-2 spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          ""
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        ) : (
          <div className="p-4">
            <div className="text-center ft-14 mt-3 font-weight-bold">
              You are not allowed to visit this screen...
            </div>
          </div>
        )}
      </div>
      {/* </div> */}
    </React.Fragment>
  );
}

export default AddHelpAndSupportSubCategory;

import React from "react";
import Header from "../Header/Index.js";
import { Formik } from "formik";
import * as Yup from "yup";
import FlashMessage from "../FlashMessage/FlashMessage";
import Button from "../../commonComponents/Button.js";

var { apiRequest } = require("../Api/Service");
var { getAlarmSettingsApi, UpdatAlarmSettinsgApi } = require("../Api/ApiRoutes");

const Helper = require("../Helper");
const initialState = {
  isError: false,
  errors: [],
  isSuccess: false,
  success: "",
  disable: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_SUCCESS":
      return {
        ...state,
        isSuccess: true,
        success: action.payload,
        disable: false,
      };
    case "FETCH_ERROR":
      return {
        ...state,
        isError: true,
        errors: action.payload,
        disable: false,
      };
    case "DEFAULT":
      return {
        disable: action.payload,
      };
    default:
      return state;
  }
};

function AlarmSetting() {
  const DetailSchema = Yup.object().shape({
    supply_voltage: Yup.string().required("This Field is Required"),
    battery_voltage_low_alarm: Yup.string().required("This Field is Required"),
    odb_disconnect: Yup.string().required("This Field is Required"),

  });

  const [LocalState, Localdispatch] = React.useReducer(reducer, initialState);
  const [record, setRecord] = React.useState(undefined);
  let user = Helper.getStorageData("SESSION");
  React.useEffect(() => {
    apiRequest(getAlarmSettingsApi.method, getAlarmSettingsApi.url)
      .then((response) => {
        if (response.data.code === 200) {
          let data = response.data.data;
          setRecord(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleSubmit = (values) => {
    const form_data = Helper.formData(values);
    Localdispatch({ type: "DEFAULT", payload: true });
    apiRequest(UpdatAlarmSettinsgApi.method, UpdatAlarmSettinsgApi.url, form_data)
      .then((response) => {
        if (response.data.code === 200) {
          // Helper.setStorageData('SESSION',data)
          Localdispatch({
            type: "FETCH_SUCCESS",
            payload: response.data.message,
          });

        }
      })
      .catch((error) => {
        console.log(error);
        Localdispatch({
          type: "FETCH_ERROR",
          payload: error.response.data.data,
        });
        window.scrollTo(500, 0);
      });
  };

  return (
    <React.Fragment>
      {/* <div className="wrapper d-flex align-items-stretch">
        <Sidebar /> */}

      <div id="content">
        <Header showSearch={false} showAdvanceSearch={false} />
        <div className="p-4">
          <h4 className="mb-3">Alarm Settings</h4>
          {record && (
            <Formik
              validateOnChange={false}
              validationSchema={DetailSchema}
              onSubmit={(values) => {
                // same shape as initial values
                console.log(values);
                handleSubmit(values);
              }}
              initialValues={{
                supply_voltage: record && record[0].value,
                battery_voltage_low_alarm: record && record[1].value,
                odb_disconnect: record && record[2].value,

              }}
            >
              {({
                handleSubmit,
                handleChange,
                isSubmitting,
                handleBlur,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <form className="mt-2 user" onSubmit={handleSubmit}>
                  <FlashMessage
                    success={LocalState.success}
                    isSuccess={LocalState.isSuccess}
                    isError={LocalState.isError}
                    errors={LocalState.errors}
                  />
                  <div className="form-row">

                    <div className="form-group col-md-6">
                      <label className="form-label">Supply Voltage</label>
                      <input
                        type="text"
                        name="supply_voltage"
                        value={values.supply_voltage || ""}
                        onChange={handleChange}
                        placeholder="Enter the Supply Voltage"
                        className={`form-control form-control-user   ${errors.supply_voltage ? "error" : ""
                          }`}
                      />
                      {errors.supply_voltage && touched.supply_voltage ? (
                        <div className="ft-14 mt-1 red">{errors.supply_voltage}</div>
                      ) : null}
                    </div>

                    <div className="form-group col-md-6">
                      <label className="form-label">Battery Voltage Low Alarm</label>
                      <input
                        type="text"
                        name="battery_voltage_low_alarm"
                        value={values.battery_voltage_low_alarm || ""}
                        onChange={handleChange}
                        placeholder="Enter the Battery Voltage Low Alarm value"
                        className={`form-control form-control-user   ${errors.battery_voltage_low_alarm ? "error" : ""
                          }`}
                      />
                      {errors.battery_voltage_low_alarm && touched.battery_voltage_low_alarm ? (
                        <div className="ft-14 mt-1 red">{errors.battery_voltage_low_alarm}</div>
                      ) : null}
                    </div>



                    <div className="form-group col-md-6">
                      <label className="form-label">Odb Disconnect</label>
                      <input
                        type="text"
                        name="odb_disconnect"
                        value={values.odb_disconnect || ""}
                        onChange={handleChange}
                        placeholder="Enter the Odb Disconnect value"
                        className={`form-control form-control-user   ${errors.odb_disconnect ? "error" : ""
                          }`}
                      />
                      {errors.odb_disconnect && touched.odb_disconnect ? (
                        <div className="ft-14 mt-1 red">{errors.odb_disconnect}</div>
                      ) : null}
                    </div>



                  </div>

                  <div className="form-row">
                    <div className="form-group d-flex justify-content-end col-md-12">
                      <Button
                        type={"submit"}
                        localState={LocalState}
                        title={'Save'}
                        size={"btn-sm"}
                      />
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          )}
        </div>
      </div>
      {/* </div> */}
    </React.Fragment>
  );
}

export default AlarmSetting;

import React, { useEffect, useCallback } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Header from "../Header/Index.js";
import { Modal, Button, Icon } from "rsuite";
import Pagination from "react-js-pagination";
import Helper from "../Helper";
import { Context } from "../Store/Store";
import { useHistory, useParams } from 'react-router-dom';
import CheckBox from "../../commonComponents/CheckBox.js";
var { UserListAPI, deleteUser, alldeleteUser, StatusUpdate, ListSearchApi } = require("../Api/ApiRoutes");
var { apiRequest } = require("../Api/Service");
var _ = require('lodash');


function UserList() {

  const [records, setrecords] = React.useState([]);
  const [result, setResult] = React.useState({});
  const [state, dispatch] = React.useContext(Context);
  const [statusData, setStatus] = React.useState([]);
  var user = Helper.getStorageData("SESSION");
  var permissions = user.permissions;
  const [rsModal, setRsModal] = React.useState(false);
  const [currentId, setCurrentId] = React.useState(null);
  const [locationKeys, setLocationKeys] = React.useState([]);
  // eslint-disable-next-line no-unused-vars
  const [errorMsg, setErrorMsg] = React.useState("");
  const [sort_by, setSortBy] = React.useState(1);

  const history = useHistory()
  let location = useLocation()
  const { id } = useParams()

  const Modalclose = () => {
    setRsModal(false);
  };
  const Modalopen = (e) => {
    setCurrentId(e.target.dataset.id);
    setRsModal(true);
  };

  const getUserList = useCallback((page, sort) => {
    if (
      Helper.getPermissions("user-read", permissions && permissions, "admin") ||
      user.type === "superadmin"
    ) {
      Helper.overlay(true);
      apiRequest(UserListAPI.method, UserListAPI.url + `?page=${page}&sort_by=${sort ?? sort_by}`)
        .then((response) => {
          if (response.data.code === 200) {
            let data = response.data.data;
            setrecords(data);
            setStatus(_.keyBy(data || [], 'id'))
            setResult(response.data.meta);
            dispatch({ type: "SET_SEARCH_STATE", search_list: false });
            Helper.overlay(false);
          }
        })
        .catch((error) => {
          setErrorMsg(error)
          Helper.overlay(false);
        });
    }
  }, [dispatch, permissions, sort_by, user.type])

  const getUserById = useCallback((id) => {
    Helper.overlay(true)
    apiRequest(
      UserListAPI.method,
      UserListAPI.url +
      `?user_id=${id}`
    )
      .then((response) => {
        if (response.data.code === 200) {
          let data = response.data;
          setrecords(data.data)
          setStatus(_.keyBy(data.data || [], 'id'))
          setResult(data.meta);
          dispatch({ type: "SET_SEARCH_STATE", search_list: false });
          Helper.overlay(false);
        }
      })
      .catch((error) => {
        setErrorMsg(error)
        console.error("FETCH_ERROR");
        Helper.overlay(false);
      });
  }, [dispatch])

  const handleSearchPageChange = useCallback((pageNumber) => {
    Helper.overlay(true);
    apiRequest(ListSearchApi.method, ListSearchApi.url + `user/all?name=${state.searchedName}&page=${pageNumber}`)
      .then((response) => {
        if (response.data.code === 200) {
          let data = response.data;
          setrecords(data.data);
          setResult(data.meta);
          Helper.overlay(false);
        }
      })
      .catch((error) => {
        Helper.overlay(false);
        history.push(`${location.pathname}#error`);
      });

  }, [history, location.pathname, state.searchedName])


  useEffect(() => {
    return history.listen(location => {
      if (history.action === 'PUSH') {
        setLocationKeys([location.key])
      }

      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          let str = location.pathname
          let id = str.substr(str.length - 1)
          dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: +id });
          if (location.pathname.includes("user-list")) {
            getUserList(+id);
          }
          setLocationKeys(([_, ...keys]) => keys)
        } else {
          let str = location.pathname
          let id = str.substr(str.length - 1)
          dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: +id });
          if (location.pathname.includes("user-list")) {
            getUserList(+id);
          }
          setLocationKeys((keys) => [location.key, ...keys])
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, history, locationKeys, state.active_page])


  useEffect(() => {
    if (location.hash !== "#search" && location.hash !== "#userId" && !state.searchedName) {
      dispatch({ type: "SET_STATE", response: [] });
      getUserList(state.set_active_page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location.hash, state.set_active_page]);

  useEffect(() => {
    if (location.pathname.includes('user-list') && location.hash === "#search" && state.searchedName) {
      dispatch({ type: "SET_STATE", response: [] });
      handleSearchPageChange(state.set_active_page)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location.hash, state.searchedName, state.set_active_page])


  useEffect(() => {
    if (location.pathname.includes('user-list') && location.hash === "#userId") {
      getUserById(id)
    }

    if (location.hash === "#error") {
      setrecords([]);
      setResult([]);
      //setShow(true);
      setErrorMsg('No record Found');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, location, state.data, state.pagination]);

  function getcheckbox() {
    var array = [];
    var checkboxes = document.querySelectorAll(
      "input[name='subcheckbox']:checked"
    );
    for (var i = 0; i < checkboxes.length; i++) {
      array.push(checkboxes[i].value);
      checkboxes[i].checked = false;
    }
    console.log("array", array);
    let params = new FormData();
    params.append("ids", array);
    let delete_property = window.confirm(
      "Are you sure you want to delete these records!"
    );
    if (delete_property) {
      apiRequest(alldeleteUser.method, alldeleteUser.url, params)
        .then((response) => {
          if (response.data.code === 200) {
            let data = state.data;
            array.forEach((el) => {
              data = data.filter((d) => {
                return d.id !== el
              })
            })
            dispatch({ type: "SET_STATE", response: data });
            setrecords(data);
            setRsModal(false);
            console.log("Success", response);
          } else {
            console.log("Failure", response);
          }
        })
        .catch((error) => {
          console.log("error", error);
          alert(error);
        });
    }
  }

  function handleMainCheckBox(e) {
    var value = false;

    if (e.target.checked) {
      value = true;
    }

    Array.from(document.querySelectorAll("input[name='subcheckbox']")).forEach(
      (checkbox) => {
        checkbox.checked = value;
      }
    );
  }

  const deleteRecord = (e) => {

    apiRequest(deleteUser.method, deleteUser.url + `${currentId}`)
      .then((response) => {
        if (response.data.code === 200) {
          let data = records.filter((d) => {
            return d.id !== +currentId
          })
          dispatch({ type: "SET_STATE", response: data });
          setrecords(data);
          setRsModal(false);
          setResult((prev) => ({ ...prev, total: prev.total - 1 }))
          console.log("Success", response);
        } else {
          console.log("Failure", response);
        }
      })
      .catch((error) => {
        console.log("error", error);
        alert(error);
      });
  };

  const handlePageChange = (pageNumber) => {
    dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: pageNumber });
    Helper.overlay(true);
    if (location.pathname.includes('user-list') && location.hash === "#search") {
      history.push(`/admin/user-list/${pageNumber}#search`);
    } else {
      history.push(`/admin/user-list/${pageNumber}`);
    }
  }
  const statusUpdate = (id, status) => {
    let params = new FormData();
    params.append("id", id);
    params.append("status", status);
    Helper.overlay(true);
    apiRequest(
      StatusUpdate.method,
      StatusUpdate.url, params
    )
      .then((response) => {
        if (response.data.code === 200) {
          let data = response.data.data;
          statusData[data.id] = data;
          setStatus({ ...statusData })
          Helper.overlay(false);
        } else {
          console.log("Failure", response);
          Helper.overlay(false);
        }
      })
      .catch((error) => {
        Helper.overlay(false);
        console.log("error", error);
      });

  };
  const setDropDown = (e) => {
    setSortBy(e.target.value)
    getUserList(state.set_active_page, e.target.value)
  }
  return (
    <React.Fragment>
      <Modal backdrop="static" show={rsModal} onHide={Modalclose} size="xs">
        <Modal.Body>
          <Icon
            icon="remind"
            style={{
              color: "#ffb300",
              fontSize: 24,
            }}
          />
          {"  "}
          Are you sure you want to delete this record?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={deleteRecord} appearance="primary">
            Ok
          </Button>
          <Button onClick={Modalclose} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <div className="wrapper d-flex align-items-stretch">
        <Sidebar /> */}
      <div id="content">
        <Header />
        {Helper.getPermissions(
          "user-read",
          permissions && permissions,
          "admin"
        ) || Helper.getPermissions("", [], user.type) ? (
          <div className="p-4">
            <div className="row pb-2 d-flex justify-content-between align-items-center border-bottom">
              <div className="col-lg-8 col-md-6">
                <div className="d-flex flex-row">
                  <div className="p-2  d-flex align-items-center">
                    {(Helper.getPermissions(
                      "user-delete",
                      permissions && permissions,
                      "admin"
                    ) ||
                      Helper.getPermissions("", [], user.type)) && (
                        <CheckBox onChange={handleMainCheckBox} />
                      )}
                  </div>
                  <div className="p-2">
                    {(Helper.getPermissions(
                      "user-delete",
                      permissions && permissions,
                      "admin"
                    ) ||
                      Helper.getPermissions("", [], user.type)) && (
                        <button
                          className="btn btnn-user btn-primary"
                          onClick={getcheckbox}
                        >
                          Bulk Delete
                        </button>
                      )}
                  </div>
                  {
                    //Helper.getPermissions('usage-create',permissions) &&
                    <div className="p-2">
                      {(Helper.getPermissions(
                        "user-create",
                        permissions && permissions,
                        "admin"
                      ) ||
                        Helper.getPermissions("", [], user.type)) && (
                          <NavLink
                            exact
                            to={`/admin/add-users-detail`}
                            className="btn white btnn-user bg-orange" style={{ backgroundColor: user.background_color }}
                          >
                            New User
                          </NavLink>
                        )}
                    </div>
                  }
                </div>
              </div>
              {/* <div className="col-md-1 p-2 text-center font-weight-bold"> */}
              {/* <NavLink className="black" exact to={`/admin/current-user-list`}>
                  Show My List
                </NavLink> */}
              {/* {(Helper.getPermissions(
                  "user-read",
                  permissions && permissions,
                  "admin"
                ) ||
                  Helper.getPermissions("", [], user.type)) && (
                  <React.Fragment>
               
                    <NavLink
                      className="black"
                      exact
                      to={`/admin/user-list`}
                    >
                      Show All List
                    </NavLink>
                  </React.Fragment>
                )} */}
              {/* {` | `}
                <NavLink className="black" exact to={`/admin/vehicle-map-list`}>
                  Show on Map
                </NavLink> */}
              {/* </div> */}

              <div className="col-lg-4 col-md-6  ">
                <div className="d-flex row align-items-center">
                  <div className="col-md-12 col-lg-3 pl-lg-0 pr-lg-0">Results: {result.total ? result.total : '0'}</div>
                  <div className="col-md-12 col-lg-9 pl-lg-0 pr-lg-0 row">
                  <label class="col-3 mt-auto">Sort By:</label>
                    <select className="form-select form-control col-8 " aria-label="Default select example" name="sort_by" onChange={setDropDown}>
                      <option selected={sort_by === 1 ? true : false} value={1}>Last created on top</option>
                      <option selected={sort_by === 2 ? true : false} value={2}>First created on top</option>
                      <option selected={sort_by === 3 ? true : false} value={3}>Alphabetically A-Z</option>
                      <option selected={sort_by === 4 ? true : false} value={4}>Alphabetically Z-A</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              {records.length ? (
                records.map((record, index) => (
                  <div
                    className="card col-12 mt-3 shadow bg-white rounded"
                    key={index}
                  >
                    <div className="row">
                      <div className="col-md-12 col-lg-8  col-sm-12 pb-3 pt-2">
                        <div className="row pt-2">
                          <div className="col-12">
                            <input
                              type="checkbox"
                              className=""
                              name="subcheckbox"
                              defaultValue={record.id}
                              id="exampleCheck1"
                            />
                            <NavLink
                              className="ml-3 font-weight-bold"
                              exact
                              to={`/admin/edit-user/${record.id}`}
                            >{record.id}

                            </NavLink>
                          </div>
                        </div>
                        <div className="row pt-2">
                          <div className="col-md-4 col-12">
                            Name: {" "}
                            <strong>{record.name}</strong>
                          </div>
                          <div className="col-md-3 col-12">
                            Type: {" "}
                            <strong>{record.type}</strong>
                          </div>
                          <div className="col-md-5 col-12">
                            TelePhone: {" "}
                            <strong>
                              <span> {record.country_code ? record.country_code : "-"} </span>
                              <span> {record.contact ? record.contact : "-"} </span>
                            </strong>
                          </div>


                        </div>
                        <div className="row pt-2">
                          <div className="col-md-4 col-12">
                            Drivers behaviour score:
                            <strong>
                              {" "}
                              <span className="text-success">{record.score ? record.score : '0'}</span>
                            </strong>
                          </div>
                          <div className="col-md-3 col-12">
                            Last Usage:
                            <strong>{record.last_usage}</strong>
                          </div>
                          <div className="col-md-5 col-12">
                            Usages: {" "}
                            <strong>{record.usages}</strong>
                          </div>


                        </div>
                        <div className="row pt-2">

                          <div className="col-md-7 col-12">
                            Email: {" "}
                            <strong>{record.email}</strong>
                          </div>
                          <div className="col-md-5 col-12">
                            Created At: {" "}
                            <strong>{Helper.getDateByTZ(record?.created_at)} (
                              {user?.timezone
                                ? user.timezone
                                : Helper.localTimezone()}
                              )</strong>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 col-sm-12  col-lg-2 ">
                        <div className="row pt-3 pb-3">
                          <div className="col-md-4  col-sm-4 col-lg-12 pt-lg-4">
                            <button
                              className={`${+statusData[record.id]?.status === 1 ? `btn-success` : `btn-danger`} w-100`}
                              onClick={(e) =>
                                statusUpdate(
                                  record.id,
                                  +statusData[record.id]?.status === 1 ? "2" : "1"
                                )
                              }
                            >
                              {`${+statusData[record.id]?.status === 1
                                ? "Unblock"
                                : "Block"
                                }`}
                            </button>
                          </div>
                          <div className="col-md-4 col-sm-4 col-lg-12 pt-lg-3 pt-md-0 pt-2">
                            {(Helper.getPermissions(
                              "user-update",
                              permissions && permissions,
                              "admin"
                            ) ||
                              Helper.getPermissions("", [], user.type)) && (
                                <NavLink
                                  className=""
                                  exact
                                  to={`/admin/edit-user/${record.id}`}
                                >
                                  <button className="btn-success w-100">
                                    Edit
                                  </button>
                                </NavLink>
                              )}
                          </div>
                          <div className="col-md-4  col-sm-4 col-lg-12 pt-lg-3 pt-md-0 pt-2">
                            {(Helper.getPermissions(
                              "admin-delete",
                              permissions && permissions,
                              "admin"
                            ) ||
                              Helper.getPermissions("", [], user.type)) && (
                                <button className="btn-danger w-100"
                                  data-id={record.id}
                                  onClick={Modalopen}
                                >
                                  Delete
                                </button>
                              )}
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>
                ))
              ) : (
                // errorMsg && (
                <div className="text-center ft-14 mt-3 font-weight-bold">
                  No Data Found...
                </div>
                // )
              )}
            </div>
            <div className="row mt-3 ft-14">
              <div className="col-md-12">
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-end">
                    <Pagination
                      activePage={state.set_active_page}
                      // itemsCountPerPage={result.to}
                      totalItemsCount={result.total ? result.total : 1}
                      pageRangeDisplayed={5}
                      itemClass="page-item"
                      linkClass="page-link"
                      onChange={handlePageChange}
                      activeClass={user.type === "whitelabel" ? "active-whitelabel-pagination" : "active-default-pagination "}
                    />
                    <li className="pl-3">
                      {/* <form className="form-inline">
                          <label className="my-1 mr-2">Results on page:</label>
                          <select
                            className="form-control p-0"
                            style={{ height: "30px" }}
                            defaultValue={"10"}
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </form> */}
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        ) : (
          <div className="p-4">
            <div className="text-center ft-14 mt-3 font-weight-bold">
              You are not allowed to visit this screen...
            </div>
          </div>
        )}
      </div>
      {/* </div> */}
    </React.Fragment>
  );
}
export default UserList;

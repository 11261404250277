import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import Header from "../Header/Index.js";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FlashMessage from "../FlashMessage/FlashMessage";
import { Context } from "../Store/Store";
import { Modal, Button, Icon } from "rsuite";

//import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

//import { EditorState, convertToRaw } from 'draft-js';
import EditorConvertToHTML from './Editor.js';

//import draftToHtml from 'draftjs-to-html';
const Helper = require("../Helper");
let userObj = Helper.getStorageData("SESSION");


var { apiRequest } = require("../Api/Service");
var {
    UserPermissions,
    whitelabelListEndpoint,
    GetTemplate,
    UpdateTemplate,
    getPlaceholder,
    getInsuracePlaceholder,
    GetInsuranceTemplate
} = require("../Api/ApiRoutes");


const initialState = {
    isError: false,
    errors: [],
    isSuccess: false,
    success: "",
    disable: false,
};

const reducer = (state, action) => {
    switch (action.type) {
        case "FETCH_SUCCESS":
            return {
                ...state,
                isSuccess: true,
                success: action.payload,
                disable: true,
            };
        case "FETCH_ERROR":
            return {
                ...state,
                isError: true,
                errors: action.payload,
                disable: false,
            };
        case "DEFAULT":
            return {
                disable: action.payload,
            };
        default:
            return state;
    }
};


function ContractEdit(props) {

    const [LocalState, Localdispatch] = React.useReducer(reducer, initialState);
    const [redirectToReferrer, setredirectToReferrer] = useState(false);
    const [setPermissions] = React.useState([]);
    const [redirectId, setRedirectId] = React.useState(null);
    const [whitelabelList, setWhitelabelList] = React.useState([]);
    const [template, setTemplate] = React.useState([]);
    const [showTemplate, setShowTemplate] = React.useState(false);
    const [showInsuranceTemplate, setShowInsuranceTemplate] = React.useState(false);
    const [showInsuranceetTemplate, setShowInsuranceetTemplate] = React.useState(false);
    const [state, dispatch] = React.useContext(Context);
    const [getTemplate, setGetemplate] = React.useState(false);
    const [placeholder, setPlaceholder] = React.useState([]);
    const [Insuranceplaceholder, setInsurancePlaceholder] = React.useState([]);
    const [getInsuranceTemplate, setGeInsurancetemplate] = React.useState(false);
    const [getInsuranceetTemplate, setGeInsuranceettemplate] = React.useState(false);
    const [Insurancetemplate, setInsuranceTemplate] = React.useState([]);
    const [Insuranceettemplate, setInsuranceetTemplate] = React.useState([]);
    var user = Helper.getStorageData("SESSION");
    let labelId = props.match.params.id;


    useEffect(() => {
        handleContract()
    }, [])
    const handleSubmit = (values) => {

        const form_data = new FormData();
        form_data.append("label_id", labelId);
        form_data.append("content", getTemplate);
        if (getTemplate !== false) {
            form_data.append("content", getTemplate);
        }
        else {
            form_data.append("content", template.data);
        }

        if (getInsuranceTemplate !== false) {
            form_data.append("extra_insurance_content", getInsuranceTemplate);
        }
        else {
            form_data.append("extra_insurance_content", Insurancetemplate.data);
        }

        if (getInsuranceetTemplate !== false) {
            form_data.append("extra_insurance_content_et", getInsuranceetTemplate);
        }
        else {
            form_data.append("extra_insurance_content_et", Insuranceettemplate.data);
        }

        Localdispatch({ type: "DEFAULT", payload: true });
        apiRequest(UpdateTemplate.method, UpdateTemplate.url, form_data)
            .then((response) => {
                if (response.data.code === 200) {
                    Localdispatch({
                        type: "FETCH_SUCCESS",
                        payload: response.data.message
                    });

                    dispatch({
                        type: "SET_SEARCH_STATE",
                        search_list: true,
                        pagination: false,
                        searchedName: null,
                    });
                    setTimeout(() => {
                        setredirectToReferrer(true);
                    }, 2000);
                }
            })
            .catch((error) => {
                console.log(error);
                Localdispatch({
                    type: "FETCH_ERROR",
                    payload: error.response.data.data,
                });
                window.scrollTo(500, 0);
            });
    };

    React.useEffect(() => {
        apiRequest(UserPermissions.method, UserPermissions.url)
            .then((response) => {
                if (response.data.code === 200) {
                    let data = response.data.data;
                    setPermissions(data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [setPermissions]);



    if (redirectToReferrer) {
        return <Redirect to={{
            pathname: `/admin/contract-listing/1`,
        }} />;
    }


    const handleContract = () => {
        //setadminurl(`http://127.0.0.1:8000/admin-react-template/`+id+'/'+userLoggedInToken)
        //setTempModal(true);

        //window.open( `https://dev.movitronic.com/admin-react-template/`+id+'/'+userLoggedInToken,'_blank',"toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=100,width=1000,height=1000");

        apiRequest(GetInsuranceTemplate.method, GetInsuranceTemplate.url + `${labelId}&lang=en`)
            .then((response) => { setInsuranceTemplate(response); setShowInsuranceTemplate(true); })
            .catch((error) => {
                setShowInsuranceTemplate(false);
                console.log(error);
            });
        apiRequest(GetInsuranceTemplate.method, GetInsuranceTemplate.url + `${labelId}&lang=et`)
            .then((response) => { setInsuranceetTemplate(response); setShowInsuranceetTemplate(true); })
            .catch((error) => {
                setShowInsuranceetTemplate(false);
                console.log(error);
            });

        apiRequest(
            GetTemplate.method,
            GetTemplate.url + `${labelId}`
        )
            .then((response) => {
                setTemplate(response);
                setShowTemplate(true);
            })
            .catch((error) => {
                setShowTemplate(false);
                console.log(error);
            });

        apiRequest(
            getPlaceholder.method,
            getPlaceholder.url
        )
            .then((response) => {
                if (response.data.code === 200) {
                    setPlaceholder(response.data.data);
                } else {
                    console.log("Failure", response);
                }
            })
            .catch((error) => {
                console.log(error);
            });



        apiRequest(
            getInsuracePlaceholder.method,
            getInsuracePlaceholder.url
        )
            .then((response) => {
                if (response.data.code === 200) {
                    setInsurancePlaceholder(response.data.data);
                } else {
                    console.log("Failure", response);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handTemplate = (template) => {
        setGetemplate(template);
        console.log(template);
    }
    const handInsuranceTemplate = (template) => {
        setGeInsurancetemplate(template);
        console.log(template);
    }
    const handInsuranceetTemplate = (template) => {
        setGeInsuranceettemplate(template);
        console.log(template);
    }

    return (
        <React.Fragment>

            <div id="content">
                <Header showSearch={false} showAdvanceSearch={false} />

                <div className="p-4">
                    <h3 className="mb-3">Edit Contract</h3>


                    <Formik
                        validateOnChange={false}
                        onSubmit={async (values) => {
                            handleSubmit(values);
                        }}
                        initialValues={{
                            label_id: "",
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            isSubmitting,
                            setFieldValue,
                            handleBlur,
                            values,
                            touched,
                            isValid,
                            errors,
                        }) => (
                            <Form className="mt-2 user">
                                <FlashMessage
                                    success={LocalState.success}
                                    isSuccess={LocalState.isSuccess}
                                    isError={LocalState.isError}
                                    errors={LocalState.errors}
                                />


                                <div>
                                    {showTemplate && <h3>Contract</h3>}
                                    <div className="alert alert-info" role="alert">
                                        Placeholders: {placeholder &&
                                            placeholder.map((precord, index) => (
                                                <strong className="badge p-2 m-1 label-assg badge-success mt-2" key={index}>{precord}</strong>
                                            ))}
                                    </div>

                                    {showTemplate && <EditorConvertToHTML tem={template} selTemplate={handTemplate} />}
                                    {showTemplate && <h3 class="mt-4">Extras</h3>}
                                    {showInsuranceTemplate && <div className="alert alert-info mt-3" role="alert">
                                        Extra Placeholders: {Insuranceplaceholder &&
                                            Insuranceplaceholder.map((precord, index) => (
                                                <strong className="badge p-2 m-1 label-assg badge-success mt-2" key={index}>{precord}</strong>
                                            ))}
                                    </div>}
                                    {showInsuranceTemplate && <h3>Extra in English</h3>}
                                    {showInsuranceTemplate && <EditorConvertToHTML tem={Insurancetemplate} selTemplate={handInsuranceTemplate} />}
                                    {showInsuranceetTemplate && <h3>Extra in Estonian</h3>}
                                    {showInsuranceetTemplate && <EditorConvertToHTML tem={Insuranceettemplate} selTemplate={handInsuranceetTemplate} />}
                                </div>
                                {showTemplate && <div className="form-row mt-2">
                                    <div className="form-group d-flex justify-content-end col-md-12">
                                        <button
                                            type="submit"
                                            className="btn-submit" style={{ backgroundColor: user.background_color }}
                                            disabled={LocalState.disable}
                                        >
                                            confirm Details
                                            {LocalState.disable ? (
                                                <span
                                                    className="ml-2 spinner-border spinner-border-sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                            ) : (
                                                ""
                                            )}
                                        </button>
                                    </div>
                                </div>
                                }

                            </Form>
                        )}
                    </Formik>
                </div>

            </div>
        </React.Fragment>
    );
}

export default ContractEdit;

import { React } from "react";

const CheckBox = ({ onChange }) => {
    return (
        <div className="row gap-1 px-2">
            <input
                type="checkbox"
                onChange={onChange}
            />
            <label className="pt-2 pl-2">Select all</label>
        </div>
    )
}
export default CheckBox
import { Redirect } from "react-router-dom";
const _ = require("lodash");
const Helper = require("./Helper");

const NotFound = () => {
  const user = !_.isEmpty(localStorage.getItem("SESSION")) ? true : false;
  const userData = Helper.getStorageData("SESSION");

  if (user) {
    if (userData.shares > 0) {
      return <Redirect to={"/admin/vehicle-list/1"} exact />;
    }
    else {
      return <Redirect to={"/admin/thankyou/1"} exact />;
    }
    
  } else {
    return <Redirect to={"/"} exact />;
  }
};
export default NotFound;

import React, { useState } from "react";
import { Upload, Image } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import Helper from "../Helper.js";


function ExtraFields({ inputValue, index, handleFieldChange, handleRemoveField, errors }) {
    const [previewImage, setPreviewImage] = useState("");
    const [isPreviewVisible, setPreviewVisible] = useState(false);

    let user = Helper.getStorageData("SESSION");
    const permissions = user.permissions;



    const getBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

    const handleImgPreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(!isPreviewVisible);
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    return (
        <React.Fragment>


            <div className="form-group col-md-3">
                <div className="">
                    <label
                        className="form-check-label mt-n2"
                        for="inlineCheckbox2"
                    >
                        Extra Title
                    </label>
                    <input
                        type="text"
                        value={inputValue?.title}
                        name="title"
                        onChange={(event) => {
                            handleFieldChange(index, "title", event.target.value);
                        }}
                        placeholder="Add New Extra"
                        className={`mb-3 form-control form-control-user ${errors.server_path ? "error" : ""
                            }`}
                    />
                </div>
            </div>

            <div className="form-group col-md-3">
                <div className="">
                    <label
                        className="form-check-label mt-n2"
                        for="price"
                    >
                        Extra Price
                    </label>
                    <input
                        type="text"
                        value={inputValue?.price}
                        name="price"
                        onChange={(event) => {
                            handleFieldChange(index, "price", event.target.value);
                        }}
                        placeholder="Price"
                        className={`mb-3 form-control form-control-user ${errors.server_path ? "error" : ""
                            }`}
                    />
                </div>
            </div>
            <div className="form-group col-md-3 mt-3">
                <div className="form-check form-check-inline">
                    <input
                        className="form-check-input"
                        type="radio"
                        name={`type_${index}`}
                        id={`dailyPrice_${index}`}
                        value="1"
                        checked={inputValue.type === "1"}
                        onChange={(event) => {
                            handleFieldChange(index, "type", event.target.value);
                        }}
                    />
                    <label className="form-check-label" htmlFor={`dailyPrice_${index}`}>
                        Daily price
                    </label>
                </div>
                <div className="form-check form-check-inline">
                    <input
                        className="form-check-input"
                        type="radio"
                        name={`type_${index}`}
                        id={`totalPrice_${index}`}
                        value="2"
                        checked={inputValue.type === "2"}
                        onChange={(event) => {
                            handleFieldChange(index, "type", event.target.value);
                        }}
                    />
                    <label className="form-check-label" htmlFor={`totalPrice_${index}`}>
                        Total Price
                    </label>
                </div>

                <div className="form-check form-check-inline">
                    <input
                        className="form-check-input"
                        type="radio"
                        name={`item_type_${index}`}
                        id={`singleItem_${index}`}
                        value="1"
                        checked={inputValue.item_type === "1"}
                        onChange={(event) => {
                            handleFieldChange(index, "item_type", event.target.value);
                        }}
                    />
                    <label className="form-check-label" htmlFor={`singleItem_${index}`}>
                        Single item
                    </label>
                </div>
                <div className="form-check form-check-inline">
                    <input
                        className="form-check-input"
                        type="radio"
                        name={`item_type_${index}`}
                        id={`multipleItems_${index}`}
                        value="2"
                        checked={inputValue.item_type === "2"}
                        onChange={(event) => {
                            handleFieldChange(index, "item_type", event.target.value);
                        }}
                    />
                    <label className="form-check-label" htmlFor={`multipleItems_${index}`}>
                        Multiple items
                    </label>
                </div>
            </div>

            <div className="form-group col-md-1 mt-3 p-0">
                <div className=" form-check form-check-inline">
                    <input
                        id={`is_vat${index}`}
                        className="form-check-input"
                        name='is_vat'
                        type="checkbox"
                        onChange={(event) => {
                            handleFieldChange(index, 'is_vat', event.target.checked);
                        }}
                        checked={inputValue?.is_vat}
                    />
                    <label className="form-check-label mt-n2" htmlFor={`is_vat${index}`}>+Vat</label>
                </div>
                <div className="form-check form-check-inline">
                    <input
                        id={`is_default_${index}`}
                        className="form-check-input"
                        name="is_default"
                        type="checkbox"
                        onChange={(event) => {
                            handleFieldChange(index, 'is_default', event.target.checked);
                        }}
                        checked={inputValue?.is_default}
                    />
                    <label className="form-check-label mt-n2" htmlFor={`is_default_${index}`}>Default</label>
                </div>
            </div>

            {Helper.getPermissions(
                "extras-delete",
                permissions && permissions,
                user.type
            ) && (
                    <div className="form-group col-md-1">
                        <div className="">
                            <div className="pl-5 pr-4 pt-2">
                                <i
                                    className="fas fa-minus hover"
                                    style={{
                                        fontSize: "30px",
                                        color: user.background_color,
                                        cursor: "pointer",
                                    }}
                                    aria-hidden="true"
                                    onClick={(event) => {
                                        handleRemoveField(index, event);
                                    }}
                                ></i>
                            </div>
                        </div>
                    </div>
                )}

            <div className="form-group col-md-6">
                <label className="form-label">ENG</label>
                <input
                    type="text"
                    value={inputValue?.title_en}
                    name={`title_en_${index}`}
                    id={`title_en_${index}`}
                    onChange={(event) => {
                        handleFieldChange(index, "title_en", event.target.value);
                    }}
                    placeholder="English"
                    className={`mb-3 form-control form-control-user ${errors.server_path ? "error" : ""
                        }`}
                />
            </div>
            <div className="form-group col-md-6">
                <label className="form-label">EST</label>
                <input
                    type="text"
                    value={inputValue?.title_es}
                    name={`title_es_${index}`}
                    id={`title_es_${index}`}
                    onChange={(event) => {
                        handleFieldChange(index, "title_es", event.target.value);
                    }}
                    placeholder="Estonian"
                    className={`mb-3 form-control form-control-user ${errors.server_path ? "error" : ""
                        }`}
                />
            </div>

            <div className="form-group col-md-12">
                Image Upload
                <Upload
                    id={`image_upload_${index}`}
                    accept="image/png, image/jpeg"
                    customRequest
                    listType="picture-card"
                    fileList={inputValue?.image}
                    onPreview={handleImgPreview}
                    onChange={(file) => {
                        handleFieldChange(index, "image", file.file);
                    }}
                    showUploadList={{
                        showPreviewIcon: true,
                        showRemoveIcon: true,
                    }}
                >
                    {uploadButton}
                </Upload>
                <Image
                    width={200}
                    style={{
                        display: "none",
                        transform: `rotate(90deg)`,
                    }}
                    preview={{
                        visible: isPreviewVisible,
                        onVisibleChange: (visible, prevVisible) =>
                            setPreviewVisible(visible),
                    }}
                    src={previewImage}
                />
            </div>
            <div className="form-group col-md-6">
                <label className="form-label">
                    Short Description (EN)
                </label>
                <input
                    type="text"
                    value={inputValue?.short_description_en}
                    name={`short_description_en_${index}`}
                    id={`short_description_en_${index}`}
                    onChange={(event) => {
                        handleFieldChange(
                            index,
                            "short_description_en",
                            event.target.value
                        );
                    }}
                    placeholder="Short Description in English"
                    className={`mb-3 form-control form-control-user ${errors.short_description_en ? "error" : ""
                        }`}
                />
            </div>
            <div className="form-group col-md-6">
                <label className="form-label">
                    Short Description (ET)
                </label>
                <input
                    type="text"
                    value={inputValue?.short_description_et}
                    name={`short_description_et_${index}`}
                    id={`short_description_et_${index}`}
                    onChange={(event) => {
                        handleFieldChange(
                            index,
                            "short_description_et",
                            event.target.value
                        );
                    }}
                    placeholder="Short Description in Estonian"
                    className={`mb-3 form-control form-control-user ${errors.short_description_et ? "error" : ""
                        }`}
                />
            </div>
            <div class="form-group col-md-6">
                <label
                    className="form-label"
                    for="long_description_en"
                >
                    Long Description (EN)
                </label>
                <textarea
                    name={`long_description_en_${index}`}
                    id={`long_description_en_${index}`}
                    placeholder="Enter Description in English"
                    value={inputValue?.long_description_en}
                    onChange={(event) => {
                        handleFieldChange(
                            index,
                            "long_description_en",
                            event.target.value
                        );
                    }}
                    className={`mb-3 form-control form-control-user ${errors.long_description_en ? "error" : ""
                        }`}
                />
            </div>
            <div className="form-group col-md-6">
                <label className="form-label">
                    Long Description (ET)
                </label>
                <textarea
                    name={`long_description_et_${index}`}
                    id={`long_description_et_${index}`}
                    placeholder="Enter Description in Estonian"
                    value={inputValue?.long_description_et}
                    onChange={(event) => {
                        handleFieldChange(
                            index,
                            "long_description_et",
                            event.target.value
                        );
                    }}
                    className={`mb-3 form-control form-control-user ${errors.long_description_et ? "error" : ""
                        }`}
                />
            </div>

        </React.Fragment>
    );
}

export default ExtraFields;

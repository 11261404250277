import React, { useEffect, useCallback } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Header from "../Header/Index.js";
import { Modal, Button, Icon } from "rsuite";
import { Context } from "../Store/Store";
import { useHistory } from 'react-router-dom';
import Pagination from "react-js-pagination";
import Helper from "../Helper";
var {
  UsageListMyAPI,
  deleteUsage,
  alldeleteUsage,
  ListSearchApi
} = require("../Api/ApiRoutes");
var { apiRequest } = require("../Api/Service");

function UsageMyList() {
  var user = Helper.getStorageData("SESSION");
  var permissions = user.permissions;
  var location = useLocation();
  const [records, setrecords] = React.useState([]);
  const [result, setResult] = React.useState({});
  const [rsModal, setRsModal] = React.useState(false);
  const history = useHistory()
  const [state, dispatch] = React.useContext(Context);
  const [locationKeys, setLocationKeys] = React.useState([])
  const [currentId, setCurrentId] = React.useState(null);
  const [errorMsg, setErrorMsg] = React.useState("");

  const Modalclose = () => {
    setRsModal(false);
  };
  const Modalopen = (e) => {
    setCurrentId(e.target.dataset.id);
    setRsModal(true);
  };

  const getUsageMyList = useCallback((page) => {
    Helper.overlay(true);
    apiRequest(UsageListMyAPI.method, UsageListMyAPI.url + `?page=${page}`)
      .then((response) => {
        if (response.data.code === 200) {
          let data = response.data.data;
          setrecords(data);
          //dispatch({ type: "SET_SEARCH_STATE", search_list: false });
          setResult(response.data.meta);
          Helper.overlay(false);
        }
        else {
          Helper.overlay(false);
          console.log("Failure", response);
        }
      })
      .catch((error) => {
        setErrorMsg(error)
        Helper.overlay(false);
      });
  }, [])


  const handleSearchPageChange = (pageNumber) => {
    Helper.overlay(true);
    apiRequest(ListSearchApi.method, ListSearchApi.url + `usage/own?start_date=${state.start_date}&end_date=${state.end_date}
    &page=${pageNumber}`)
    .then((response) => {
      if (response.data.code === 200) {
        let data = response.data;
        dispatch({ type: "SET_STATE", response: data });
        dispatch({ type: "SET_SEARCH_STATE", pagination: true });
        dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: pageNumber });
        history.push(`/admin/usage-my-list/${pageNumber}#search`);
          // setrecords(response.data.data);
          // setResult(response.data.meta);
          Helper.overlay(false);

        }
      })
      .catch((error) => {
        Helper.overlay(false);
      });
  }


  useEffect(() => {
    if (location.hash !== "#search" && !state.start_date) {
      dispatch({ type: "SET_STATE", response: [] });
      getUsageMyList(state.set_active_page);
    }
    else {
      Helper.overlay(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getUsageMyList, location.hash, state.set_active_page]);

  useEffect(() => {
    if (location.pathname.includes('usage-my-list') && location.hash === "#search") {
      setrecords(state.data.data || []);
      setResult(state.data.meta || [])
      dispatch({ type: "SET_SEARCH_STATE", pagination: false });
    }

    if (location.hash === "#error") {
      setrecords([]);
      setResult([]);
      setErrorMsg('No record found');
    }
  }, [dispatch, location, state.data, state.pagination]);

  useEffect(() => {
    return history.listen(location => {
      if (history.action === 'PUSH') {
        setLocationKeys([location.key])
      }

      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          let str = location.pathname
          let id = str.substr(str.length - 1)
          dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: +id });
          getUsageMyList(+id);
          setLocationKeys(([_, ...keys]) => keys)
        } else {
          setLocationKeys((keys) => [location.key, ...keys])
          let str = location.pathname
          let id = str.substr(str.length - 1)
          dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: +id });
          getUsageMyList(+id);
        }
      }
    })
  }, [dispatch, getUsageMyList, history, locationKeys])

  function getcheckbox() {
    var array = [];
    var checkboxes = document.querySelectorAll(
      "input[name='subcheckbox']:checked"
    );
    for (var i = 0; i < checkboxes.length; i++) {
      array.push(checkboxes[i].value);
      checkboxes[i].checked = false;
    }

    let params = new FormData();
    params.append("ids", array);
    let delete_property = window.confirm(
      "Are you sure you want to delete these records!"
    );
    if (delete_property) {
      apiRequest(alldeleteUsage.method, alldeleteUsage.url, params)
        .then((response) => {
          if (response.data.code === 200) {
            let data = records;
            array.forEach((el) => {
              data = data.filter((d) => {
                return d.id !== el
              })
            })
            setrecords(data);
            setRsModal(false);
  
          } else {
            console.log("Failure", response);
          }
        })
        .catch((error) => {
          alert(error);
        });
    }
  }

  function handleMainCheckBox(e) {
    var value = false;

    if (e.target.checked) {
      value = true;
    }

    Array.from(document.querySelectorAll("input[name='subcheckbox']")).forEach(
      (checkbox) => {
        checkbox.checked = value;
      }
    );
  }

  const deleteRecord = (e) => {
    apiRequest(deleteUsage.method, deleteUsage.url + `${currentId}`)
      .then((response) => {
        if (response.data.code === 200) {
          let data = records.filter((d) => {
            // eslint-disable-next-line eqeqeq
            return d.id != currentId.trim()
          })
          setrecords(data);
          setRsModal(false);
          console.log("Success", response);
        } else {
          console.log("Failure", response);
        }
      })
      .catch((error) => {
        console.log("error", error);
        alert(error);
      });
    //}
  };


  const handlePageChange = (pageNumber) => {
    dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: pageNumber });
    Helper.overlay(true);
    apiRequest(
      UsageListMyAPI.method,
      UsageListMyAPI.url + `?page=${pageNumber}`
    )
      .then((response) => {
        if (response.data.code === 200) {
          setrecords(response.data.data);
          setResult(response.data.meta);
          console.log("Success", response);
          history.push(`/admin/usage-my-list/${pageNumber}`);
          Helper.overlay(false);
        } else {
          Helper.overlay(false);
          console.log("Failure", response);
        }
      })
      .catch((error) => {
        Helper.overlay(false);
        console.log("error", error);
      });
  };


  return (
    <React.Fragment>
      <Modal backdrop="static" show={rsModal} onHide={Modalclose} size="xs">
        <Modal.Body>
          <Icon
            icon="remind"
            style={{
              color: "#ffb300",
              fontSize: 24,
            }}
          />
          {"  "}
          Are you sure you want to delete this record?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={deleteRecord} appearance="primary">
            Ok
          </Button>
          <Button onClick={Modalclose} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <div id="content">
        <Header />
        {
          <div className="p-4">
            <div className="row border-bottom">
              <div className="col-8">
                <div className="d-flex flex-row mb-3">
                  <div className="p-2 d-flex align-items-center">
                    {(Helper.getPermissions(
                      "usage-delete",
                      permissions && permissions,
                      "admin"
                    ) ||
                      Helper.getPermissions("", [], user.type)) && (
                        <input type="checkbox" onChange={handleMainCheckBox} />
                      )}
                  </div>
                  <div className="p-2">
                    {(Helper.getPermissions(
                      "usage-delete",
                      permissions && permissions,
                      "admin"
                    ) ||
                      Helper.getPermissions("", [], user.type)) && (
                        <button
                          className="btn btnn-user btn-primary"
                          onClick={getcheckbox}
                        >
                          Bulk Delete
                        </button>
                      )}
                  </div>
                  {
                    <div className="p-2">
                    </div>
                  }
                </div>
              </div>

              <div className="col-4 d-flex align-items-center">
                <div className="d-flex flex-row mb-3">
                  <div className="p-2">Results: {result.total ? result.total : '1'}</div>
                  <div className="pl-4 pt-1">
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              {records.length ? (
                records.map((record, index) => (
                  <div
                    className="card col-md-12 mt-3 shadow bg-white rounded"
                    key={index}
                  >
                    <div className="row">
                      <div className="col-md-4 col-lg-2  col-6 pb-3 pt-2 my-auto">
                        <img
                          src={record.vehicle.file}
                          className="card-img-top"
                          alt="..."
                          style={{ borderRadius: "50% !important" }}
                        />
                      </div>
                      <div className="col-md-12 col-lg-8  col-sm-12 pb-3 pt-2">
                        <div className="row pt-2">
                          <div className="col-12">
                            <input
                              type="checkbox"
                              className=""
                              name="subcheckbox"
                              defaultValue={record.id}
                              id="exampleCheck1"
                            />
                            <NavLink
                              className="ml-3 font-weight-bold"
                              exact
                              to={`/admin/usage-details/${record.id}`}
                            >{record.id}

                            </NavLink>
                          </div>
                        </div>
                        <div className="row pt-1">
                          <div className="col-md-4 col-12">
                            Vehicle Id: {" "}
                            <strong>
                              {record.vehicle.id ? record.vehicle.id : "-"}
                            </strong>
                          </div>
                          <div className="col-md-4 col-12">
                            Car Name:
                            <strong>
                              {" "} {record.vehicle && record.vehicle.name}
                            </strong>
                          </div>
                          <div className="col-md-4 col-12">
                            Key Name:{" "}
                            <strong>
                              {record.vehicle && record.vehicle.key_name}
                            </strong>
                          </div>
                        </div>
                        <div className="row pt-2">
                          <div className="col-md-4 col-12">
                            User Name: {" "}
                            <strong>
                              {record.vehicle.user.name ? record.vehicle.user.name : "-"}
                            </strong>
                          </div>
                          <div className="col-md-4 col-12">
                            <strong>
                              Drivers Behavior Score:{" "}
                            </strong>
                            <span className="text-success">{record.score ? record.score : '0'}</span>
                          </div>
                          <div className="col-md-4 col-12">
                            ADD_BT Share Key: {" "}
                            <strong>{record.ADD_BT_share_key}</strong>
                          </div>
                        </div>
                        <div className="row pt-2">
                          <div className="col-md-4 col-12">
                            <strong className="btn-success badge badge-success">Start:</strong>{" "}
                            {record.created_at ? record.created_at : "-"}

                          </div>
                          <div className="col-md-4 col-12">
                            Pick-up: {" "}
                            <strong>
                              {(record.from_latitude ?
                                <a
                                  href={`https://www.google.com/maps/search/?api=1&query=${record.from_latitude},${record.from_longitude}`}
                                  target='blank'
                                >
                                  {record.from ? record.from : `${record.from_latitude},${record.from_longitude}`}
                                </a>
                                : '-')}
                            </strong>
                          </div>
                          <div className="col-md-4 col-12">
                            Tags:{" "}
                            <strong>
                              {record.vehicle && record.vehicle.tags}
                            </strong>
                          </div>
                        </div>
                        <div className="row pt-2">
                          <div className="col-md-4 col-12">
                            <strong className="btn-danger badge badge-danger">Stop:</strong>{" "}
                            {record.vehicle.last_usage ? record.vehicle.last_usage : "-"}
                          </div>
                          <div className="col-md-4 col-12">

                            Drop-off from: {" "}
                            <strong>
                              {(record.from_latitude ?
                                <a
                                  href={`https://www.google.com/maps/search/?api=1&query=${record.to_latitude},${record.to_longitude}`}
                                  target='blank'
                                >
                                  {record.to ? record.to : `${record.to_latitude},${record.to_longitude}`}
                                </a>
                                : '-')}
                            </strong>

                          </div>
                          <div className="col-md-4 col-12">
                            License:
                            <strong>
                              {" "}
                              {record.vehicle && record.vehicle.license}
                            </strong>
                          </div>
                          
                        </div>
                        <div className="row pt-2">
                          <div className="col-12">
                            Current Usage:{" "}
                            <strong>
                              {" "}
                              {record.vehicle.usages &&
                                record.vehicle.usages.current_usage.id}
                            </strong>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 col-sm-12  col-lg-2 my-auto">
                        <div className="row pt-3 pb-3">

                          <div className="col-md-12 col-sm-12 col-lg-12 pt-lg-3 my-auto">
                          {(Helper.getPermissions(
                              "admin-delete",
                              permissions && permissions,
                              "admin"
                            ) ||
                              Helper.getPermissions("", [], user.type)) && (
                                <button
                                  data-id={record.id}
                                  onClick={Modalopen}
                                  className="btn-danger w-100"
                                >Delete
                                </button>
                              )}
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                ))
              ) : (
                 errorMsg && (
                  <div className="text-center ft-14 mt-3 font-weight-bold">
                    No Data Found...
                  </div>
                )
              )}
            </div>

            <div className="row mt-3 ft-14">
              <div className="col-md-12">
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-end">
                    <Pagination
                      activePage={state.set_active_page}
                      totalItemsCount={result.total ? result.total : 1}
                      pageRangeDisplayed={3}
                      itemClass="page-item"
                      linkClass="page-link"
                      onChange={(state.start_date || state.end_date) ? handleSearchPageChange : handlePageChange}
                      activeClass={user.type === "whitelabel" ? "active-whitelabel-pagination" : "active-default-pagination "}
                    />
                    <li className="pl-3">
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        }
      </div>
      {/* </div> */}
    </React.Fragment>
  );
}
export default UsageMyList;

const _ = require("lodash");

const Reducer = (state, payload) => {

  let userObj = _.isEmpty(localStorage.getItem("SESSION"))
    ? ""
    : localStorage.getItem("SESSION");
  switch (payload.type) {
    case "SET_STATE":
      return {
        ...state,
        data: typeof payload.response == "undefined" ? [] : payload.response,
        metaData: typeof payload.metaData == "undefined" ? [] : payload.metaData,
        user: typeof payload.user == "undefined" ? userObj : payload.user,
        advanceSearch: payload.advanceSearch ? true : false,
      };
      case "SET_CALL_STATE":
      return {
        ...state,
        call_one: payload.call_one ? true : false,
      };
      case "SET_SEARCH_STATE":
      return {
        ...state,
        search_list: payload.search_list ? true : false,
        pagination: payload.pagination ? true : false,
        searchedName: payload.searchedName ?  payload.searchedName : null,
      };
      case "SET_ACTIVE_PAGE_STATE":
      return {
        ...state,
        set_active_page: payload.set_active_page ? payload.set_active_page : 1,
      };
      case "SET_USAGES_DATES":
      return {
        ...state,
        start_date: payload.start_date ? payload.start_date : null,
        end_date: payload.end_date ? payload.end_date : null,
      };
   
    default:
      return state;
  }
};
export default Reducer;

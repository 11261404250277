import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { Redirect, NavLink } from "react-router-dom";
import moment from "moment";
import omit from "lodash/omit";
import keyBy from "lodash/keyBy";
import axios from "axios";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Upload, message, Image } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Header from "../Header/Index.js";
import FlashMessage from "../FlashMessage/FlashMessage";
import { Context } from "../Store/Store";
import Helper from "../Helper.js";
import EditorConvertToHTML from "./EditorBooking.js";
import DownloadContract from "./DownloadContract";
import { Button, Icon, Modal } from "rsuite";
import { Collapse } from 'antd';
import "./index.css";
const { Panel } = Collapse;

var { apiRequest } = require("../Api/Service");
var {
  GetBookingList,
  whitelabelListEndpoint,
  getWhitelabelVehicles,
  UpdateBooking,
  UploadBookingImage,
  DeleteBookingImage,
  GetWhiteLabelStation,
  getPlaceholder,
  getInsuracePlaceholder,
  ExtraWhitelableuserLsting,
  getDriverName,
} = require("../Api/ApiRoutes");

const initialState = {
  isError: false,
  errors: [],
  isSuccess: false,
  success: "",
  disable: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_SUCCESS":
      return {
        ...state,
        isSuccess: true,
        success: action.payload,
        disable: true,
      };
    case "FETCH_ERROR":
      return {
        ...state,
        isError: true,
        errors: action.payload,
        disable: false,
      };
    case "DEFAULT":
      return {
        disable: action.payload,
      };
    default:
      return state;
  }
};
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
function EditBooking(props) {
  const DetailSchema = Yup.object().shape({
    insurance_price: Yup.number()
      .strict()
      .required("This Field is Required")
      .typeError("Input must be a number"),
    // start_date: Yup.string().required("This Field is Required"),
    // end_date: Yup.mixed().required("This Field is Required"),
    // main_driver: Yup.mixed().required("This Field is Required"),
    // vehicle_id: Yup.mixed().required("This Field is Required"),
    // label_id: Yup.mixed().required("This Field is Required"),
  });

  const [LocalState, Localdispatch] = useReducer(reducer, initialState);
  const [redirectToReferrer, setredirectToReferrer] = useState(false);
  const [records, setRecord] = useState(undefined);
  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = useContext(Context);

  const [whitelabelList, setWhitelabelList] = useState([]);
  const [vehicleList, setVehiclelList] = useState([]);
  const [isPreviewVisible, setPreviewVisible] = useState(false);
  const [previewVisibleExtra, setExtraPreviewVisible] = React.useState(false);
  const [previewImageExtra, setPreviewImageExtra] = useState("");
  const [additionalDriverFields, setAdditionalDriverFields] = useState([]); // Initialize with one empty input field
  const [vehicleFields, setVehicleFields] = useState([""]); // Initialize with one empty input field
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [showTemplate, setShowTemplate] = useState(false);
  const [whitelabelStationList, setWhitelabelStationList] = useState([]);
  const [getTemplate, setGetemplate] = useState(false);
  const [showOthersFields, setShowOthersFields] = useState(false);
  const [showOthersDropFields, setShowOthersDropFields] = useState(false);
  const [placeholder, setPlaceholder] = useState([]);

  const [Insuranceplaceholder, setInsurancePlaceholder] = useState([]);
  const [getInsuranceTemplate, setGeInsurancetemplate] = useState(false);
  const [getInsuranceetTemplate, setGeInsuranceettemplate] = useState(false);

  const [downloadBUttonShow, setDownloadBUttonShow] = useState(false);
  const [defaultDropoff, setDefaultDropoff] = useState("");
  const [defaultPick, setDefaultPick] = useState("");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [extraDefaultFileds, setExtraDefaultFileds] = useState([]);
  const [extraFileds, setExtraFileds] = useState([]);
  const [extraAddNewFileds, setAddNewExtraFileds] = useState([]);
  const [extraOption, setExtraOption] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState("");
  const [excessAmount, setExcessAmount] = useState("");
  const [currentImage, setCurrentImage] = useState(null);
  const [rsModal, setRsModal] = useState(false);

  const emailSchema = Yup.string().email('Invalid email format');

  const arrayOfEmailsSchema = Yup.array().of(emailSchema);


  let id = props.match.params.id;
  let user = Helper.getStorageData("SESSION");
  let token = user.access_token;

  const handleSubmit = (values) => {
    const form_data = new FormData();
    form_data.append(
      "start_date",
      Helper.convertToUTC(
        moment(values.start_date).format("YYYY-MM-DD HH:mm:ss")
      )
    );
    form_data.append(
      "end_date",
      Helper.convertToUTC(moment(values.end_date).format("YYYY-MM-DD HH:mm:ss"))
    );
    form_data.append("main_driver", values.main_driver[0].user_contact);
    const userContacts =
      additionalDriverFields.length > 0
        ? additionalDriverFields.map((item) => item.user_contact)
        : "";

    if (additionalDriverFields.length > 0) {
      arrayOfEmailsSchema.validate(userContacts)
        .then(valid => console.log('All emails are valid'))
        .catch((error) => {
          console.log(error);
          Localdispatch({
            type: "FETCH_ERROR",
            payload: error.errors,
          });
          window.scrollTo(500, 0);
          return false;
        });
    }

    form_data.append("additional_drivers", userContacts);
    form_data.append("label_id", values.label_id);
    form_data.append(
      "vehicle_id",
      vehicleFields.length > 0 ? vehicleFields : ""
    );
    form_data.append("timezone", values.timezone);
    form_data.append("insurance_price", values.insurance_price);
    form_data.append("agent", selectedAgent);
    form_data.append("excess_amount", excessAmount);

    /* Extra Management payload part */
    const extras = [...extraDefaultFileds, ...extraAddNewFileds];
    const images = extras.length > 0
    ? extras.map((item, index) => {
        if ('image' in item && typeof item.image === 'string' && item.image.trim() !== '') {
            form_data.append(`extra-image[${index}]`, item.image);
        } else if ('image' in item && Array.isArray(item.image) && item.image.length > 0) {
            console.log(Array.isArray(item.image))
            console.log(item.image.length)
            console.log(item.image[0].originFileObj)
            form_data.append(`extra-image[${index}]`, item.image[0].originFileObj);
        } else if ('image' in item) {
            form_data.append(`extra-image[${index}]`, "");
        } else {
            console.warn(`Item at index ${index} does not have an image key.`);
        }
    })
    : [];
    form_data.append("extras", [JSON.stringify(extras)]);

    if (
      values.pickup_station_id !== "other" &&
      values.pickup_station_id !== "default"
    ) {
      form_data.append("pickup_station_id", values.pickup_station_id);
      form_data.append("pickup_station_type", "1");
      form_data.append("pickup_station_name", null);
    }

    if (
      values.dropoff_station_id !== "other" &&
      values.dropoff_station_id !== "default"
    ) {
      form_data.append("dropoff_station_id", values.dropoff_station_id);
      form_data.append("dropoff_station_type", "1");
      form_data.append("dropoff_station_name", null);
    }

    if (values.pickup_station_id === "default") {
      form_data.append("pickup_station_id", null);
      form_data.append("pickup_station_type", "2");
      form_data.append("pickup_station_name", null);
    }
    if (values.dropoff_station_id === "default") {
      form_data.append("dropoff_station_id", null);
      form_data.append("dropoff_station_type", "2");
      form_data.append("dropoff_station_name", null);
    }

    if (values.pickup_station_id === "other") {
      form_data.append("pickup_station_id", null);
      form_data.append("pickup_station_type", "3");
      form_data.append("pickup_station_name", values.pickup_station_name);
    }
    if (values.dropoff_station_id === "other") {
      form_data.append("dropoff_station_id", null);
      form_data.append("dropoff_station_type", "3");
      form_data.append("dropoff_station_name", values.dropoff_station_name);
    }

    if (getTemplate !== false) {
      form_data.append("contract", getTemplate);
    }
    if (getInsuranceTemplate !== false) {
      form_data.append("extra_insurance_content", getInsuranceTemplate);
    }

    if (getInsuranceetTemplate !== false) {
      form_data.append("extra_insurance_content_et", getInsuranceetTemplate);
    }

    Localdispatch({ type: "DEFAULT", payload: true });
    apiRequest(UpdateBooking.method, UpdateBooking.url + id, form_data)
      .then((response) => {
        if (response.data.code === 200) {
          Localdispatch({
            type: "FETCH_SUCCESS",
            payload: response.data.message,
          });
          dispatch({
            type: "SET_SEARCH_STATE",
            search_list: true,
            pagination: false,
            searchedName: null,
          });
          //   setRedirectId(response.data.data.id)
          setTimeout(() => {
            setredirectToReferrer(true);
          }, 2000);
        } else {
          Localdispatch({
            type: "FETCH_ERROR",
            payload: response?.data?.message,
          });
          window.scrollTo(500, 0);
        }
      })
      .catch((error) => {
        console.log(error);
        Localdispatch({
          type: "FETCH_ERROR",
          payload: error?.response?.data?.data,
        });
        window.scrollTo(500, 0);
      });
  };

  const handleWhitelabelList = (event) => {
    apiRequest(
      whitelabelListEndpoint.method,
      whitelabelListEndpoint.url + `per_page=-1`
    )
      .then((response) => {
        if (response.data.code === 200) {
          setWhitelabelList(response.data.data);
          setDefaultDropoff(response.data.default_dropoff_station_id);
          setDefaultPick(response.data.default_pickup_station_id);
        } else {
          console.log("Failure", response);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    apiRequest(getPlaceholder.method, getPlaceholder.url)
      .then((response) => {
        if (response.data.code === 200) {
          setPlaceholder(response.data.data);
        } else {
          console.log("Failure", response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    apiRequest(getInsuracePlaceholder.method, getInsuracePlaceholder.url)
      .then((response) => {
        if (response.data.code === 200) {
          setInsurancePlaceholder(response.data.data);
        } else {
          console.log("Failure", response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleWhitelabelStationPickupChange = (event) => {
    let id = event.target.value;

    if (id === "other") {
      setShowOthersFields(true);
    } else {
      setShowOthersFields(false);
    }
  };

  const handleWhitelabelStationDropoffChange = (event) => {
    let id = event.target.value;

    if (id === "other") {
      setShowOthersDropFields(true);
    } else {
      setShowOthersDropFields(false);
    }
  };

  const handleWhitelabelChange = (event) => {
    let id = event.target.value;
    handleVehicles(id);
  };

  const handTemplate = (template) => {
    setGetemplate(template);
  };

  const handInsuranceTemplate = (template) => {
    setGeInsurancetemplate(template);
    console.log(template);
  };
  const handInsuranceetTemplate = (template) => {
    setGeInsuranceettemplate(template);
    console.log(template);
  };

  const handleVehicles = (id) => {
    apiRequest(
      getWhitelabelVehicles.method,
      getWhitelabelVehicles.url + `label_id=${id}`
    )
      .then((response) => {
        if (response.data.code === 200) {
          setVehiclelList(response.data.data);
        } else {
          console.log("Failure", response);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    apiRequest(GetWhiteLabelStation.method, GetWhiteLabelStation.url + `${id}`)
      .then((response) => {
        if (response.data.code === 200) {
          setWhitelabelStationList(response.data.data);
        } else {
          console.log("Failure", response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleExtraField = (id) => {
    apiRequest(
      ExtraWhitelableuserLsting.method,
      ExtraWhitelableuserLsting.url + `${id}`
    )
      .then((response) => {
        if (response.data.code === 200) {
          setExtraFileds([...extraFileds, {}]);
          if (response.data.data.length === 0) {
          } else {
            let otherVlaues = response.data.data.filter((d) => {
              return d.is_default != true;
            });
            setExtraFileds(otherVlaues);
          }
        } else {
          console.log("Failure", response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [driverName, setDriverName] = useState({});
  const [drivers, setDrivers] = useState({});

  const getDriverNameByEmail = useCallback(
    async (email) => {
      try {
        if (!drivers[email]) {
          const response = await apiRequest(
            getDriverName.method,
            getDriverName.url + `${email}`
          );
          if (response.data.code === 200) {
            setDriverName((prev) => ({
              ...prev,
              [email]: response.data.data,
            }));
          }
        } else {
          setDriverName((prev) => ({
            ...prev,
            [email]: drivers[email].user_name,
          }));
        }
      } catch (error) {
        throw new Error(error);
      }
    },
    [drivers]
  );

  useEffect(() => {
    Object.keys(drivers).map((email) => [getDriverNameByEmail(email)]);
  }, [drivers, getDriverNameByEmail]);

  useEffect(() => {
    // if (user.type !== "whitelabel") {
    handleWhitelabelList();
    // }
  }, [user.type]);

  useEffect(() => {
    const scrollToErrorField = () => {
      const errorField = document.querySelector(".error");
      if (errorField) {
        const rect = errorField.getBoundingClientRect();
        const midpoint = rect.top + rect.height / 2;
        window.scrollTo({
          top: midpoint + window.scrollY - window.innerHeight / 2,
          behavior: "smooth",
        });
      }
    };

    document.addEventListener("scrollToTop", scrollToErrorField);

    return () => {
      document.removeEventListener("scrollToTop", scrollToErrorField);
    };
  }, []);

  useEffect(() => {
    // if (
    //   Helper.getPermissions(
    //     "admin-update",
    //     user_permissions && user_permissions,
    //     "admin"
    //   ) ||
    //   Helper.getPermissions("", [], user.type)
    // ) {
    apiRequest(GetBookingList.method, GetBookingList.url + `/get/${id}`)
      .then((response) => {
        if (response.status === 200) {
          let data = response.data.data;
          const additional_drivers = data.drivers
            ?.filter((item) => item.type !== "1")
            .map((item) => {
              return {
                user_contact: item.user_contact,
                is_license_verified: item.is_license_verified,
                user_id: item.user_id,
                extra_insurance: item.extra_insurance,
                license_number: item.license_number,
              };
            });

          const utcs = new Date(data.start_date);
          const offset = utcs.getTimezoneOffset();

          const local_startDate = new Date(utcs.getTime() - offset * 60000);

          const utce = new Date(data.end_date);
          const offsete = utce.getTimezoneOffset();
          const local_endDate = new Date(utce.getTime() - offsete * 60000);

          setStartDate(local_startDate);
          setEndDate(local_endDate);
          setDrivers(keyBy(data.drivers, "user_contact"));
          setRecord(data);
          handleAgentChange(data.agent);
          setExtraDefaultFileds(data.extras);
          handleVehicles(data.label_id);
          handleExtraField(data.label_id);
          if (data.is_contract_signed === 1) {
            setDownloadBUttonShow(true);
          }

          setVehicleFields([String(data.vehicle_id)]);
          if (additional_drivers.length > 0) {
            setAdditionalDriverFields([...additional_drivers]);
          } else {
            setAdditionalDriverFields([
              ...additionalDriverFields,
              { user_contact: "" },
            ]);
          }
          setFileList(
            data.images.map((file) => {
              return {
                uid: file.id,
                name: file.comment,
                status: "done",
                url: file.url,
                type: String(file.type),
              };
            })
          );

          if (
            data.dropoff_station_type === "1" ||
            data.dropoff_station_type === "2"
          ) {
            setShowOthersDropFields(false);
          } else {
            setShowOthersFields(true);
            setShowOthersDropFields(true);
          }

          if (
            data.pickup_station_type === "1" ||
            data.pickup_station_type === "2"
          ) {
            setShowOthersFields(false);
          } else {
            setShowOthersFields(true);
          }

          templateShow();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  const templateShow = () => {
    setShowTemplate(true);
  };
  const handleAdditionalChangeDriverFields = (index, event) => {
    const values = [...additionalDriverFields];
    values[index].user_contact = event.target.value;
    setAdditionalDriverFields(values);
  };
  const handleAdditionalDriverFields = () => {
    setAdditionalDriverFields([
      ...additionalDriverFields,
      { user_contact: "" },
    ]); // Add a new empty input field to the state
  };

  const handleRemoveAdditionalDriverFields = (index) => {
    const values = [...additionalDriverFields];
    values.splice(index, 1); // Remove the input field at the given index
    setAdditionalDriverFields(values);
  };
  const handleVehicleChangeFields = (index, event) => {
    const values = [...vehicleFields];
    values[index] = event.target.value;
    setVehicleFields(values);
  };

  const handleImgPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewOpen(true);
    setPreviewTitle(
      file.name ||
      file.comment ||
      file.url.substring(file.url.lastIndexOf("/") + 1)
    );
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(!isPreviewVisible);
  };
  const handleImgPreviewExtra = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
  
    setPreviewImageExtra(file.preview);
    setExtraPreviewVisible(!previewVisibleExtra);
  };

  const deleteModalOpen = (image) => {
    setCurrentImage(image);
    setRsModal(true);
  };

  const Modalclose = () => {
    setRsModal(false);
  };

  const handleImgRemove = () => {
    const updatedFileList = fileList.filter(
      (item) => item.uid !== currentImage.uid
    );
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const baseUrl = DeleteBookingImage.url;
    message.loading("deleting");
    axios
      .delete(baseUrl + `${currentImage.uid}?booking_id=${id}`, { headers })
      .then((response) => {
        setFileList(updatedFileList);
        message.success("deleted successfully");
        console.log("API response:", response.data);
      })
      .catch((error) => {
        Localdispatch({
          type: "FETCH_ERROR",
          payload: error.response.data.data,
        });
        window.scrollTo(500, 0);
      });
    setRsModal(false);
  };

  const uploadImage = (file, type) => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const baseUrl = UploadBookingImage.url;
    var data = new FormData();
    data.append("booking_id", id);
    data.append("image", file.file);
    data.append("type", type);
    message.loading("uploading");
    axios
      .post(baseUrl, data, { headers })
      .then((response) => {
        if (response.data.code === 200) {
          const _data = response.data.data;
          message.success("uploaded successfully");
          setFileList((prev) => [
            ...prev,
            {
              ...file.file,
              uid: _data.id,
              status: "done" || "",
              type: String(_data.type) || null,
              name: _data.comment || "",
              url: _data.url || null,
            },
          ]);
        }
      })
      .catch((error) => {
        Localdispatch({
          type: "FETCH_ERROR",
          payload: `${error.response.data.data.message}`,
        });
        window.scrollTo(500, 0);
      });
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const downloadHandler = () => {
    Localdispatch({
      type: "FETCH_SUCCESS",
      payload: "Success",
    });
  };

  /* Extra Mangements */
  const handelExtraFields = (index, key, event) => {
    const values = [...extraDefaultFileds];

    if (key === "price") {
      values[index].price = event.target.value;
    }

    setExtraDefaultFileds(values);
  };
  const handleAssignNewExtra = (event) => {
    setExtraOption(event.target.value);
    if (event.target.value === "other") {
      //handleAdditionalExtraFields();
    }
  };
  const handleRemoveExtraAddNewFileds = (index) => {
    const values = [...extraAddNewFileds];
    values.splice(index, 1); // Remove the input field at the given index
    setAddNewExtraFileds(values);
  };

  const handleRemoveExtraFileds = (index) => {
    let ob = extraDefaultFileds.filter((_, i) => i == index);

    const values = [...extraDefaultFileds];
    values.splice(index, 1); // Remove the input field at the given index
    setExtraDefaultFileds(values);

    setExtraFileds([
      ...extraFileds,
      {
        title: ob[0].title,
        title_en: ob[0].title_en,
        title_es: ob[0].title_es,
        price: ob[0].price,
        is_daily: ob[0].is_daily,
        is_default: ob[0].is_default,
        is_vat: ob[0].is_vat,
        image: ob[0].image,
        type:ob[0].type,
        item_type:ob[0].item_type,
        short_description_en:ob[0].short_description_en,
        short_description_et:ob[0].short_description_et,
        long_description_en:ob[0].long_description_en,
        long_description_et:ob[0].long_description_et,
      },
    ]);
  };

  const handelExtraAddNewFields = (index, key, event) => {
    const values = [...extraAddNewFileds];
    // const fieldValue = event.target.checked ? true : false;

    const setValueForKey = (targetKey, value) => {
      values[index][targetKey] = value;
    };

    switch (key) {
      case "title":
      case "price":
      case "title_en":
      case "title_es":
        setValueForKey(key, event.target.value);
        break;

      case "is_default":
      case "is_vat":
      case "is_daily":
        setValueForKey(key, event.target.checked);
        break;

      case "type":
      case "item_type":
      case "short_description_en":
      case "short_description_et":
      case "long_description_en":
      case "long_description_et":
        setValueForKey(key, event);
        break;

      case "image":
        if (event.status === "removed") {
          values[index].image = [];
        } else {
          const _file = { ...event, status: "done" };
          values[index].image = [_file];
        }
        break;

      default:
        break;
    }

    setAddNewExtraFileds(values);
    console.log(values)
  };

  const handleAdditionalExtraFields = () => {
    if (extraOption.length === 0) {
      return false;
    }
    if (extraOption === "other") {
      setAddNewExtraFileds([
        ...extraAddNewFileds,
        {
          title: "",
          title_en: "",
          title_es: "",
          price: 0,
          is_daily: false,
          is_default: false,
          is_vat: false,
          image: [],
          type:null,
          item_type:null,
          short_description_en:"",
          short_description_et:"",
          long_description_en:"",
          long_description_et:"",
        },
      ]); // Add a new empty input field to the state
    } else {
      let ob = extraFileds.filter((_, index) => index === +extraOption);
      setExtraDefaultFileds([
        ...extraDefaultFileds,
        {
          title: ob[0].title,
          title_en: ob[0].title_en,
          title_es: ob[0].title_es,
          price: ob[0].price,
          is_daily: ob[0].is_daily,
          is_default: ob[0].is_default,
          is_vat: ob[0].is_vat,
          image: ob[0].image,
          type:ob[0].type,
          item_type:ob[0].item_type,
          short_description_en:ob[0].short_description_en,
          short_description_et:ob[0].short_description_et,
          long_description_en:ob[0].long_description_en,
          long_description_et:ob[0].long_description_et,
        },
      ]);

      const values = [...extraFileds];
      values.splice(extraOption, 1);

      setExtraFileds(values);
      setExtraOption([]);
    }
  };

  const agentOptions = [
    "ERGO Insurance SE",
    "AB Lietuvos draudimas Eesti Filiaal",
    "Compensa Vienna insurance Group, ADB EESTI",
    "IF P&C INSURANCE AS",
    "LHV Kindlustus AS",
  ];

  const handleAgentChange = (selectedAgent) => {
    setSelectedAgent(selectedAgent);

    // Set excess amount based on the selected agent
    switch (selectedAgent) {
      case "ERGO Insurance SE":
        setExcessAmount(`300€`);
        break;
      case "AB Lietuvos draudimas Eesti Filiaal":
      case "Compensa Vienna insurance Group, ADB EESTI":
      case "IF P&C INSURANCE AS":
      case "LHV Kindlustus AS":
        setExcessAmount(`200€`);
        break;
      default:
        setExcessAmount("");
    }
  };

  if (redirectToReferrer) {
    return (
      <Redirect
        to={{
          pathname: `/admin/booking-list/1`,
        }}
      />
    );
  }

  return (
    <React.Fragment>
      <Modal backdrop="static" show={rsModal} onHide={Modalclose} size="xs">
        <Modal.Body>
          <Icon
            icon="remind"
            style={{
              color: "#ffb300",
              fontSize: 24,
            }}
          />
          {"  "}
          Are you sure you want to delete this Image?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleImgRemove} appearance="primary">
            Ok
          </Button>
          <Button onClick={Modalclose} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <div className="wrapper d-flex align-items-stretch">
        <Sidebar /> */}
      <div id="content">
        <Header showSearch={false} showAdvanceSearch={false} />

        {/* {Helper.getPermissions(
            "admin-create",
            user_permissions && user_permissions,
            "admin"
          ) || Helper.getPermissions("", [], user.type) ? ( */}
        <div className="p-4">
          <h4 className="mb-3">Edit Booking</h4>
          {records && (
            <div className="mb-2">
              <p>
                Booking Created at:{" "}
                {records?.created_at ? (
                  <>
                    {Helper.getDateByTZ(records?.created_at)}{" "}
                    {user?.timezone
                      ? `(${user.timezone})`
                      : `(${Helper.localTimezone()})`}{" "}
                  </>
                ) : (
                  "-"
                )}
              </p>
              <p>Created By: {records?.created_by?.email}</p>
              <p>
                Booking Status:{" "}
                {records?.booking_status ? records.booking_status : "--"}
              </p>
            </div>
          )}
          <DownloadContract
            pdf={records?.vehicle_condition_pdf}
            bid={id}
            messages={downloadHandler}
            downloadBUttonShow={downloadBUttonShow}
          />
          <h5 className="mb-2 mt-2">Booking Details</h5>
          {records && (
            <Formik
              validateOnChange={false}
              validationSchema={DetailSchema}
              onSubmit={async (values) => {
                // console.log("permissions: ", values);
                handleSubmit(values);
              }}
              initialValues={{
                start_date: records && startDate,
                end_date: records && endDate,
                main_driver:
                  records.drivers &&
                  records.drivers
                    ?.filter((item) => item.type === "1")
                    .map((item) => {
                      return {
                        user_contact: item.user_contact,
                        is_license_verified: item.is_license_verified,
                        user_id: item.user_id,
                        license_number: item.license_number,
                      };
                    }),
                additional_drivers:
                  records.drivers &&
                  records.drivers
                    ?.filter((item) => item.type !== "1")
                    .map((item) => {
                      return {
                        user_contact: item.user_contact,
                        is_license_verified: item.is_license_verified,
                        user_id: item.user_id,
                        extra_insurance: item.extra_insurance,
                        license_number: item.license_number,
                      };
                    }),
                additional_status:
                  records.drivers &&
                  records.drivers
                    ?.filter((item) => item.type !== "1")
                    .map((item) => item.is_license_verified),
                label_id: records && records.label_id,
                extra_insurance:
                  records.drivers &&
                  records.drivers
                    ?.filter((item) => item.type === "1")
                    .map((item) => item.extra_insurance),
                fuel_coverage: records && records.fuel_coverage,
                previous:
                  records &&
                  records.previous_vehicle &&
                  records.previous_vehicle.name,
                contract: records && records.is_contract_signed,
                signature: records && records.signature,
                vehicle_id: records && records.vehicle_id,
                timezone: records && records.timezone,
                status: records && records.status,
                images: records && records.images,
                fuel: records && records.fuel,
                pay_price: records && records.pay_price,
                check_in_odometer: records && records.check_in_odometer,
                check_out_odometer: records && records.check_out_odometer,
                rental_start_date: records && records.rental_start_date,
                rental_end_date: records && records.rental_end_date,
                insurance_price: records && records.insurance_price,
                hcontract: records && records.contract,
                extra_insurance_content:
                  records && records.extra_insurance_content,
                extra_insurance_content_et:
                  records && records.extra_insurance_content_et,
                pickup_station_id:
                  records && records.pickup_station_type === "2"
                    ? "default"
                    : records.pickup_station_type === "3"
                      ? "other"
                      : records.pickup_station_id,
                dropoff_station_id:
                  records && records.dropoff_station_type === "2"
                    ? "default"
                    : records.dropoff_station_type === "3"
                      ? "other"
                      : records.dropoff_station_id,
                pickup_station_type: records && records.pickup_station_type,
                dropoff_station_type: records && records.dropoff_station_type,
                pickup_station_name:
                  records && records.pickup_station_name !== null
                    ? records.pickup_station_name
                    : "",
                dropoff_station_name:
                  records && records.dropoff_station_name !== null
                    ? records.dropoff_station_name
                    : "",
                registration_plate:
                  records && records.vehicle_detail.license
                    ? records.vehicle_detail.license
                    : "",
                is_contract_signed:
                  records && records.is_contract_signed === 1 ? true : false,
                vehicle_condition_pdf: records && records.vehicle_condition_pdf,
                check_in_fuel: records && records.check_in_fuel,
                check_out_fuel: records && records.check_out_fuel,
              }}
            >
              {({
                handleSubmit,
                handleChange,
                isSubmitting,
                setFieldValue,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form className="mt-2 user">
                  <FlashMessage
                    success={LocalState.success}
                    isSuccess={LocalState.isSuccess}
                    isError={LocalState.isError}
                    errors={LocalState.errors}
                  />
                  {user.type !== "whitelabel" && (
                    <div className="form-row">
                      <div className="col-md-6">
                        <div className="">
                          <label className="form-label">WhiteLabel</label>
                          <select
                            className={`form-control hideArrow form-select-user dropdown-height ${errors.label_id ? "error" : ""
                              }`}
                            name="label_id"
                            value={values.label_id}
                            onChange={(e) => {
                              values.vehicle_id = "";
                              handleWhitelabelChange(e);
                              handleChange(e);
                            }}
                          >
                            {" "}
                            <option value="">Select WhiteLabel</option>
                            {whitelabelList &&
                              whitelabelList.map((record, index) => (
                                <option value={record.id} key={index}>
                                  {record.name}
                                </option>
                              ))}
                          </select>
                          <div
                            className="custom-arrow"
                            style={{ top: "60%" }}
                          ></div>
                          {errors.label_id && (
                            <div className="ft-14 mt-1 red">
                              {errors.label_id}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="">
                          <label className="form-label">Select Vehicle</label>
                          {vehicleFields.map((inputValue, index) => (
                            <div className="d-flex" key={index}>
                              <select
                                className={`mb-3 form-control hideArrow form-select-user dropdown-height ${errors.vehicle_id ? "error" : ""
                                  }`}
                                name="vehicle_id"
                                value={inputValue}
                                onChange={(event) => {
                                  handleVehicleChangeFields(index, event);
                                }}
                              >
                                {" "}
                                <option value="">Select Vehicle</option>
                                {vehicleList &&
                                  vehicleList.map((record, index) => (
                                    <option value={record.id} key={index}>
                                      {record.name} {"- " + record.license}
                                    </option>
                                  ))}
                              </select>
                              <div
                                className="custom-arrow"
                                style={{ top: "60%" }}
                              ></div>
                              {errors.vehicle_id && (
                                <div className="ft-14 mt-1 red">
                                  {errors.vehicle_id}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                      {/* <div className="flex ml-4 col-md-4 mt-3">
                                                <label className="row form-label">Extra insurance: {(values.extra_insurance) ? (values.extra_insurance == 1 ? 'Yes' : 'No') : '-'}</label>
                                            </div> */}
                    </div>
                  )}
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      {user.type == "whitelabel" && (
                        <>
                          {" "}
                          <label className="form-label">Vehicle</label>
                          {vehicleFields.map((inputValue, index) => (
                            <div className="d-flex" key={index}>
                              <select
                                className={`mb-3 form-control  hideArrow form-select-user dropdown-height ${errors.vehicle_id ? "error" : ""
                                  }`}
                                name="vehicle_id"
                                value={inputValue}
                                onChange={(event) => {
                                  handleVehicleChangeFields(index, event);
                                }}
                              >
                                {" "}
                                <option value="">Vehicle</option>
                                {vehicleList &&
                                  vehicleList.map((record, index) => (
                                    <option value={record.id} key={index}>
                                      {record.name} {"- " + record.license}
                                    </option>
                                  ))}
                              </select>
                              <div className="custom-arrow"></div>
                              {errors.vehicle_id && (
                                <div className="ft-14 mt-1 red">
                                  {errors.vehicle_id}
                                </div>
                              )}
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="form-label">
                        Booking Start Date & Time
                      </label>
                      <input
                        type="datetime-local"
                        name="start_date"
                        value={
                          moment(values.start_date).format(
                            "YYYY-MM-DD HH:mm:ss"
                          ) || ""
                        }
                        // defaultValue={records.start_date}
                        onChange={handleChange}
                        placeholder="Start"
                        className={`form-control form-control-user ${errors.start_date ? "error" : ""
                          }`}
                      />
                      {errors.start_date && (
                        <div className="ft-14 mt-1 red">
                          {errors.start_date}
                        </div>
                      )}
                    </div>
                    <div className="form-group col-md-6">
                      <label className="form-label">
                        Booking End Date & Time
                      </label>

                      <input
                        type="datetime-local"
                        name="end_date"
                        value={
                          moment(values.end_date).format(
                            "YYYY-MM-DD HH:mm:ss"
                          ) || ""
                        }
                        onChange={handleChange}
                        placeholder="Ends"
                        className={`form-control form-control-user ${errors.end_date ? "error" : ""
                          }`}
                      />
                      {errors.end_date && (
                        <div className="ft-14 mt-1 red">{errors.end_date}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="form-label">Main Driver</label>
                      <input
                        type="text"
                        name="main_driver[0].user_contact"
                        value={values.main_driver[0].user_contact || ""}
                        onChange={(e) => {
                          const _value = e.target.value;
                          handleChange(e);
                          getDriverNameByEmail(_value);
                        }}
                        placeholder="Main Driver"
                        className={`form-control form-control-user ${errors.main_driver ? "error" : ""
                          }`}
                      />
                      {errors.main_driver && (
                        <div className="ft-14 mt-1 red">
                          {errors.main_driver}
                        </div>
                      )}
                      <div className=" mt-3">
                        <label className="col-12 form-label">
                          User Name:{" "}
                          {values.main_driver[0]?.user_id ? (
                            <NavLink
                              className=""
                              exact
                              to={`/admin/edit-user/${values.main_driver[0].user_id}`}
                            >
                              <b className="">
                                {driverName[values.main_driver[0].user_contact]}
                              </b>
                            </NavLink>
                          ) : (
                            <span className="pl-1">
                              {driverName[values.main_driver[0].user_contact]
                                ? driverName[values.main_driver[0].user_contact]
                                : "Not found"}
                            </span>
                          )}
                        </label>
                        <label className="col-12 form-label ">
                          User Status:{" "}
                          {values.main_driver[0].is_license_verified ? (
                            <label className="pl-1">
                              <b className="green">Verified</b>
                            </label>
                          ) : (
                            <label className="pl-1">
                              <b className="red">Not Verified</b>
                            </label>
                          )}
                        </label>
                        <label className="col-12 form-label">
                          Driver's license:{" "}
                          <span className="pl-1">
                            {" "}
                            {values.main_driver[0].license_number
                              ? values.main_driver[0].license_number.replace(
                                /"/g,
                                ""
                              )
                              : "-"}{" "}
                          </span>
                        </label>
                      </div>
                    </div>

                    <div className="form-group col-md-6">
                      <div className="">
                        <label className="form-label">Additional Driver</label>
                        {additionalDriverFields?.map((inputValue, index) => (
                          <>
                            <div className="d-flex" key={index}>
                              <input
                                type="email"
                                value={inputValue?.user_contact}
                                name="additional_drivers"
                                onChange={(event) => {
                                  handleAdditionalChangeDriverFields(
                                    index,
                                    event
                                  );
                                  getDriverNameByEmail(event.target.value);
                                }}
                                placeholder="Additional Driver"
                                className={`mb-3 form-control form-control-user ${errors.server_path ? "error" : ""
                                  }`}
                              />
                              {index > 0 ? (
                                <div className="pl-5 pr-4 pt-2">
                                  <i
                                    className="fas fa-minus hover"
                                    style={{
                                      fontSize: "30px",
                                      color: user.background_color,
                                      cursor: "pointer",
                                    }}
                                    aria-hidden="true"
                                    onClick={() =>
                                      handleRemoveAdditionalDriverFields(index)
                                    }
                                  ></i>
                                </div>
                              ) : (
                                <div className="pl-5 pr-4 pt-2">
                                  <i
                                    className="fas fa-plus-circle hover"
                                    style={{
                                      fontSize: "30px",
                                      color: user.background_color,
                                      cursor: "pointer",
                                    }}
                                    onClick={handleAdditionalDriverFields}
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              )}
                              {errors.additional_drivers && (
                                <div className="ft-14 mt-1 red">
                                  {errors.additional_drivers}
                                </div>
                              )}
                            </div>
                            <label className="col-12 form-label">
                              User Name:{" "}
                              {driverName[inputValue?.user_contact] ? (
                                <span className="">
                                  {inputValue.user_id ? (
                                    <NavLink
                                      className=""
                                      exact
                                      to={`/admin/edit-user/${inputValue.user_id}`}
                                    >
                                      <label className="">
                                        <b>
                                          {" "}
                                          {
                                            driverName[inputValue?.user_contact]
                                          }{" "}
                                        </b>
                                      </label>
                                    </NavLink>
                                  ) : (
                                    <label className="">
                                      <b>
                                        {" "}
                                        {
                                          driverName[inputValue?.user_contact]
                                        }{" "}
                                      </b>
                                    </label>
                                  )}
                                </span>
                              ) : (
                                <span>Not found</span>
                              )}
                            </label>
                            {typeof inputValue?.is_license_verified ===
                              "boolean" ? (
                              inputValue?.is_license_verified ? (
                                <label className="col-12 form-label">
                                  User Status:{" "}
                                  <label className="pl-1">
                                    <b className="green">Verified</b>
                                  </label>
                                </label>
                              ) : (
                                <label className="col-12 form-label">
                                  {" "}
                                  User Status:{" "}
                                  <b className="red">Not verified</b>
                                </label>
                              )
                            ) : (
                              <></>
                            )}
                            {/* {inputValue?.extra_insurance !== undefined &&
                                                            <label className="col-12 form-label">
                                                                Extra Insurance: {
                                                                    inputValue?.extra_insurance ? (inputValue?.extra_insurance == 1 ? 'Yes' : 'No') : "-"
                                                                }
                                                            </label>
                                                        } */}
                            {inputValue?.license_number !== undefined && (
                              <label className="col-12 form-label">
                                Driver's license:{" "}
                                {inputValue?.license_number !== ""
                                  ? inputValue?.license_number.replace(/"/g, "")
                                  : "-"}
                              </label>
                            )}
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="form-label">Insurance agent</label>
                      <select
                        name="agent"
                        onChange={(e) => handleAgentChange(e.target.value)}
                        value={selectedAgent}
                        className={`form-control hideArrow form-select-user dropdown-height ${errors.agent ? "error" : ""
                          }`}
                      >
                        {" "}
                        <option value="">Select insurance agent</option>
                        {agentOptions.map((agent) => (
                          <option key={agent} value={agent}>
                            {agent}
                          </option>
                        ))}
                      </select>
                      <div className="custom-arrow"></div>
                      {errors.agent && (
                        <div className="ft-14 mt-1 red">{errors.agent}</div>
                      )}
                    </div>
                    <div className="form-group col-md-6">
                      <label className="form-label">Excess Amount</label>
                      <input
                        type="text"
                        name="excess_amount"
                        value={`${excessAmount}`}
                        readOnly
                        placeholder="Excess Amount"
                        className={`form-control form-control-user ${errors.excess_amount ? "error" : ""
                          }`}
                      />
                      {errors.excess_amount && (
                        <div className="ft-14 mt-1 red">
                          {errors.excess_amount}
                        </div>
                      )}
                    </div>
                  </div>

                  <h5 className="mb-3">Vehicle Details</h5>
                  <div className="form-row mt-3">
                    <div className="form-group col-md-6">
                      <label className="form-label col-12">
                        Previous Vehicle: {values.previous}
                      </label>
                      <label className="form-label col-12">
                        Rental Start Date:{" "}
                        {values.rental_start_date && (
                          <>
                            {Helper.getDateByTZ(values.rental_start_date)}{" "}
                            {user?.timezone
                              ? `(${user.timezone})`
                              : `(${Helper.localTimezone()})`}
                          </>
                        )}
                      </label>
                      <label className="form-label col-12">
                        Rental End Date:{" "}
                        {values.rental_end_date && (
                          <>
                            {Helper.getDateByTZ(values.rental_end_date)}{" "}
                            {user?.timezone
                              ? `(${user.timezone})`
                              : `(${Helper.localTimezone()})`}
                          </>
                        )}
                      </label>
                      <label className="form-label col-12">
                        Odometer at check-in: {values.check_in_odometer}
                      </label>
                      <label className="form-label col-12">
                        Odometer at check-out: {values.check_out_odometer}
                      </label>
                      <label className="form-label col-12">
                        Fuel level at check-out: {values.check_out_fuel}%
                      </label>
                      <label className="form-label col-12">
                        Fuel level at check-in: {values.check_in_fuel}%
                      </label>

                      {/*<label className="form-label col-12">
                                                Registration plate: {values.registration_plate}
                                            </label>*/}

                      {/* <label className="form-label col-12">Extra insurance: -</label>
                                            <label className="form-label col-12"> Fuel coverage: - </label> */}
                    </div>
                  </div>

                  <h5 className="mb-3">Contract and insurance details</h5>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="form-label col-12">
                        Fuel coverage: {values?.fuel_coverage ? "Yes" : "No"}
                      </label>
                      <label className="form-label col-12">
                        Pay Price: {values?.pay_price ? values.pay_price : "-"}
                      </label>
                      <label className="form-label col-12">
                        {" "}
                        Insurance day price (with VAT)
                      </label>
                      <div className="input-group">
                        <input
                          type="number"
                          name="insurance_price"
                          value={values.insurance_price}
                          onChange={handleChange}
                          onInput={(e) => {
                            if (e.target.value < 0) {
                              e.target.value = 0;
                            }
                          }}
                          placeholder=""
                          className={`form-control form-control-user ${errors.insurance_price ? "error" : ""
                            }`}
                        />
                        <div className="input-group-append">
                          <span
                            className="input-group-text form-control form-select-user pt-3 h-100"
                            id="basic-addon2"
                          >
                            Euros (€)
                          </span>
                        </div>
                      </div>
                      {errors.insurance_price && (
                        <div className="ft-14 mt-1 red">
                          {errors.insurance_price}
                        </div>
                      )}
                      <label className="form-label col-12">
                        Extra insurance:{" "}
                        {values.extra_insurance
                          ? values.extra_insurance == 1
                            ? "Yes"
                            : "No"
                          : "-"}
                      </label>

                      <label className="form-label col-12">
                        Contract Signed: {values.contract === 1 ? "Yes" : "No"}
                      </label>
                      {values.signature && (
                        <label className="form-label col-12">
                          Contract signed at:{" "}
                          {records.contract_signed_at && (
                            <>
                              {Helper.getDateByTZ(records.contract_signed_at)}{" "}
                              {user?.timezone
                                ? `(${user.timezone})`
                                : `(${Helper.localTimezone()})`}
                            </>
                          )}
                        </label>
                      )}
                      {values.signature && (
                        <>
                          <label className="form-label col-12">
                            <strong>Signature:</strong>
                          </label>
                          {!values.signature.endsWith(".txt") && (
                            <img
                              src={values.signature}
                              width="300"
                              height="150"
                              alt="signature"
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  <h5 className="mb-3">Car pick-up and drop-off</h5>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="form-label">Pick-up location</label>

                      <select
                        className={`form-control hideArrow form-select-user dropdown-height ${errors.pickup_station_id ? "error" : ""
                          }`}
                        name="pickup_station_id"
                        value={values.pickup_station_id}
                        onChange={(e) => {
                          handleWhitelabelStationPickupChange(e);
                          handleChange(e);
                        }}
                      >
                        <option value="">Select Pickup</option>
                        {whitelabelStationList &&
                          whitelabelStationList.map((rwecord, index) => (
                            <option value={rwecord.id} key={index}>
                              {rwecord.name}
                            </option>
                          ))}
                        <option value="other">Other</option>
                        <option value="default">Default</option>
                      </select>
                      <div className="custom-arrow"></div>
                      {errors.pickup_station_id && (
                        <div className="ft-14 mt-1 red">
                          {errors.pickup_station_id}
                        </div>
                      )}
                    </div>

                    {showOthersFields && (
                      <div className="form-group col-md-6">
                        <label className="form-label">Pickup location</label>
                        <input
                          type="text"
                          name="pickup_station_name"
                          value={values.pickup_station_name}
                          onChange={handleChange}
                          placeholder="Pickup location"
                          className={`form-control form-control-user ${errors.pickup_station_name ? "error" : ""
                            }`}
                        />
                        {errors.pickup_station_name && (
                          <div className="ft-14 mt-1 red">
                            {errors.pickup_station_name}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="form-label">Drop-off location</label>

                      <select
                        className={`form-control hideArrow form-select-user dropdown-height ${errors.dropoff_station_id ? "error" : ""
                          }`}
                        name="dropoff_station_id"
                        value={values.dropoff_station_id}
                        onChange={(e) => {
                          handleWhitelabelStationDropoffChange(e);
                          handleChange(e);
                        }}
                      >
                        <option value="">Select Dropoff</option>
                        {whitelabelStationList &&
                          whitelabelStationList.map((drecord, index) => (
                            <option value={drecord.id} key={index}>
                              {drecord.name}
                            </option>
                          ))}
                        <option value="other">Other</option>
                        <option value="default">Default</option>
                      </select>
                      <div className="custom-arrow"></div>
                      {errors.dropoff_station_id && (
                        <div className="ft-14 mt-1 red">
                          {errors.dropoff_station_id}
                        </div>
                      )}
                    </div>
                    {showOthersDropFields && (
                      <div className="form-group col-md-6">
                        <label className="form-label">Dropoff location</label>

                        <input
                          type="text"
                          name="dropoff_station_name"
                          value={values.dropoff_station_name}
                          onChange={handleChange}
                          placeholder="Dropoff location"
                          className={`form-control form-control-user ${errors.dropoff_station_name ? "error" : ""
                            }`}
                        />
                        {errors.dropoff_station_name && (
                          <div className="ft-14 mt-1 red">
                            {errors.dropoff_station_name}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <h3 className="mb-3">Photos of the vehicle</h3>
                  <div className="">
                    <h5 className="mb-3">Check-out photos</h5>
                    <div className="form-group col-md-12 upload-img">
                      {fileList.filter((file) =>
                        ["1", "2", "3", "4"].includes(file.type)
                      ).length > 0 ? (
                        <div>
                          <Upload
                            customRequest={(file) => uploadImage(file, "1")}
                            listType="picture-card"
                            fileList={fileList
                              ?.filter((file) =>
                                ["1", "2", "3", "4"].includes(file.type)
                              )
                              .sort(
                                (a, b) => parseInt(a.type) - parseInt(b.type)
                              )
                              .map((item) => omit(item, "type"))}
                            onPreview={handleImgPreview}
                            onRemove={deleteModalOpen}
                            multiple
                            showUploadList={{
                              showPreviewIcon: true,
                              showRemoveIcon: true,
                            }}
                            itemRender={(file, _file) => (
                              <div className={`${!_file.name && 'position-relative'}`} style={{ top: "20%", width: '100%' }}>
                                {file}
                                {
                                  <p className="text-wrap p-2 fs-6">{_file.name}</p>
                                }
                              </div>
                            )
                            }
                          ></Upload>
                          <Image
                            width={200}
                            style={{
                              display: "none",
                              transform: `rotate(90deg)`,
                            }}
                            preview={{
                              visible: isPreviewVisible,
                              onVisibleChange: (visible, prevVisible) =>
                                setPreviewVisible(visible),
                            }}
                            src={previewImage}
                          />
                        </div>
                      ) : (
                        <>
                          <p className="px-2">No photos uploaded yet.</p>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="my-3">
                    <h5 className="">Photos to report damage</h5>
                    <div className="form-group col-md-12 upload-img mt-2">
                      <div>
                        <Upload
                          customRequest={(file) => uploadImage(file, "6")}
                          listType="picture-card"
                          fileList={fileList
                            .filter((file) => file.type === "6")
                            .map((item) => omit(item, "type"))}
                          onPreview={handleImgPreview}
                          onRemove={deleteModalOpen}
                          accept="image/png, image/jpeg"
                          multiple
                          showUploadList={{
                            showPreviewIcon: true,
                            showRemoveIcon: true,
                          }}
                          itemRender={(file, _file) => (
                            <div className={`${!_file.name && 'position-relative'}`} style={{ top: "20%", width: '100%' }}>
                              {file}
                              {
                                <p className="text-wrap p-2 fs-6">{_file.name}</p>
                              }
                            </div>
                          )
                          }
                          maxCount={8}
                        >
                          {values.status === "3"
                            ? uploadButton
                            : null}
                        </Upload>
                        <Image
                          width={200}
                          style={{
                            display: "none",
                            transform: `rotate(90deg)`,
                          }}
                          preview={{
                            visible: isPreviewVisible,
                            onVisibleChange: (visible, prevVisible) =>
                              setPreviewVisible(visible),
                          }}
                          src={previewImage}
                        />
                      </div>
                    </div>

                  </div>
                  <div className="">
                    <h5 className="mb-2">Check-in photos</h5>
                    <div className="form-group col-md-12 upload-img">
                      {fileList.filter((file) =>
                        ["7", "8", "9", "10"].includes(file.type)
                      ).length > 0 ? (
                        <div>
                          <Upload
                            customRequest={(file) => uploadImage(file, "7")}
                            listType="picture-card"
                            fileList={fileList
                              ?.filter((file) =>
                                ["7", "8", "9", "10"].includes(file.type)
                              )
                              .sort(
                                (a, b) => parseInt(a.type) - parseInt(b.type)
                              )
                              .map((item) => omit(item, "type"))}
                            onPreview={handleImgPreview}
                            onRemove={deleteModalOpen}
                            multiple
                            showUploadList={{
                              showPreviewIcon: true,
                              showRemoveIcon: true,
                            }}
                            itemRender={(file, _file) => (
                              <div className={`${!_file.name && 'position-relative'}`} style={{ top: "20%", width: '100%' }}>
                                {file}
                                {
                                  <p className="text-wrap p-2 fs-6">{_file.name}</p>
                                }
                              </div>
                            )
                            }
                          >
                            {fileList?.length >= 8 || values.status === "3"
                              ? uploadButton
                              : null}
                          </Upload>
                          <Image
                            width={200}
                            style={{
                              display: "none",
                              transform: `rotate(90deg)`,
                            }}
                            preview={{
                              visible: isPreviewVisible,
                              onVisibleChange: (visible, prevVisible) =>
                                setPreviewVisible(visible),
                            }}
                            src={previewImage}
                          />
                        </div>
                      ) : (
                        <>
                          <p className="px-2">No photos uploaded yet.</p>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="">
                    {fileList.filter((file) => ["5"].includes(file.type))
                      .length > 0 ? (
                      <h5 className="mb-2"> Extras</h5>
                    )
                      : (
                        <h5 className="mb-2"> Additional Photos</h5>
                      )}
                    <div className="form-group col-md-12 upload-img">
                      <div>
                        <Upload
                          customRequest={(file) => uploadImage(file, "5")}
                          listType="picture-card"
                          fileList={fileList
                            ?.filter((file) => file.type === "5")
                            .sort(
                              (a, b) => parseInt(a.type) - parseInt(b.type)
                            )
                            .map((item) => omit(item, "type"))}
                          onPreview={handleImgPreview}
                          onRemove={deleteModalOpen}
                          multiple
                          showUploadList={{
                            showPreviewIcon: true,
                            showRemoveIcon: true,
                          }}
                          itemRender={(file, _file) => (
                            <div className={`${!_file.name && 'position-relative'}`} style={{ top: "20%", width: '100%' }}>
                              {file}
                              {
                                <p className="text-wrap p-2 fs-6">{_file.name}</p>
                              }
                            </div>
                          )
                          }
                          maxCount={8}
                          accept="image/png, image/jpeg"
                        >
                          {values.status === "3"
                            ? uploadButton
                            : null}
                        </Upload>
                        <Image
                          width={200}
                          style={{
                            display: "none",
                            transform: `rotate(90deg)`,
                          }}
                          preview={{
                            visible: isPreviewVisible,
                            onVisibleChange: (visible, prevVisible) =>
                              setPreviewVisible(visible),
                          }}
                          src={previewImage}
                        />
                      </div>
                    </div>
                  </div>


                  {extraDefaultFileds && (
                    <h5 className="mb-3">Extras Management</h5>
                  )}
                  {extraDefaultFileds.map((inputValue, index) => (
                    <div className="form-row" key={index}>
                      <div className="form-group col-md-6">
                        <label className="form-label">
                          {inputValue.title}{" "}
                          <span className="mt-2">
                            {inputValue.is_vat === true ? "(with VAT)" : ""}{" "}
                          </span>{" "}
                        </label>
                        <div className="input-group">
                          <input
                            type="text"
                            value={inputValue?.price}
                            name="price"
                            onChange={(event) => {
                              handelExtraFields(index, "price", event);
                            }}
                            placeholder="Price"
                            className={`form-control form-control-user ${errors.server_path ? "error" : ""
                              }`}
                          />
                          <div className="input-group-append">
                            <span
                              className="input-group-text form-control form-select-user pt-3 h-100"
                              id="basic-addon2"
                            >
                              EUR{inputValue.is_daily === true ? "/Day" : ""}
                            </span>
                          </div>
                          <div className="">
                            <div className="pl-5 pr-4 pt-2">
                              <i
                                className="fas fa-minus hover"
                                style={{
                                  fontSize: "30px",
                                  color: user.background_color,
                                  cursor: "pointer",
                                }}
                                aria-hidden="true"
                                onClick={(event) => {
                                  handleRemoveExtraFileds(index, event);
                                }}
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                  {extraAddNewFileds.map((inputValue, index) => (
                    <div
                      className="row border border-secondary m-1"
                      key={index}
                    >
                      <div className="form-group col-md-3">
                        <div className="">
                          <label
                            className="form-check-label mt-n2"
                            for="inlineCheckbox2"
                          >
                            Extra Title
                          </label>
                          <input
                            type="text"
                            value={inputValue?.title}
                            name={`title_${index}`}
                            id={`title_${index}`}
                            onChange={(event) => {
                              handelExtraAddNewFields(index, "title", event);
                            }}
                            placeholder="Add New Extra"
                            className={`mb-3 form-control form-control-user ${errors.server_path ? "error" : ""
                              }`}
                          />
                        </div>
                      </div>

                      <div className="form-group col-md-3">
                        <div className="">
                          <label
                            className="form-check-label mt-n2"
                            for="inlineCheckbox2"
                          >
                            Extra Price
                          </label>
                          <input
                            type="text"
                            value={inputValue?.price}
                            name={`price_${index}`}
                            id={`price_${index}`}
                            onChange={(event) => {
                              handelExtraAddNewFields(index, "price", event);
                            }}
                            placeholder="Price"
                            className={`mb-3 form-control form-control-user ${errors.server_path ? "error" : ""
                              }`}
                          />
                        </div>
                      </div>
                      <div className="form-group col-md-3 mt-3">
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name={`type_${index}`}
                            id={`dailyPrice_${index}`}
                            value="1"
                            checked={inputValue?.type === "1"}
                            onChange={(event) => {
                              handelExtraAddNewFields(
                                index,
                                "type",
                                event.target.value
                              );
                            }}
                          />
                          <label class="form-check-label" for="dailyPrice">
                            Daily price
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name={`type_${index}`}
                            id={`totalPrice_${index}`}
                            value="2"
                            checked={inputValue?.type === "2"}
                            onChange={(event) => {
                              handelExtraAddNewFields(
                                index,
                                "type",
                                event.target.value
                              );
                            }}
                          />
                          <label class="form-check-label" for="totalPrice">
                            Total Price
                          </label>
                        </div>

                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name={`item_type_${index}`}
                            id={`singleItem_${index}`}
                            checked={inputValue?.item_type === "1"}
                            value="1"
                            onChange={(event) => {
                              handelExtraAddNewFields(
                                index,
                                "item_type",
                                event.target.value
                              );
                            }}
                          />
                          <label class="form-check-label" for="singleItem">
                            Single item
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name={`item_type_${index}`}
                            id={`multipeItems_${index}`}
                            checked={inputValue?.item_type === "2"}
                            value="2"
                            onChange={(event) => {
                              handelExtraAddNewFields(
                                index,
                                "item_type",
                                event.target.value
                              );
                            }}
                          />
                          <label class="form-check-label" for="multipeItems">
                            Multiple items
                          </label>
                        </div>
                      </div>

                      <div className="form-group col-md-2 mt-3">
                        <div className=" form-check form-check-inline">
                          <input
                            className="form-check-input"
                            name={`is_vat_${index}`}
                            id={`is_vat_${index}`}
                            type="checkbox"
                            onChange={(event) => {
                              handelExtraAddNewFields(index, 'is_vat', event);
                            }}
                            checked={inputValue?.is_vat ? inputValue.is_vat : false}
                            value={inputValue?.is_vat ? inputValue.is_vat : false}
                          />
                          <label className="form-check-label mt-n2" for="inlineCheckbox1">+Vat</label>
                        </div>
                        <div className=" form-check form-check-inline">
                          <input
                            className="form-check-input"
                            name={`is_default_${index}`}
                            id={`is_default_${index}`}
                            type="checkbox"
                            onChange={(event) => {
                              handelExtraAddNewFields(index, 'is_default', event);
                            }}
                            checked={inputValue?.is_default ? inputValue.is_default : false}
                            value={inputValue?.is_default ? inputValue.is_default : false}
                          />
                          <label className="form-check-label mt-n2" for="inlineCheckbox2">Default</label>
                        </div>
                      </div>

                      <div className="form-group col-md-1">
                        <div className="">
                          <div className="pl-5 pr-4 pt-2">
                            <i
                              className="fas fa-minus hover"
                              style={{
                                fontSize: "30px",
                                color: "orange",
                                cursor: "pointer",
                              }}
                              id={`minus_${index}`}
                              aria-hidden="true"
                              onClick={(event) => {
                                handleRemoveExtraAddNewFileds(index, event);
                              }}
                            ></i>
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-md-12">
                        Image Upload
                        <Upload
                          customRequest
                          listType="picture-card"
                          fileList={inputValue.image}
                          onPreview={handleImgPreviewExtra}
                          onChange={(file) => {
                            handelExtraAddNewFields(index, "image", file.file);
                          }}
                          showUploadList={{
                            showPreviewIcon: true,
                            showRemoveIcon: false,
                          }}
                        >
                          {uploadButton}
                        </Upload>
                        <Image
                          width={200}
                          style={{
                            display: "none",
                            transform: `rotate(90deg)`,
                          }}
                        
                          src={previewImageExtra}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label className="form-label">
                          Short Description (EN)
                        </label>
                        <input
                          type="text"
                          value={inputValue?.short_description_en}
                          name={`short_description_en_${index}`}
                          id={`short_description_en_${index}`}
                          onChange={(event) => {
                            handelExtraAddNewFields(
                              index,
                              "short_description_en",
                              event.target.value
                            );
                          }}
                          placeholder="Short Description in English"
                          className={`mb-3 form-control form-control-user ${errors.short_description_en ? "error" : ""
                            }`}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label className="form-label">
                          Short Description (ET)
                        </label>
                        <input
                          type="text"
                          value={inputValue?.short_description_et}
                          name={`short_description_et_${index}`}
                          id={`short_description_et_${index}`}
                          onChange={(event) => {
                            handelExtraAddNewFields(
                              index,
                              "short_description_et",
                              event.target.value
                            );
                          }}
                          placeholder="Short Description in Estonian"
                          className={`mb-3 form-control form-control-user ${errors.short_description_et ? "error" : ""
                            }`}
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label className="form-label" for="long_description_en">
                          Long Description (EN)
                        </label>
                        <textarea
                          name={`long_description_en_${index}`}
                          id={`long_description_en_${index}`}
                          placeholder="Enter Description in English"
                          value={inputValue?.long_description_en}
                          onChange={(event) => {
                            handelExtraAddNewFields(
                              index,
                              "long_description_en",
                              event.target.value
                            );
                          }}
                          className={`mb-3 form-control ${errors.long_description_en ? "error" : ""
                            }`}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label className="form-label">
                          Long Description (ET)
                        </label>
                        <textarea
                          id={`long_description_et_${index}`}
                          name="long_description_et"
                          placeholder="Enter Description in Estonian"
                          value={inputValue?.long_description_et}
                          onChange={(event) => {
                            handelExtraAddNewFields(
                              index,
                              "long_description_et",
                              event.target.value
                            );
                          }}
                          className={`mb-3 form-control  ${errors.long_description_et ? "error" : ""
                            }`}
                        />
                      </div>

                    </div>
                  ))}
                  <div className="d-flex form-group col-md-6 p-0">
                    {extraFileds && (
                      <>
                        <select
                          className={`form-control hideArrow form-select-user dropdown-height ${errors.dropoff_station_id ? "error" : ""
                            }`}
                          name="another_extra"
                          value={defaultDropoff}
                          onChange={(e) => {
                            handleAssignNewExtra(e);
                            handleChange(e);
                          }}
                        >
                          <option value="">Select Another extras</option>
                          {extraFileds &&
                            extraFileds.map((drecord, index) => (
                              <option value={index} key={index}>
                                {drecord.title}
                              </option>
                            ))}
                          <option value="other">Other</option>
                        </select>
                        <div
                          className="custom-arrow"
                          style={{ right: "135px", top: "26px" }}
                        ></div>
                      </>
                    )}
                    <div className="pl-5 pr-4 pt-2">
                      <i
                        className="fas fa-plus-circle hover"
                        style={{
                          fontSize: "30px",
                          color: user.background_color,
                          cursor: "pointer",
                        }}
                        onClick={handleAdditionalExtraFields}
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                  {showTemplate && <h3 className="mb-1">Contract</h3>}
                  {showTemplate && (
                    <Collapse accordion className="mb-3">
                      <Panel header="Placeholders" key="1">
                        {placeholder &&
                          placeholder.map((precord, index) => (
                            <strong
                              className="badge p-2 m-1 label-assg badge-success mt-2"
                              key={index}
                            >
                              {precord}
                            </strong>
                          ))}
                      </Panel>
                    </Collapse>
                  )}

                  {showTemplate && (
                    <EditorConvertToHTML
                      tem={values.hcontract}
                      selTemplate={handTemplate}
                      editorState={values.is_contract_signed}
                      minheight="390px"
                      maxheight="680px"
                    />
                  )}
                  {showTemplate && <h3 class="mt-4">Extras</h3>}
                  {showTemplate && (
                    <Collapse accordion className=" mb-3">
                      <Panel header="Extra Placeholders" key="1">
                        {Insuranceplaceholder &&
                          Insuranceplaceholder.map((precord, index) => (
                            <strong
                              className="badge p-2 m-1 label-assg badge-success mt-2"
                              key={index}
                            >
                              {precord}
                            </strong>
                          ))}
                      </Panel>
                    </Collapse>
                  )}
                  {showTemplate && <h5 className="mb-1">Extra in English</h5>}
                  {showTemplate && (
                    <EditorConvertToHTML
                      tem={values.extra_insurance_content}
                      selTemplate={handInsuranceTemplate}
                      minheight="100px"
                      maxheight="590px"
                    />
                  )}
                  {showTemplate && <h5 className="mb-1">Extra in Estonian</h5>}
                  {showTemplate && (
                    <EditorConvertToHTML
                      tem={values.extra_insurance_content_et}
                      selTemplate={handInsuranceetTemplate}
                      minheight="100px"
                      maxheight="590px"
                    />
                  )}
                  <div className="form-row mt-4">
                    <div className="form-group d-flex justify-content-end col-md-12">
                      <button
                        type="submit"
                        className="btn-submit"
                        style={{ backgroundColor: user.background_color }}
                        disabled={LocalState.disable}
                      >
                        confirm Details
                        {LocalState.disable ? (
                          <span
                            className="ml-2 spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          ""
                        )}
                      </button>
                    </div>
                  </div>
                  {Object.keys(errors).length > 0 &&
                    document.dispatchEvent(new Event("scrollToTop"))}
                </Form>
              )}
            </Formik>
          )}
          {/* <div className="row">
                <div className="col-md-12 mb-4">
                  Usages: <u>20</u>
                </div>
                <div className="col-md-12">
                  Drivers behaviour score: <u>4.5</u>
                </div>
              </div> */}
        </div>
        {/* ) : (
            <div className="p-4">
              <div className="text-center ft-14 mt-3 font-weight-bold">
                You are not allowed to visit this screen...
              </div>
            </div>
          )} */}
      </div>
      {/* </div> */}
    </React.Fragment>
  );
}

export default EditBooking;

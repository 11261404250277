import React, { useEffect, useState } from "react";
import Header from "../Header/Index.js";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FlashMessage from "../FlashMessage/FlashMessage.js";
import { apiRequest } from "../Api/Service.js";
import {
  UpdateExtra,
  whitelabelListEndpoint,
  ExtraWhitelableuserLsting,
} from "../Api/ApiRoutes.js";
import Helper from "../Helper.js";
import ExtraFields from "./ExtraFields.js";
const initialState = {
  isError: false,
  errors: [],
  isSuccess: false,
  success: "",
  disable: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_SUCCESS":
      return { ...state, isSuccess: true, success: action.payload, disable: true };
    case "FETCH_ERROR":
      return { ...state, isError: true, errors: action.payload, disable: false };
    case "DEFAULT":
      return { ...state, disable: action.payload };
    default:
      return state;
  }
};

function AddExtra() {
  const [localState, localDispatch] = React.useReducer(reducer, initialState);
  const [whitelabelList, setWhitelabelList] = useState([]);
  const [extraFields, setExtraFields] = useState([]);
  const [labelId, setLabelId] = useState("");

  let user = Helper.getStorageData("SESSION");
  const permissions = user.permissions;
  const DetailSchema = Yup.object().shape({
    start_date: Yup.string().required("This Field is Required"),
  });

  useEffect(() => {
    if (user.type !== "whitelabel") {
      handleWhitelabelList();
    } else {
      setLabelId(user.label_id);
      handleExtras(user.label_id);
    }
  }, []);

  const handleWhitelabelList = () => {
    apiRequest(whitelabelListEndpoint.method, `${whitelabelListEndpoint.url}?per_page=-1`)
      .then(response => {
        if (response.data.code === 200) {
          setWhitelabelList(response.data.data);
        } else {
          console.error("Failure", response);
        }
      })
      .catch(console.error);
  };

  const saveData = async () => {
    const form_data = new FormData();
    const extras = extraFields.length > 0 ? extraFields.map(item => ({ ...item, image: undefined })) : [];

    extraFields.forEach((item, index) => {
      if (item?.image?.length > 0) {
        const imageFile = item.image[0].originFileObj || item.image[0].url;
        form_data.append(`extra-image[${index}]`, imageFile);
      }
    });

    form_data.append("extras", JSON.stringify(extras));

    localDispatch({ type: "DEFAULT", payload: true });
    apiRequest(UpdateExtra.method, `${UpdateExtra.url}${labelId}`, form_data)
      .then(response => {
        if (response.data.code === 200) {
          localDispatch({ type: "FETCH_SUCCESS", payload: response.data.message });
        }
      })
      .catch(error => {
        console.error(error);
        localDispatch({ type: "FETCH_ERROR", payload: error.response.data.data });
        window.scrollTo(0, 0);
      });
  };

  const handleFieldChange = (index, key, value) => {
    setExtraFields(prevFields => {
      const newFields = [...prevFields];
    
      if(key === 'image'){
        if (value.status === "removed") {
          newFields[index].image = [];
        } else {
          const _file = { ...value, status: "done" };
          newFields[index].image = [_file];
        }
      }else{
        newFields[index][key] = value;
      }
      return newFields;
    });
  };

  const handleAddField = () => {
    if (labelId) {
      setExtraFields(prevFields => [
        ...prevFields,
        {
          title: "",
          title_en: "",
          title_es: "",
          price: 0,
          is_daily: false,
          is_default: false,
          is_vat: false,
          type: "1",
          item_type: "1",
          short_description_en: "",
          short_description_et: "",
          long_description_en: "",
          long_description_et: "",
          image: [],
        },
      ]);
    }
  };

  const handleRemoveField = (index) => {
    if (window.confirm("Are you sure you want to delete this record?")) {
      setExtraFields(prevFields => {
        const newFields = [...prevFields];
        newFields.splice(index, 1);
        return newFields;
      });
    }
  };

  const handleWhitelabelChange = (event) => {
    const id = event.target.value;
    setLabelId(id);
    handleExtras(id);
  };

  const handleExtras = (id) => {
    apiRequest(
      ExtraWhitelableuserLsting.method,
      ExtraWhitelableuserLsting.url + `${id}`
    )
      .then((response) => {
        if (response.data.code === 200) {
          setExtraFields([...extraFields, {}]);
          if (response.data.data.length === 0) {
            setExtraFields([
              ...extraFields,
              {
                title: "",
                title_en: "",
                title_es: "",
                price: 0,
                is_daily: false,
                is_default: false,
                is_vat: false,
                type: "1",
                item_type: "1",
                short_description_en: "",
                short_description_et: "",
                long_description_en: "",
                long_description_et: "",
                image: [],
              },
            ]);
          } else {
            const data = response.data.data.map((item, index) => {
              return {
                ...item,
                image: item.image
                  ? [
                    {
                      uid: index,
                      name: `image${index}.png`,
                      status: "done",
                      url: item.image,
                    },
                  ]
                  : [],
              };
            });
            setExtraFields(data);
          }
        } else {
          console.log("Failure", response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  return (
    <React.Fragment>
      <div id="content">
        <Header showSearch={false} showAdvanceSearch={false} />

        <div className="p-4">
          <h4 className="mb-3">Extra</h4>
          <div className="container-fluid">
            <div className="row">
              <Formik
                validateOnChange={false}
                validationSchema={DetailSchema}
                initialValues={{}}
                onSubmit={saveData}
              >
                {({
                  handleChange,
                  handleSubmit,
                  values,
                  errors,
                }) => (
                  <Form className="mt-2 w-100">
                    <FlashMessage
                      success={localState.success}
                      isSuccess={localState.isSuccess}
                      isError={localState.isError}
                      errors={localState.errors}
                    />
                    <div className="row mb-3 ">
                      {user.type !== "whitelabel" && (
                        <div className="col-md-6">
                          <div className="">
                            <label className="form-label">WhiteLabel</label>
                            <select
                              className={`form-control dropdown-height ${errors.label_id ? "error" : ""
                                }`}
                              name="label_id"
                              value={values.label_id}
                              onChange={(e) => {
                                values.vehicle_id = "";
                                handleWhitelabelChange(e);
                                handleChange(e);
                              }}
                            >
                              {" "}
                              <option value="">Select WhiteLabel</option>
                              {whitelabelList &&
                                whitelabelList.map((record, index) => (
                                  <option value={record.id} key={index}>
                                    {record.name}
                                  </option>
                                ))}
                            </select>
                            {errors.label_id && (
                              <div className="ft-14 mt-1 red">
                                {errors.label_id}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      <div className="col-md-6 mt-4">
                        <i
                          className="fas fa-plus-circle hover mt-3"
                          style={{
                            fontSize: "30px",
                            color: user.background_color,
                            cursor: "pointer",
                          }}
                          onClick={handleAddField}
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                    {extraFields.map((inputValue, index) => (
                      <div
                        className="row border border-secondary m-1 p-3"
                        key={index}
                      >
                        <ExtraFields
                          inputValue={inputValue}
                          index={index}
                          handleFieldChange={handleFieldChange}
                          handleRemoveField={handleRemoveField}
                          errors={errors}
                        />
                      </div>
                    ))}
                    {Helper.getPermissions(
                      "extras-update",
                      permissions && permissions,
                      user.type
                    ) && (
                        <div className="row mt-5 float-right">
                          <div className="form-row mt-4">
                            <div className="form-group d-flex justify-content-end col-md-12">
                              <button
                                type="submit"
                                className="btn-submit"
                                onClick={saveData}
                                style={{ backgroundColor: user.background_color }}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AddExtra;

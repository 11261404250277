import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import Header from "../Header/Index.js";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FlashMessage from "../FlashMessage/FlashMessage";
import { Context } from "../Store/Store";
import { Modal, Button, Icon } from "rsuite";
import SaveButton from "../../commonComponents/Button.js";

//import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

//import { EditorState, convertToRaw } from 'draft-js';
import EditorConvertToHTML from './Editor.js';

//import draftToHtml from 'draftjs-to-html';
const Helper = require("../Helper");


var { apiRequest } = require("../Api/Service");
var {
    UserPermissions,
    whitelabelListEndpoint,
    GetTemplate,
    UpdateTemplate,
    getPlaceholder,
    getInsuracePlaceholder,
    GetInsuranceTemplate

} = require("../Api/ApiRoutes");


const initialState = {
    isError: false,
    errors: [],
    isSuccess: false,
    success: "",
    disable: false,
};

const reducer = (state, action) => {
    switch (action.type) {
        case "FETCH_SUCCESS":
            return {
                ...state,
                isSuccess: true,
                success: action.payload,
                disable: true,
            };
        case "FETCH_ERROR":
            return {
                ...state,
                isError: true,
                errors: action.payload,
                disable: false,
            };
        case "DEFAULT":
            return {
                disable: action.payload,
            };
        default:
            return state;
    }
};


function ContractAdd() {
    let user = Helper.getStorageData("SESSION");
    console.log("===========");
    console.log(user);
    const [LocalState, Localdispatch] = React.useReducer(reducer, initialState);
    const [redirectToReferrer, setredirectToReferrer] = useState(false);
    const [setPermissions] = React.useState([]);
    const [redirectId, setRedirectId] = React.useState(null);
    const [whitelabelList, setWhitelabelList] = React.useState([]);
    const [template, setTemplate] = React.useState([]);
    const [Insurancetemplate, setInsuranceTemplate] = React.useState([]);
    const [Insuranceettemplate, setInsuranceetTemplate] = React.useState([]);
    const [showTemplate, setShowTemplate] = React.useState(false);
    const [dispatch] = React.useContext(Context);
    const [getTemplate, setGetemplate] = React.useState(false);
    const [getInsuranceTemplate, setGeInsurancetemplate] = React.useState(false);
    const [getInsuranceetTemplate, setGeInsuranceedtemplate] = React.useState(false);
    const [tempModal, setTempModal] = React.useState(false);
    const [adminurl, setadminurl] = React.useState('');
    const [placeholder, setPlaceholder] = React.useState([]);
    const [Insuranceplaceholder, setInsurancePlaceholder] = React.useState([]);

    const Modalclose = () => {
        setTempModal(false);
    };
    const Modalopen = (e) => {
        tempModal(true);
    };
    const handleWhitelabelList = (event) => {
        if (user.type !== "whitelabel") {
            apiRequest(
                whitelabelListEndpoint.method,
                whitelabelListEndpoint.url + `per_page=-1`
            )
                .then((response) => {
                    if (response.data.code === 200) {
                        setWhitelabelList(response.data.data);
                    } else {
                        console.log("Failure", response);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        else {
            setShowTemplate(true)
        }
        apiRequest(
            getPlaceholder.method,
            getPlaceholder.url
        )
            .then((response) => {
                if (response.data.code === 200) {
                    setPlaceholder(response.data.data);
                } else {
                    console.log("Failure", response);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        apiRequest(
            getInsuracePlaceholder.method,
            getInsuracePlaceholder.url
        )
            .then((response) => {
                if (response.data.code === 200) {
                    setInsurancePlaceholder(response.data.data);
                } else {
                    console.log("Failure", response);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {

        handleWhitelabelList()
    }, [])

    const handleSubmit = (values) => {

        const form_data = new FormData();
        if (user.type == "whitelabel") {
            //form_data.append("label_id", user.id);
        }
        else {
            form_data.append("label_id", values.label_id);
        }

        if (getTemplate !== false) {
            form_data.append("content", getTemplate);
        }
        else {
            form_data.append("content", template.data);
        }

        if (getInsuranceTemplate !== false) {
            form_data.append("extra_insurance_content", getInsuranceTemplate);
        }
        else {
            form_data.append("extra_insurance_content", Insurancetemplate.data);
        }

        if (getInsuranceetTemplate !== false) {
            form_data.append("extra_insurance_content_et", getInsuranceetTemplate);
        }
        else {
            form_data.append("extra_insurance_content_et", Insuranceettemplate.data);
        }




        Localdispatch({ type: "DEFAULT", payload: true });
        apiRequest(UpdateTemplate.method, UpdateTemplate.url, form_data)
            .then((response) => {
                if (response.data.code === 200) {
                    Localdispatch({
                        type: "FETCH_SUCCESS",
                        payload: response.data.message

                    });
                    setredirectToReferrer(true); setTimeout(() => {
                        setredirectToReferrer(true);
                    }, 2000);

                }
            })
            .catch((error) => {
                console.log(error);
                Localdispatch({
                    type: "FETCH_ERROR",
                    payload: error.response.data.data,
                });
                window.scrollTo(500, 0);
            });
    };

    React.useEffect(() => {
        apiRequest(UserPermissions.method, UserPermissions.url)
            .then((response) => {
                if (response.data.code === 200) {
                    let data = response.data.data;
                    setPermissions(data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [setPermissions]);



    if (redirectToReferrer) {
        return <Redirect to={{
            pathname: `/admin/contract-listing/1`,
        }} />;
    }

    const handleWhitelabelChange = (event) => {
        let id = event.target.value;
        handleContract(id);
    }

    const handleContract = (id) => {
        //setadminurl(`http://127.0.0.1:8000/admin-react-template/`+id+'/'+userLoggedInToken)
        //setTempModal(true);

        //window.open( `https://dev.movitronic.com/admin-react-template/`+id+'/'+userLoggedInToken,'_blank',"toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=100,width=1000,height=1000");

        apiRequest(
            GetTemplate.method,
            GetTemplate.url + `${id}`
        )
            .then((response) => {
                setTemplate(response);
                setShowTemplate(true);
            })
            .catch((error) => {
                setShowTemplate(false);
                console.log(error);
            });

        apiRequest(
            GetInsuranceTemplate.method,
            GetInsuranceTemplate.url + `${id}&lang=en`
        )
            .then((response) => {
                setInsuranceTemplate(response);
            })
            .catch((error) => {
                setShowTemplate(false);
                console.log(error);
            });
        apiRequest(
            GetInsuranceTemplate.method,
            GetInsuranceTemplate.url + `${id}&lang=et`
        )
            .then((response) => {
                setInsuranceetTemplate(response);
            })
            .catch((error) => {
                setShowTemplate(false);
                console.log(error);
            });
    };

    const handTemplate = (template) => {
        setGetemplate(template);
        console.log(template);
    }

    const handInsuranceTemplate = (template) => {
        setGeInsurancetemplate(template);
        console.log(template);
    }

    const handInsuranceetTemplate = (template) => {
        setGeInsuranceedtemplate(template);
    }

    return (
        <React.Fragment>
            <Modal backdrop="static" show={tempModal} onHide={Modalclose} size="xl">
                <Modal.Body>
                    <Icon
                        icon="remind"
                        style={{
                            color: "#ffb300",
                            fontSize: 24,
                        }}
                    />
                    {"  "}
                    <iframe src={adminurl} frameborder="0" allowfullscreen></iframe>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={Modalclose} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            <div id="content">
                <Header showSearch={false} showAdvanceSearch={false} />

                <div className="p-4">
                    <h4 className="mb-3">Create Contract</h4>
                    
                   
                   
                    <Formik
                        validateOnChange={false}
                        onSubmit={async (values) => {
                            handleSubmit(values);
                        }}
                        initialValues={{
                            label_id: "",
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            isSubmitting,
                            setFieldValue,
                            handleBlur,
                            values,
                            touched,
                            isValid,
                            errors,
                        }) => (
                            <Form className="mt-2 user">
                                <FlashMessage
                                    success={LocalState.success}
                                    isSuccess={LocalState.isSuccess}
                                    isError={LocalState.isError}
                                    errors={LocalState.errors}
                                />

                                <div className="form-row">
                                    {(user.type !== "whitelabel" &&
                                        <div className="form-group col-md-6">
                                            <label className="form-label">WhiteLabel</label>
                                            <select

                                                name="label_id"
                                                onChange={(e) => {
                                                    values.vehicle_id = ''
                                                    handleWhitelabelChange(e);
                                                    handleChange(e);
                                                }}
                                                className={`form-control form-select-user dropdown-height ${errors.label_id ? "error" : ""
                                                    }`}
                                            > <option value="">Select WhiteLabel</option>
                                                {whitelabelList &&
                                                    whitelabelList.map((record, index) => (
                                                        <option value={record.id} key={index}>
                                                            {record.name}
                                                        </option>
                                                    ))}</select>
                                            {errors.label_id && (
                                                <div className="ft-14 mt-1 red">{errors.label_id}</div>
                                            )}

                                        </div>
                                    )}
                                </div>
                                <div>
                                { showTemplate &&<h3>Contract</h3> }      
                                <div className="alert alert-info" role="alert">
                                    Placeholders: {placeholder &&
                                                    placeholder.map((precord, index) => (
                                                        <strong className="badge p-2 m-1 label-assg badge-success mt-2" key={index}>{precord}</strong>
                                                    ))}
                                </div>      
                                { showTemplate &&  <EditorConvertToHTML tem={template} selTemplate={handTemplate} />}

                                { showTemplate &&<h3 class="mt-4">Extras</h3> } 
                                    {showTemplate && <div className="alert alert-info mt-3" role="alert">
                                        Extra Placeholders: {Insuranceplaceholder &&
                                            Insuranceplaceholder.map((precord, index) => (
                                                <strong className="badge p-2 m-1 label-assg badge-success mt-2" key={index}>{precord}</strong>
                                            ))}
                                    </div>}
                                    {showTemplate && <h3 >Extra in English</h3>}
                                    {showTemplate && <EditorConvertToHTML tem={Insurancetemplate} selTemplate={handInsuranceTemplate} />}
                                    {showTemplate && <h3>Extra in Estonian</h3>}
                                    {showTemplate && <EditorConvertToHTML tem={Insuranceettemplate} selTemplate={handInsuranceetTemplate} />}
                                </div>
                                {showTemplate && <div className="form-row mt-2">
                                    <div className="form-group d-flex justify-content-end col-md-12">
                                        <SaveButton
                                            type={"submit"}
                                            localState={LocalState}
                                            title={'Save'}
                                            size={"btn-sm"}
                                        />
                                    </div>
                                </div>
                                }

                            </Form>
                        )}
                    </Formik>
                </div>

            </div>
        </React.Fragment>
    );
}

export default ContractAdd;

import React, { useEffect, useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "../Header/Index.js";
import { Modal, Button } from "rsuite";
import Pagination from "react-js-pagination";
import { Context } from "../Store/Store";
import Helper from "../Helper";
import { DateRangePicker } from "rsuite";
import Table from 'react-bootstrap/Table';
import moment from "moment";
import "./index.css";

var { GetHeartBeatListing, HeartBeatExport } = require("../Api/ApiRoutes");

var { apiRequest } = require("../Api/Service");

const initialState = {
  isError: false,
  errors: [],
  isSuccess: false,
  success: "",
  disable: false,
};
const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_SUCCESS":
      return {
        ...state,
        isSuccess: true,
        success: action.payload,
        disable: false,
      };
    case "FETCH_ERROR":
      return {
        ...state,
        isError: true,
        errors: action.payload,
        disable: false,
      };
    case "DEFAULT":
      return {
        disable: action.payload,
      };
    default:
      return state;
  }
};
function HeartBeatList(props) {

  var user = Helper.getStorageData("SESSION");
  var location = useLocation();
  const [heartBeatrecords, setHeartBeatrecords] = React.useState([]);
  const [result, setResult] = React.useState({});
  const [loader, setloader] = React.useState(false);
  const [state, dispatch] = React.useContext(Context);
  const [rsModal, setRsModal] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [startdate, setStartDate] = useState(new Date());
  const [enddate, setEndDate] = useState(null);
  const [LocalState, Localdispatch] = React.useReducer(reducer, initialState);
  const [show, setShow] = React.useState(false);
  const [svid, setVid] = React.useState('');
  const [userEmail, setUserEmail] = React.useState('');


  const downloadCSV = (event) => {
    event.preventDefault();

    Helper.overlay(true);
    setloader(true);
    let qs = '?per_page=50';



    let vid = props?.vid ? props.vid : '';
    if (vid != '') {
      qs = qs + `&vehicle_id=${vid}`;
    }

    if (startdate && enddate) {
      qs = qs + `&start=${startdate}&end=${enddate}`;
    }
    else {
      var today = new Date();
      var fdate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-01';
      var tdate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-30';
      qs = qs + `&start=${fdate}&end=${tdate}`;
    }

    if (userEmail) {
      qs = qs + `&email=${userEmail}`
    }

    apiRequest(HeartBeatExport.method, HeartBeatExport.url + `${qs}`)
      .then((response) => {
        if (response.data.code === 200) {
          Localdispatch({
            type: "FETCH_SUCCESS",
            payload: "Email send Successfully",
          });
          Helper.overlay(false);
          setRsModal(false)
          window.scrollTo(500, 0);
        }
        else {
          Helper.overlay(false);
          setRsModal(false)
          window.scrollTo(500, 0);
        }
      })
      .catch((error) => {
        Helper.overlay(false);
        setShow(true);
        setRsModal(false)
        setErrorMsg(error.response.data.message);
        window.scrollTo(500, 0);
      });
  };

  const checkWhiteLabelTheme = () => {
    const isWhiteLabel = user.type === "whitelabel";

    // Left toolbar items
    const leftToolBarItems = document.getElementsByClassName("rs-picker-toolbar-ranges");
    if (leftToolBarItems.length > 0) {
      if (isWhiteLabel) {
        leftToolBarItems[0].classList.add("whiteLabelLeftBarItems");
      } else {
        leftToolBarItems[0].classList.remove("whiteLabelLeftBarItems");
      }
    }

    // Right toolbar items
    const rightToolBarItems = document.getElementsByClassName("rs-picker-toolbar-right");
    if (rightToolBarItems.length > 0) {
      if (isWhiteLabel) {
        rightToolBarItems[0].classList.add("whiteLabelRightBarButton");
      } else {
        rightToolBarItems[0].classList.remove("whiteLabelRightBarButton");
      }
    }

    // Calendar table cells
    const elements = document.getElementsByClassName("rs-calendar-table-cell-selected");
    if (elements.length > 0)
      for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        if (isWhiteLabel) {
          element.classList.add("whiteLabel");
        } else {
          element.classList.remove("whiteLabel");
        }
      }
  };

  const onChange = (dates) => {
    const [s, e] = dates;
    const startDate = moment(s).format("YYYY-MM-DD")
    const endDate = moment(e).format("YYYY-MM-DD")
    setStartDate(startDate);
    setEndDate(endDate);
  }

  const onClean = () => {
    setStartDate(null);
    setEndDate(null);
  }

  const getHeartBeatList = useCallback((page) => {

    Helper.overlay(true);
    let aurl = GetHeartBeatListing.url;
    let vid = props?.vid ? props.vid : '';

    if (vid) {
      aurl = aurl + `?per_page=50&vehicle_id=${vid}`;
    }

    apiRequest(GetHeartBeatListing.method, aurl)
      .then((response) => {
        if (response.data.code === 200) {
          setHeartBeatrecords(response.data.data);
          setResult(response.data.meta);
          Helper.overlay(false);

        } else {
          Helper.overlay(false);
        }
      })
      .catch((error) => {
        setErrorMsg(error)
        Helper.overlay(false);
      });
  }, [dispatch])

  useEffect(() => {
    if (location.hash !== "#search") {
      getHeartBeatList(state.set_active_page);
    }
    if (location.hash === "#error") {
      setHeartBeatrecords([]);
      setResult([]);

      setErrorMsg('No record found');
    }
  }, [getHeartBeatList, location.hash]);

  useEffect(() => {
    if (state.pagination === true) {
      setHeartBeatrecords(state.data.data);
      setResult(state.data.meta);
      dispatch({ type: "SET_SEARCH_STATE", pagination: false });
      Helper.overlay(false);
    }
  }, [dispatch, state.data.data, state.data.meta, state.pagination]);

  const handlePageChange = useCallback((pageNumber) => {

    dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: pageNumber });
    Helper.overlay(true);
    let qs = '&per_page=50';


    let vid = props?.vid ? props.vid : '';
    if (vid != '') {
      setVid(vid);
      qs = qs + `&vehicle_id=${vid}`;
    }
    if (startdate && enddate) {
      qs = qs + `&start=${startdate}&end=${enddate}`;
    }

    apiRequest(
      GetHeartBeatListing.method,
      GetHeartBeatListing.url + `?page=${pageNumber}` + qs
    )
      .then((response) => {
        if (response.data.code === 200) {
          setHeartBeatrecords(response.data.data);
          setResult(response.data.meta);
          Helper.overlay(false);
          //history.push(`/admin/heartbeat/${pageNumber}`);
        } else {
          Helper.overlay(false);
        }
      })
      .catch((error) => {
        Helper.overlay(false);
      });
  },[dispatch, enddate, props.vid, startdate]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const name = event.target.name.value;
    //Localdispatch({ type: "DEFAULT", payload: true });
    Helper.overlay(true);
    setloader(true);
    let qs = '?per_page=50';

    let vid = props?.vid ? props.vid : '';
    if (vid != '') {
      setVid(vid);
      qs = qs + `&vehicle_id=${vid}`;
    }


    if (startdate && enddate) {
      qs = qs + `&start=${startdate}&end=${enddate}`;
    }
    dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: 1 });
    apiRequest(GetHeartBeatListing.method, GetHeartBeatListing.url + qs)
      .then((response) => {
        if (response.data.code === 200) {
          Localdispatch({
            type: "FETCH_SUCCESS",
            payload: response.data.message,
          });
          setHeartBeatrecords(response.data.data);
          setResult(response.data.meta);
          Helper.overlay(false);
        }
        else {

        }
      })
      .catch((error) => {
        Helper.overlay(false);
        setShow(true);
        setHeartBeatrecords([]);
        setResult([]);
        setErrorMsg(error.response.data.message);
      });
  };

  const handleClose = (() => {
    setRsModal(false)
  })

  return (
    <React.Fragment>
      <Modal show={rsModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Send CSV file</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group col-md-6">
            <label className="form-label">Enter user email <span style={{ color: "red" }}>*</span></label>
            <input
              type="email"
              className="form-control form-control-user"
              name="email"
              required
              onChange={(e) => setUserEmail(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={downloadCSV} appearance="primary"
            style={{ background: user.background_color }}
          >
            Send
          </Button>
          <Button onClick={handleClose} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <div id="content">
        {props?.header == "no" ? '' : <Header showSearch={false} showAdvanceSearch={false} />}

        <div className="p-4">
          <div className="row border-bottom">
            <div className="col-md-9">
              <form
                className="white d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search"
                onSubmit={handleSubmit}
              >
                <ul className="nav-ul">
                  <li className="nav-li">
                    <div className="input-group">
                      <DateRangePicker
                        placeholder="Select Date Range"
                        onChange={onChange}
                        onClean={onClean}
                        startdate={startdate}
                        enddate={enddate}
                        type="datetime-local"
                        onSelect={checkWhiteLabelTheme}
                        onOpen={checkWhiteLabelTheme}
                      />
                      <div >
                        <div className="input-group-append">
                          <button className="btn btn-primary " type="submit"
                            style={{ backgroundColor: user.background_color, border: user.background_color }}
                          >
                            <i className="fas fa-search fa-sm"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                  {props?.search == "no" ? '' :
                    <li className="nav-li" style={{ marginLeft: '22px' }}>
                      <div className="input-group">
                        <input
                          type="search"
                          name="name"
                          className="form-control bg-light border-0 small"
                          placeholder="Search here"
                        />
                        <div className="input-group-append">
                          <button className="btn btn-primary" type="submit">
                            <i className="fas fa-search fa-sm"></i>
                          </button>
                        </div>
                      </div>
                    </li>
                  }

                </ul>
              </form>
              {svid != "" &&
                <Button onClick={() => setRsModal(true)} appearance="subtle">
                  <b style={{ color: user.background_color }}>Download CSV</b>
                </Button>}
            </div>

            <div className="col-md-3 d-flex align-items-center">
              <div className="d-flex flex-row mb-3">
                <div className="p-2">Results: {result.total ? result.total : '1'}</div>
                <div className="pl-4 pt-1">
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <Table striped bordered hover>
              <thead>
                <tr>
                  {props?.header == "no" ? '' : <th>Name</th>}
                  <th>Status</th>
                  <th>Lat & Long</th>
                  <th>Battery</th>
                  <th>Supply Voltage</th>
                  <th>RPM</th>
                  <th>odometer</th>

                  <th>Fuel</th>
                  <th>Next Online</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {heartBeatrecords.length ? (
                  heartBeatrecords.map((record, index) => (
                    <tr key={index}>
                      {props?.header == "no" ? '' : <td>{record.vehicle.name ? record.vehicle.name : "-"}</td>}
                      <td> {record.status ? record.status : "Sleep mode"}</td>
                      <td> {" "} {record && record.lat} {" - "} {record && record.long}
                        <strong>
                          {(record.lat ?
                            <a
                              href={`https://www.google.com/maps/search/?api=1&query=${record.lat},${record.long}`}
                              target='blank'
                            >
                            </a>
                            : '-')}
                        </strong>
                      </td>
                      <td> {record.data?.battery_voltage ? record.data.battery_voltage : "-"}</td>
                      <td>{record.data?.supply_voltage ? record.data.supply_voltage : "-"}</td>
                      <td>{record.data?.engine_rpm ? record.data.engine_rpm : "-"}</td>
                      <td> {record.data?.odo ? record.data.odo : "-"}</td>
                      <td> {record.data?.fuel ? record.data.fuel : "-"}</td>
                      <td> {record?.next_online ? record.next_online : "-"}</td>
                      <td>{record?.created_at ? <>{Helper.getDateByTZ(record.created_at)} {user?.timezone ? `(${user.timezone})` : `(${Helper.localTimezone()})`} </> : "-"}</td>

                    </tr>
                  ))
                ) : (
                  errorMsg && (
                    <div className="text-center ft-14 mt-3 font-weight-bold">
                      No Data Found...
                    </div>
                  )
                )}
              </tbody>
            </Table>
          </div>

          <div className="row mt-3 ft-14">
            <div className="col-md-12">
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-end">
                  <Pagination
                    activePage={result?.current_page}
                    totalItemsCount={result.total ? result.total : 1}
                    pageRangeDisplayed={5}
                    itemsCountPerPage={50}
                    itemClass="page-item"
                    linkClass="page-link"
                    onChange={handlePageChange}
                    activeClass={user.type === "whitelabel" ? "active-whitelabel-pagination" : "active-default-pagination "}
                  />
                  <li className="pl-3">
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default HeartBeatList;
